import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import MetricsIntakeStore from './../../store'
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import { toast } from 'react-toastify' // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css' // Import the CSS for toast notifications
import './PerformanceForm.scss'
import BulkEditDescriptionsModal from './BulkEditDescriptionsModal' // Import the modal component
import KpiMetricService from './../../../../shared/services/KpiMetric.service'
import UserProfileService from './../../../../shared/services/UserProfile.service'

// Initialize toast notifications
toast.configure()

const PerformanceForm = () => {
  const meetsRequirement =
    UserProfileService.isA('internal-staff', true) ||
    UserProfileService.isA('internal-admin', true) ||
    UserProfileService.isA('system-admin', true) ||
    UserProfileService.isA('agency-owner', true)

  const [isBulkEditModalOpen, setBulkEditModalOpen] = useState(false)
  const [kpis, setKpis] = useState([])

  const handleSave = async () => {
    try {
      await MetricsIntakeStore.debouncedSave()
      toast.success('KPI saved successfully!', {
        position: toast.POSITION.TOP_RIGHT, // Position of the toast
        autoClose: 3000, // Auto-close after 3 seconds
      })
    } catch (error) {
      console.error('Error saving data:', error)
      toast.error('Failed to save data. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const handleBulkEdit = async () => {
    try {
      // Fetch all KPI metrics from the server
      const response = await KpiMetricService.search()
      setKpis(response.models) // Set the KPI data for the modal
      setBulkEditModalOpen(true) // Open the modal
    } catch (error) {
      console.error('Error fetching KPI metrics:', error)
    }
  }

  const handleBulkSave = (updatedDescriptions) => {
    // Update the descriptions in the store
    MetricsIntakeStore.updateKpiDescriptions(updatedDescriptions)
  }

  return (
    <form id="PerformanceForm">
      {/* Bulk Edit Descriptions Modal */}
      <BulkEditDescriptionsModal
        isOpen={isBulkEditModalOpen}
        toggle={() => setBulkEditModalOpen(!isBulkEditModalOpen)}
        kpis={kpis}
        onSave={handleBulkSave}
      />
      <MDBRow className="metric-intake">
        {/* Vertical Label for LEAD GENERATION */}
        <MDBCol size="1" className="vertical-label-container vertical-cyan">
          <h5 className="text-indigo vertical-label">LEAD GENERATION</h5>
        </MDBCol>

        {/* Input Fields */}
        <MDBCol size="11">
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="hours_worked">
                Number of Hours Worked:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.hours_worked}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                value={MetricsIntakeStore.performance?.hours_worked || '0'}
                name="hours_worked"
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="calls">
                Number of Calls Completed:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.calls}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="calls"
                value={MetricsIntakeStore.performance?.calls || '0'}
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="events_attended">
                Number of Events Attended:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.events_attended}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="events_attended"
                value={MetricsIntakeStore.performance?.events_attended || '0'}
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="spoken_to">
                Number of Customers Spoken To:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.spoken_to}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="spoken_to"
                value={MetricsIntakeStore.performance?.spoken_to || '0'}
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="fact_finders">
                Number of Fact Finders Collected:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.fact_finders}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="fact_finders"
                value={MetricsIntakeStore.performance?.fact_finders || '0'}
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="appts_set">
                Appointments Set:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.appts_set}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="appts_set"
                value={MetricsIntakeStore.performance?.appts_set || '0'}
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>

      {/* Vertical Label for PRESENTATIONS */}
      <MDBRow className="metric-intake">
        <MDBCol size="1" className="vertical-label-container vertical-blue">
          <h5 className="text-indigo vertical-label vertical-label-white">
            PRESENTATIONS
          </h5>
        </MDBCol>

        {/* Input Fields */}
        <MDBCol size="11">
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="presentations_offered">
                Number of Presentations Offered:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.presentations_offered}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="presentations_offered"
                value={
                  MetricsIntakeStore.performance?.presentations_offered || '0'
                }
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="referrals_requested">
                Number of Referrals Requested:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.referrals_requested}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="referrals_requested"
                value={
                  MetricsIntakeStore.performance?.referrals_requested || '0'
                }
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="metric-intake">
            <MDBCol size="10">
              <label className="text-indigo" htmlFor="life_quoted">
                Number of Life Insurance Quotes:
              </label>
              <br />
              <span>{MetricsIntakeStore.kpiDefs?.life_quoted}</span>
            </MDBCol>
            <MDBCol size="2">
              <MDBInput
                type="text"
                name="life_quoted"
                value={MetricsIntakeStore.performance?.life_quoted || '0'}
                onChange={MetricsIntakeStore.onMetricChange}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow className="justify-content-end align-items-end mt-4">
        <MDBCol size="auto">
          {meetsRequirement && (
            <MDBBtn color="info" onClick={handleBulkEdit} className="mr-2">
              Edit All Descriptions
            </MDBBtn>
          )}
          <MDBBtn color="primary" onClick={handleSave}>
            Save
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(PerformanceForm)
