import React from 'react'
import UserProfileService from './../../../shared/services/UserProfile.service'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import {
  MDBBtn,
  MDBAlert,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBDataTable,
  MDBSelect,
} from 'mdbreact'
import CarrierService from './../../../shared/services/Carrier.service'
import UserCarrierContractService from './../../../shared/services/UserCarrierContractConfiguration.service'
import CarrierCommissonLevelService from './../../../shared/services/CarrierCommissionLevel.service'
import CarrierContractService from './../../../shared/services/CarrierContract.service'
import { carrierCommisionLevels } from './../../../constants/commisionLevels'

import AdminApi from './../../../api/admin-api/admin-api'

class CarrierCommisionModal extends React.Component {
  state = {
    isLoading: true,
    isOpen: false,
    isEditOpen: false,
    isEditLoading: true,
    isEmpty: false,
    commissionLevels: null,
    commissionLevelCurrentValue: null,
    linkStatusSelectedValue: null,
    carrierConfigExist: false,
    carrierConfigId: null,
    userData: [],
    data: {
      columns: [
        {
          label: 'Active Carriers',
          field: 'name',
          sort: 'asc',
          width: '150px',
        },
        {
          label: 'Description',
          field: 'description',
          width: '200px',
        },
        {
          label: 'Select Commisison Level',
          field: 'edit',
          width: '150px',
        },
        {
          label: 'Use Default Link',
          field: 'link',
          width: '150px',
        },
      ],
      rows: [],
    },
  }

  #toggleCarrierCommisionModal = () => {
    if (!this.state.loading) {
      if (!this.state.isOpen) this.#fetchActiveCarrierInfo()
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  onCommissionLevelChange = (value, carrierId) => {
    // Here we need to update the commsion overide table
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      // Ensure 'commision_id' exists as an object before setting the carrierId
      if (!userData['commision_id']) {
        userData['commision_id'] = {}
      }
      userData['commision_id'][carrierId] = value
      return { userData }
    })
  }

  onChangeStatusLink = (value, carrierId) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      if (!userData['use_default_link']) {
        userData['use_default_link'] = {}
      }
      userData['use_default_link'][carrierId] = value;
      return { userData };
    });
  };

  #fetchActiveCarrierInfo = async () => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      // Ensure 'commision_id' exists as an object before setting the carrierId
      userData['commision_id'] = {}
      userData['use_default_link'] = {}
      userData['carrierConfigExist'] = {}
      return { userData }
    })

    const userContractConfig = await UserCarrierContractService.search({
      search: { user_id: UserProfileService.getUserId() },
      pagination: false,
    })

    let userCommisionOveride = []
    userCommisionOveride = userContractConfig?.models.map((item) => {
      this.setState((prevState) => {
        let userData = Object.assign({}, prevState.userData)
        // Ensure 'commision_id' exists as an object before setting the carrierId
        if (!userData['carrierConfigExist']) {
          userData['carrierConfigExist'] = {}
        }

        userData['carrierConfigExist'][item.carrier_id] = item.id
        return { userData }
      })
      return { [item.carrier_id]: item.commision_id}
    })

    // Load commission levels
    let commissionLevels = []
    try {
      commissionLevels =
        (await AdminApi.getCommissionLevelList())?.data?.data || []
    } catch (ex) {
      console.error('Error loading commission levels', ex)
    }

    let carrierContract = await CarrierContractService.search({
      pagination: false,
    })

    // create array of carriers which has default option yes / no.
    let useDefaultLink = [];
    useDefaultLink = userContractConfig?.models.map((item) => {
      this.setState((prevState) => {
        let userData = Object.assign({}, prevState.userData)
        if (!userData['use_default_link']) {
          userData['use_default_link'] = {}
        }

        userData['use_default_link'][item.carrier_id] = item.use_default_link
        return { userData }
      })
      return {
        'carrier_id' : item.carrier_id,
        'status' : item.use_default_link
      }
    })

    const linkStatusOptions = [
      { text: 'Yes', value: 1 },
      { text: 'No', value: 0 }
    ];

    // Build Table row commision levels
    const buildRow = (Carrier) => {
      const updatedCommissionLevels = commissionLevels
        .map((item) => {
          const matchingCarrier = carrierContract.models.find(
            (model) => Carrier.id === model.carrier_id
          )

          const carrierKey = carrierCommisionLevels[item.com_code]

          if (
            carrierKey &&
            matchingCarrier &&
            carrierCommisionLevels[item.com_code] &&
            matchingCarrier.hasOwnProperty(carrierKey) &&
            matchingCarrier[carrierKey]
          ) {
            let matchingCommission = []
            matchingCommission = userCommisionOveride.find(
              (commisionObj) => commisionObj[Carrier.id]
            )
            return {
              text: item.com_name,
              value: `${item.id}`,
              checked:
                parseInt(item.id) ===
                parseInt(
                  matchingCommission ? matchingCommission[Carrier.id] : ''
                ),
            }
          } else {
            return null
          }
        })
        .filter((item) => item !== null)

      // loop through linkStatusOptions and check for carrier id.
      // if row carrier id exist in default links array then set checked as yes.
      const updatedLinkOptions = linkStatusOptions
        .map((option) => {
          let matchingCarrier = useDefaultLink.find(
            (item) => item.carrier_id == Carrier.id
          )

          if(matchingCarrier) {
            return {
              text: option.text,
              value: `${option.value}`,
              checked: (matchingCarrier.status && option.value == matchingCarrier.status) ? true : false
            }
          }
          else {
            return {
              text: option.text,
              value: `${option.value}`,
              checked: null
            }
          }
      });

      let carObj = {
        name: Carrier.c_name,
        description: Carrier.c_description,
        edit:
          updatedCommissionLevels.length > 0
            ? renderDropdown(Carrier.id, updatedCommissionLevels)
            : '',
        link:
          renderDefaultLinkDropdown(Carrier.id, updatedLinkOptions)
      }
      return carObj
    }

    const renderDefaultLinkDropdown = (carrierId, linkStatusOptions) => {
      return (
        <MDBSelect
          options={ linkStatusOptions }
          value={
            this.state.userData['use_default_link'][carrierId] || 0
          }
          selected={
            this.linkStatusSelectedValue || 'Choose Option'
          }
          getValue={(value) => {
            this.onChangeStatusLink(value[0], carrierId)
          }}
          getTextContent={(value) =>
            this.setState({
              linkStatusSelectedValue: value[0],
            })
          }
          label="Choose Option"
        />
      );
    };

    const renderDropdown = (carrierId, commissionLevels) => (
      <MDBSelect
        options={commissionLevels}
        value={
          this.state.userData['commision_id'][carrierId] ||
          'Choose Commission Level'
        }
        selected={
          this.state.commissionLevelCurrentValue || 'Choose Commission Level'
        }
        getValue={(value) => {
          this.onCommissionLevelChange(value[0], carrierId)
        }}
        getTextContent={(value) =>
          this.setState({
            commissionLevelCurrentValue: value,
          })
        }
        label="Commission Level"
      />
    )

    let carriers = await CarrierService.search({
      search: { c_active: 1 },
      pagination: false,
    })

    // console.log("Carrier", carriers?.models.map(buildRow).filter((c) => c))
    this.setState({
      data: {
        ...this.state.data,
        rows: carriers?.models.map(buildRow).filter((c) => c),
      },
    })
    // console.log("Carrier", CarrierMgmtStore.getCarriers(true))

    this.setState((prevState) => ({
      ...prevState,
      isLoading: false,
      isEmpty: carriers?.models?.length === 0,
    }))
  }

  #saveCarrierCommission = async () => {
    //console.log(this.state.userData)
    let userData = this.state.userData

    try {
      if (this.state.userData['use_default_link']) {
        for (let carrierId in userData.use_default_link) {
          if (userData.use_default_link[carrierId] !== undefined &&
            userData.carrierConfigExist[carrierId] != undefined) {

            let linkData = {
              carrier_id: carrierId,
              commision_id: userData.commision_id[carrierId],
              use_default_link: userData.use_default_link[carrierId],
              user_id: UserProfileService.getUserId(),
            }

            UserCarrierContractService.update(
              userData.carrierConfigExist[carrierId],
              linkData
            )
          }
          else if (userData.use_default_link[carrierId] !== undefined) {
            let linkData = {
              carrier_id: carrierId,
              use_default_link: userData.use_default_link[carrierId],
              user_id: UserProfileService.getUserId(),
            }
            UserCarrierContractService.store(linkData)
          }
        }
      }
    }
    catch(error) {
      console.error(
        'An error occurred while processing the link data:',
        error
      )
    }

    try {
      if (this.state.userData['commision_id']) {
        for (let carrierId in userData.commision_id) {

          if (userData.commision_id.hasOwnProperty(carrierId)) {
            if (
              userData.commision_id[carrierId] !== undefined &&
              userData.carrierConfigExist[carrierId] !== undefined
            ) {
              let commissionData = {
                carrier_id: carrierId,
                commision_id: userData.commision_id[carrierId],
                use_default_link: userData.use_default_link[carrierId],
                user_id: UserProfileService.getUserId(),
              }
              UserCarrierContractService.update(
                userData.carrierConfigExist[carrierId],
                commissionData
              )
            } else if (userData.commision_id[carrierId] !== undefined) {
              let commissionData = {
                carrier_id: carrierId,
                commision_id: userData.commision_id[carrierId],
                user_id: UserProfileService.getUserId(),
              }
              UserCarrierContractService.store(commissionData)
            }
          }
        }
      }
    } catch (error) {
      console.error(
        'An error occurred while processing the commission data:',
        error
      )
      // Handle the error appropriately, such as setting an error state or notifying the user
    }
  }

  render() {
    return (
      <div id="CarrierCommisionModal">
        <MDBBtn
          className="btn-block mb-1"
          color="indigo"
          onClick={this.#toggleCarrierCommisionModal}
        >
          <MDBIcon icon="user-circle" className="mr-1" /> Contracting
          Configuration
        </MDBBtn>

        <MDBModal
          size="xl"
          isOpen={this.state.isOpen}
          toggle={this.#toggleCarrierCommisionModal}
        >
          <MDBModalHeader toggle={this.#toggleCarrierCommisionModal}>
            Contracting Configuration
          </MDBModalHeader>
          <MDBModalBody className={this.state.isLoading ? 'is-loading' : ''}>
            {this.state.isLoading ? (
              <div className="loading-wrapper">
                <LoadingSpinner size="md" isActive={true} />
              </div>
            ) : (
              <>
                <div>
                  {
                    <MDBDataTable
                      paging={true}
                      striped
                      order={['name', 'asc']}
                      bordered
                      data={this.state.data}
                      style={{
                        tableLayout: 'fixed', // Ensures fixed column width
                        width: '100%',
                      }}
                    />
                  }
                </div>
                <MDBBtn
                  color="indigo"
                  block
                  className="btn btn-info"
                  onClick={() => {
                    // Add Update Commison setting callback
                    // console.log(this.state.userData)

                    this.#saveCarrierCommission()
                    this.#toggleCarrierCommisionModal()
                  }}
                >
                  Update
                </MDBBtn>
                <MDBBtn
                  color="warning"
                  size={'sm'}
                  block
                  onClick={this.#toggleCarrierCommisionModal}
                >
                  Close
                </MDBBtn>
              </>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}

export default CarrierCommisionModal
