import BaseModelFactory from './base.factory'
import ContactService from './../services/Contact.service'
import Contact from './../models/contact.model'

class ContactFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: ContactService, Model: Contact }
    )
  static findById = async (id) =>
    await this._findBy({ id }, { Service: ContactService, Model: Contact })
  static create = (payload) =>
    this._create(payload, {
      Service: ContactService,
      Model: Contact,
    })
}

export default ContactFactory
