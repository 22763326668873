import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/leader_report_cards'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  drill: async (id, metric_name) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      {},
      { endpoint: `/${id}/drill/${metric_name}` }
    ),
  download: async (id, metric_name) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      { id },
      { endpoint: `/download/${metric_name}` }
    ),
  getLastReport: async (id, type, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, {
      endpoint: `/${id}/${type}/getLastReport`,
    }),
}
