import React, { useState, useEffect } from 'react'
import env from './../../../environments/environment'
import { observer } from 'mobx-react-lite'
import CrmStore from './../crm.store'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBAlert,
} from 'mdbreact'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import LocationSelector from './../LocationSelector/LocationSelector.component'
import NewContactBtn from './../NewContactBtn/NewContactBtn.component'
import ContactsList from './../ContactsList/ContactsList.component'
import ContactForm from './../ContactForm/ContactForm.component'

import './ContactManager.component.scss'

const openLogin = () => {
  const redirectUrl = env.integrations.bm10x.redirect_url,
    scopes = [
      'contacts.readonly',
      'contacts.write',
      'opportunities.readonly',
      'opportunities.write',
      'locations.readonly',
      'locations/customFields.readonly',
      'locations/customFields.write',
    ],
    clientId = env.integrations.bm10x.client_id

  window.location.assign(
    `https://marketplace.leadconnectorhq.com/oauth/chooselocation?response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}&scope=${encodeURIComponent(
      scopes.join(' ')
    )}`
  )
}

export default observer(({ locationId }) => {
  const [mustAuth, setMustAuth] = useState(null),
    {
      isAssigned,
      isEditing,
      Contact,
      resetContact,
      isLocationAuthenticated,
      assignContact,
    } = CrmStore

  const requiresAuth = () => {
    return mustAuth === true
  }

  const isCheckingAuth = () => {
    return mustAuth !== false
  }

  useEffect(() => {
    isLocationAuthenticated(locationId).then((res) => {
      if (locationId && parseInt(res?.status) === 401) setMustAuth(true)
      else if (res && res?.id) setMustAuth(false)
      else if (res && res?.error) console.log('error: ' + res?.error)
      else if (locationId === false && !res) setMustAuth(false)
      else if (!locationId && !res) {
      }
    })
  }, [locationId])

  if (requiresAuth()) {
    return (
      <MDBContainer
        id="ContactManagerComponent"
        className="contact-manager-component"
      >
        <ContentHtml contentSlug="10x-authentication-how-to" />
        <MDBRow className="auth-location">
          <MDBCol size="12">
            <button onClick={() => openLogin()} className="login-crm-btn">
              Login to & integrate my USABG 10X CRM.&nbsp;
              <MDBIcon fas icon="external-link-alt" />
            </button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  if (isCheckingAuth())
    return (
      <MDBContainer
        id="ContactManagerComponent"
        className="contact-manager-component"
      >
        <MDBRow className="auth-location">
          <MDBCol size="12">Loading your 10X CRM account.</MDBCol>
        </MDBRow>
      </MDBContainer>
    )

  return (
    <MDBContainer
      id="ContactManagerComponent"
      className="contact-manager-component"
    >
      {!isAssigned ? (
        <MDBRow className="start-contact">
          <MDBCol size="12">
            <ContentHtml contentSlug="10x-contact-search-how-to" />
          </MDBCol>
          <MDBCol size="12" md="8">
            <ContactsList locationId={locationId} disabled={isEditing} />
          </MDBCol>
          <MDBCol size="12" md="4">
            <NewContactBtn locationId={locationId} disabled={isEditing} />
          </MDBCol>
        </MDBRow>
      ) : (
        <></>
      )}

      {isEditing && !isAssigned ? (
        <MDBRow>
          <MDBCol>
            <br />
            <ContactForm
              onSuccess={(Contact) => {
                resetContact()
                assignContact(Contact)
              }}
            />
          </MDBCol>
        </MDBRow>
      ) : (
        <></>
      )}

      {isAssigned && Contact ? (
        <MDBRow className="selected-contact">
          <MDBCol size="12">
            <MDBAlert>
              <span>
                <span>Using 10X contact:</span>&nbsp;
                {`${Contact?.get('first_name')} ${Contact?.get('last_name')}`}
              </span>
              <MDBBtn onClick={() => resetContact()}>
                Change Contact&nbsp;
                <MDBIcon icon="times-circle" />
              </MDBBtn>
            </MDBAlert>
          </MDBCol>
        </MDBRow>
      ) : (
        <></>
      )}
    </MDBContainer>
  )
})
