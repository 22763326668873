import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon, MDBContainer } from 'mdbreact'
import {
  UIInput,
  UIInputToggleSwitch,
} from './../../../components/forms/form-fields'
import AddDocsStore from './AdditionalDocuments.store'
import env from '../../../environments/environment'

import './AdditionalDocumentsList.scss'

const AdditionalDocumentsList = ({
  Contract,
  Carrier,
  userId,
  updateParams,
}) => {
  const contractId = Contract?.get('id')

  const getDocParams = (Document) => {
    let params = { send_to_carrier: null }

    if (Document) {
      try {
        params =
          typeof Document.get('params') === 'string'
            ? JSON.parse(Document.get('params'))
            : Document.get('params')
      } catch (ex) {
        console.log('cant read params', ex)
      }
    }

    return params
  }

  const list = () => {
    if (AddDocsStore.hasFetched !== true) return <></>

    return (
      <ul className="documents-list">
        <li>
          <div>Document title</div>
          <div>Send to Carrier</div>
          <div>Actions</div>
        </li>
        {AddDocsStore.Documents.map((Document, idx) => {
          let params = getDocParams(Document),
            isSaving = AddDocsStore.isSaving(Document.get('id')),
            isSaved = !isSaving && AddDocsStore.isSaved(Document.get('id'))
          return (
            <li key={`aval-user-doc-${idx}`}>
              <div>
                {
                  <UIInput
                    name="description"
                    required={true}
                    disabled={isSaving}
                    value={Document.get('description')}
                    onChange={(e) => {
                      Document.set('description', e.target.value)
                    }}
                  />
                }
              </div>
              <div>
                {
                  <UIInputToggleSwitch
                    id={`send_to_carrier_${Document.get('id')}`}
                    name={`send_to_carrier_${Document.get('id')}`}
                    value={!!params?.send_to_carrier}
                    width="70"
                    labelOn="ON"
                    labelOff="OFF"
                    onChange={(e) => {
                      if (!isSaving) {
                        e = !!e.target.value
                        Document.set(
                          'params',
                          JSON.stringify({ ...params, send_to_carrier: e })
                        )
                      }
                    }}
                  />
                }
              </div>
              <div title="Save">
                <MDBBtn
                  block
                  size="sm"
                  disabled={
                    !Document.get('description') ||
                    isSaving ||
                    !Document.hasChanged
                  }
                  color="success"
                  onClick={() => AddDocsStore.save(Document)}
                >
                  {isSaving ? (
                    <span>
                      <i className="fa fa-spin fa-spinner"></i>
                    </span>
                  ) : (
                    <MDBIcon icon={isSaved ? 'check' : 'save'} />
                  )}
                </MDBBtn>
              </div>
              <div title="Download">
                <MDBBtn
                  block
                  size="sm"
                  disabled={false}
                  color="primary"
                  target="_blank"
                  download={Document.get('description')}
                  href={`https://firebasestorage.googleapis.com/v0/b/${
                    env.integrations.firebase.config.storageBucket
                  }/o/${encodeURIComponent(
                    Document.get('filepath')
                  )}?alt=media`}
                >
                  <MDBIcon icon="download" />
                </MDBBtn>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  useEffect(() => {
    if (userId && contractId) AddDocsStore.fetch(userId, contractId)
  }, [userId, contractId])

  return (
    <MDBContainer className="AdditionalDocumentsListComponent" fluid>
      {list()}
    </MDBContainer>
  )
}

export default observer(AdditionalDocumentsList)
