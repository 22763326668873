import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { UIDropdown } from './../../components/forms/form-fields'
import ContactList from './ContactList.component'
import ContactSearch from './ContactSearch.component'
import CreateContactBtn from './CreateContactBtn.component'
import Store from './CRM.store'

const ContactSelector = ({ Locations, Location }) => {
  const isDisabled = !Store.Locations || !Store.Locations.length,
    options = toJS(Store.Locations || []).sort((a, b) =>
      a.get('name').localeCompare(b.get('name'))
    )

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <ContactSearch />
          </td>
          <td>
            <CreateContactBtn />
          </td>
        </tr>
        <tr>
          <td>
            <ContactList />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default observer(ContactSelector)
