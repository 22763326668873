import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import {
  UIInput,
  UIInputCheckbox,
} from './../../../../components/forms/form-fields'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBCollapse,
} from 'mdbreact'
import Store from './../../shared/Enrollment.store'

import './IntakeFormAcceptance.scss'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import StorageService from '../../../../shared/services/Storage.service'

const FIELDS = ['term_set', 'term_set_signature']

const IntakeFormAcceptance = ({ upline, onComplete }) => {
  const [submitted, setSubmitted] = useState(false),
    [warning, setWarning] = useState(false)

  useEffect(() => {
    // let completed_enroll = UserProfileService.get('completed_enroll', true)
    // if (typeof completed_enroll !== 'number') {
    //   completed_enroll = completed_enroll * 1
    // }
    // if (completed_enroll) {

    const isUserLoggedIntoAssumedRecruiter = !!(
        Store.isAssumed() &&
        Store.isRecruiter(UserProfileService.get('usertype_id', false))
      ),
      isNotAssumedOrRecruiter = !!(
        !Store.isAssumed() &&
        !Store.isRecruiter(UserProfileService.get('usertype_id', true))
      ),
      isRecruiterLoggedIntoEnrollee = !!(
        Store.isAssumed() &&
        Store.isRecruiter(UserProfileService.get('usertype_id', true))
      ),
      isUserLoggedIntoOwnAccount =
        Store.isAuthenticated() && Store.isAssumed() && !Store.isEnrolled()
          ? true
          : false

    Store.intake.u_fname = !!Store.intake.u_fname?.trim()
      ? Store.intake.u_fname.trim()
      : isUserLoggedIntoOwnAccount && UserProfileService.get('u_fname', false)
      ? UserProfileService.get('u_fname', false)
      : isUserLoggedIntoAssumedRecruiter
      ? ''
      : (isNotAssumedOrRecruiter && UserProfileService.get('u_fname', false)) ||
        (isRecruiterLoggedIntoEnrollee &&
          UserProfileService.get('u_fname', false)) ||
        ''
    Store.intake.u_lname = !!Store.intake.u_lname?.trim()
      ? Store.intake.u_lname.trim()
      : isUserLoggedIntoOwnAccount && UserProfileService.get('u_lname', false)
      ? UserProfileService.get('u_lname', false)
      : isUserLoggedIntoAssumedRecruiter
      ? ''
      : (isNotAssumedOrRecruiter && UserProfileService.get('u_lname', false)) ||
        (isRecruiterLoggedIntoEnrollee &&
          UserProfileService.get('u_lname', false)) ||
        ''

    // }
  }, [])

  const matchSignature = async ({ value }) => {
    const name = [
      Store.intake.u_fname?.trim(),
      Store.intake.u_lname?.trim(),
    ].join(' ')
    if (name === value) {
      return {
        isValid: true,
        errors: [],
      }
    }

    return {
      isValid: false,
      errors: ["Signature doesn't match name."],
    }
  }

  const onKeyDown = (evt) => {
    try {
      if (evt.key.toLowerCase() === 'enter' && !submitted) onSubmit()
    } catch (ex) {}
  }

  const onSubmit = (evt) => {
    if (evt) evt.preventDefault()

    if (!submitted) setSubmitted(true)

    if (Store.isValid(FIELDS)) onComplete('IntakeFormPayment')

    return false
  }

  const idxPosition = (idx) => {
    return toJS(Store.intake.term_set).indexOf(parseInt(idx))
  }

  const isAccepted = (idx) => idxPosition(parseInt(idx)) > -1

  const accept = (idx) => {
    Store.intake.term_set = [...toJS(Store.intake.term_set)].concat([
      parseInt(idx),
    ])
  }

  const unaccept = (idx) => {
    Store.intake.term_set = toJS(Store.intake.term_set).filter(
      (prev) => parseInt(prev) !== parseInt(idx)
    )
  }

  const toggleAcceptance = (idx) => {
    let res = []
    if (isAccepted(idx)) {
      res = toJS(Store.intake.term_set).filter(
        (prev) => parseInt(prev) !== parseInt(idx)
      )
      unaccept(idx)
    } else {
      res = [...toJS(Store.intake.term_set)].concat([parseInt(idx)])
      accept(idx)
    }
    return res
  }

  const getTermSet = (usertypeId) => {
    const TermSet = Store.TermSets[usertypeId]
    if (TermSet) {
      return TermSet.get('term_set')
    }
    return []
  }

  const getTermIds = (usertypeId) => {
    const TermSet = Store.TermSets[usertypeId]
    if (TermSet) {
      return TermSet?.termIds
    }
    return []
  }

  const getTermSetId = (usertypeId) => {
    const TermSet = Store.TermSets[usertypeId]
    if (TermSet) {
      return TermSet?.id()
    }
    return null
  }

  return (
    <MDBContainer
      tag="form"
      fluid
      id="IntakeFormAcceptanceComponent"
      className="intake-form"
      onSubmit={onSubmit}
      noValidate
    >
      <MDBRow>
        <MDBCol size="12" className="stage-heading">
          <h3>
            <strong>Terms & Conditions</strong>
          </h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" className="accept-all">
          <UIInputCheckbox
            label="Accept All Terms"
            name={`enroll_accept_all_term`}
            id={`enroll_accept_all_term`}
            onChange={(evt) => {
              getTermSet(Store.usertypeId).forEach((term, idx) => {
                if (!isAccepted(idx)) accept(idx)
              })

              if (warning) setWarning(false)

              Store.intake.term_ids = getTermIds(Store.usertypeId)
              Store.intake.termset_id = getTermSetId(Store.usertypeId)

              Store.validity.term_set = {
                isValid: true,
                errors: [],
              }
            }}
          />
        </MDBCol>

        {getTermSet(Store.usertypeId).map((term, idx) => {
          return (
            <MDBCol size="12" key={`acceptance-term-${Store.termSetId}-${idx}`}>
              <UIInputCheckbox
                label={<span dangerouslySetInnerHTML={{ __html: term }}></span>}
                name={`enroll_accept_term_${idx}`}
                id={`enroll_accept_term_${idx}`}
                onChange={(evt) => {
                  let isValid =
                    toggleAcceptance(idx).length ===
                    getTermSet(Store.usertypeId).length

                  if (isValid && warning) setWarning(false)

                  let termIds = isValid ? getTermIds(Store.usertypeId) : [],
                    termSetId = isValid ? getTermSetId(Store.usertypeId) : null

                  Store.intake.term_ids = termIds
                  Store.intake.termset_id = termSetId

                  Store.validity.term_set = {
                    isValid: isValid,
                    errors: [],
                  }
                }}
                checked={isAccepted(idx)}
              />
            </MDBCol>
          )
        })}
      </MDBRow>
      <MDBRow>
        <MDBCol
          size="12"
          onClick={() => setWarning(Store.validity.term_set?.isValid !== true)}
        >
          <UIInput
            label="Electronic Signature"
            name="term_set_signature"
            type="text"
            disabled={Store.validity.term_set?.isValid !== true}
            onChange={(evt) => {
              Store.intake.term_set_signature = evt.target.value
              StorageService.set('term_set_signature', evt.target.value)
            }}
            required={true}
            value={Store.intake.term_set_signature}
            rules={{
              required: true,
              minLength: 2,
              maxLength: 255,
              callback: ({ value }) => matchSignature({ value }),
            }}
            showValidity={submitted}
            onValidityChange={(validity) =>
              (Store.validity.term_set_signature = validity)
            }
            onKeyDown={onKeyDown}
          />
          <MDBCollapse isOpen={warning} className="requires-sigs-warning">
            <span className="text--invalid">
              Make sure to accept all terms before signing.
            </span>
          </MDBCollapse>
          <div className="enroll-as">
            <span>
              Enrolling as:{' '}
              {[Store.intake.u_fname, Store.intake.u_lname].join(' ')}
            </span>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="intake-action-btns">
        <MDBCol size="12" md="6">
          <MDBBtn
            type="button"
            className="intake-action-btn"
            onClick={() => onComplete('IntakeFormPackageSelection')}
          >
            <MDBIcon icon="angle-double-left" size="lg" />
            &nbsp;Back to Package Selection
          </MDBBtn>
        </MDBCol>
        <MDBCol size="12" md="6">
          <MDBBtn
            type="submit"
            className={
              'intake-action-btn ' +
              (!Store.isValid(FIELDS) ? 'appear-disabled' : '')
            }
          >
            Next: Billing Profile&nbsp;
            <MDBIcon icon="angle-double-right" size="lg" />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(IntakeFormAcceptance)
