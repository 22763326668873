import React, { Component } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBAlert,
} from 'mdbreact'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import AdminApi from './../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AdminContentEditPopupComponent from './../../components/adminShared/AdminContentEditPopupComponent'
import ContentService from './../../shared/services/Content.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import MessageViewer from './../../components/MessageViewer/MessageViewer.component'
import PerformanceWidget from './components/PerformanceWidget/PerformanceWidget.component'
import AlertMessage from './components/AlertMessage/AlertMessage.component'

import './DashboardPage.scss'

const youtubeURLRegex =
  /https:\/\/www.youtube.com\/(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()[]{};:'".,<>?«»“”‘’]))?/

class DashboardPage extends Component {
  state = {
    messageBoardAlert: {},
    fullMessageAlertModal: false,
    featuredVideo1: '',
    featuredVideo2: '',
    activeDivisionId: UserProfileService.get('u_devision'),
    annualTheme: null,
    showMigrationOtp: false,
    copiedEmail: false,
    copiedPass: false,
  }

  componentDidMount() {
    this.setState({ currentUser: UserProfileService.getUserDetails(true) })

    this._fetchAnnualTheme()
    this._fetchMessageBoardAlert()
    this._fetchfeatureVideo1(16)
    this._fetchfeatureVideo2(41)
  }

  _fetchAnnualTheme = async () => {
    AdminApi.getAgencyInfo()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({ annualTheme: content.a_annual_theme })
        }
      })
      .catch((error) => {})
  }

  _fullMessageAlertModal_toggle = () =>
    this.setState({ fullMessageAlertModal: !this.state.fullMessageAlertModal })

  _numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)

  _fetchMessageBoardAlert = async () => {
    AdminApi.getMessageBoardAlert()
      .then((result) =>
        this.setState({ messageBoardAlert: result.data.data || {} })
      )
      .catch((error) =>
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      )
  }

  _fetchfeatureVideo1 = async (id) => {
    try {
      let url = await ContentService.getPageById(id)
      this.setState({ featuredVideo1: url && url.content })
    } catch (ex) {
      toast.error(ex, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  _fetchfeatureVideo2 = async (id) => {
    try {
      let url = await ContentService.getPageById(id)
      this.setState({ featuredVideo2: url && url.content })
    } catch (ex) {
      toast.error(ex, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  _renderFeatureVideo = (pos) => {
    let url = null

    switch (pos) {
      case '1':
      case 1:
        url = this.state.featuredVideo1
        break
      case '2':
      case 2:
        url = this.state.featuredVideo2
        break
      default:
        break
    }

    if (url && `${url}`.match(/(vimeo\.com)/gi))
      return (
        <>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src={url.replace(/(<([^>]+)>)/gi, '')}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              title="Rick Banville Testimonial"
            ></iframe>
          </div>
        </>
      )

    if (
      url &&
      `${url}`.match(/(youtube\.com|ytimg\.com|googlevideo\.com|youtu\.be)/gi)
    )
      return (
        <>
          <iframe
            width="100%"
            height="auto"
            src={url.match(youtubeURLRegex)}
            title="USABG Video Player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </>
      )

    return url ? <div dangerouslySetInnerHTML={{ __html: url }} /> : <></>
  }

  _renderBannerBlock = () => {
    const { currentUser } = this.state

    if (!!this.state.featuredVideo1) {
      return (
        <MDBRow>
          <MDBCol size="12" sm="4" md="4" lg="4" className="video-wrapper">
            <div className="position-relative">
              {this._renderFeatureVideo(1)}
              {currentUser &&
                [36, 219, 222].includes(currentUser.usertype_id) && (
                  <AdminContentEditPopupComponent
                    type="RTE"
                    iconPosition="top-left"
                    iconStyle={{ transform: 'translate(-110%, 0)' }}
                    pageId={16}
                    color="primary"
                    callback={this.fetchfeatureVideo1}
                  />
                )}
            </div>
          </MDBCol>
          <MDBCol size="12" sm="8" md="8" lg="8" className="mr-auto ml-auto">
            <div
              className="mb-2 d-flex h-100 align-items-center theme-banner-mid"
              style={{ textAlign: 'center' }}
            >
              <img
                alt="Stronger Together 2023"
                src={this.state.annualTheme}
                className="img-fluid"
              />
            </div>
          </MDBCol>
        </MDBRow>
      )
    }

    return (
      <MDBRow>
        <div className="video-wrapper">
          {currentUser && [36, 219, 222].includes(currentUser.usertype_id) && (
            <AdminContentEditPopupComponent
              type="RTE"
              iconPosition="top-left"
              iconStyle={{ transform: 'translate(-40%, 0)' }}
              pageId={16}
              color="primary"
              callback={this.fetchfeatureVideo1}
            />
          )}
        </div>
        <MDBCol size="12" sm="12" md="12" lg="12" className="mr-auto ml-auto">
          <div
            className="mb-2 d-flex h-100 align-items-center theme-banner-full"
            style={{ textAlign: 'center' }}
          >
            <img
              alt="Stronger Together 2023"
              src={this.state.annualTheme}
              className="img-fluid"
            />
          </div>
        </MDBCol>
      </MDBRow>
    )
  }

  render() {
    toast.configure()

    const { loading, messageBoardAlert, currentUser, showMigrationOtp } =
      this.state
    let enableMigrationOtp = !!UserProfileService.get('migration_acct_otp')

    return (
      <>
        <DashboardLayout>
          <main id="DashboardPage" className="mainSection">
            {/* ----- First Featured Video & Bigger Vision Img Banner ----- */}
            <MDBContainer fluid className="mt-3">
              {this._renderBannerBlock()}
            </MDBContainer>

            {/* ----- Alerts ----- */}
            <MDBContainer fluid className="mt-3">
              <MDBRow>
                <MDBCol size="12">
                  <AlertMessage
                    message={messageBoardAlert?.message}
                    alertType={messageBoardAlert?.alert_type}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            {/* ----- Second Featured Video & Message Board ----- */}
            <MDBContainer fluid className="mt-3">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <MDBRow>
                    {!!this.state.featuredVideo2 ? (
                      <>
                        <MDBCol
                          size="12"
                          sm={this.state.featuredVideo2 ? '4' : '0'}
                          md={this.state.featuredVideo2 ? '4' : '0'}
                          lg={this.state.featuredVideo2 ? '4' : '0'}
                          className="video-wrapper"
                        >
                          <div className="position-relative">
                            {this._renderFeatureVideo(2)}
                            {currentUser &&
                              [36, 219, 222].includes(
                                currentUser.usertype_id
                              ) && (
                                <AdminContentEditPopupComponent
                                  type="RTE"
                                  iconPosition="top-left"
                                  iconStyle={{
                                    transform: 'translate(-110%, 0)',
                                  }}
                                  pageId={41}
                                  color="primary"
                                  callback={this.fetchfeatureVideo2}
                                />
                              )}
                          </div>
                        </MDBCol>
                      </>
                    ) : (
                      <>
                        <div className="video-wrapper">
                          {currentUser &&
                            [36, 219, 222].includes(
                              currentUser.usertype_id
                            ) && (
                              <AdminContentEditPopupComponent
                                type="RTE"
                                iconPosition="top-left"
                                iconStyle={{ transform: 'translate(-40%, 0)' }}
                                pageId={41}
                                color="primary"
                                callback={this.fetchfeatureVideo2}
                              />
                            )}
                        </div>
                      </>
                    )}
                    <MDBCol
                      size="12"
                      sm={this.state.featuredVideo2 ? '8' : '12'}
                      md={this.state.featuredVideo2 ? '8' : '12'}
                      lg={this.state.featuredVideo2 ? '8' : '12'}
                      className="mr-auto ml-auto message-viewer-wrapper"
                    >
                      <MessageViewer viewerName={'USABG'} />
                      <br />
                      <MessageViewer
                        viewerName={'Division'}
                        division_id={this.state.activeDivisionId}
                        isHomepageBoard={true}
                        hideIfEmpty={true}
                        headerColor={'sky-blue'}
                      />
                    </MDBCol>
                  </MDBRow>

                  {UserProfileService.isA([
                    'affiliate-group',
                    'associate-group',
                  ]) ? (
                    <></>
                  ) : (
                    <>
                      <br />
                      <div id="updated-account-details">
                        <MDBAlert color="warning">
                          <MDBRow>
                            <MDBCol size="12">
                              <h5>
                                <strong>
                                  IMPORTANT: USABG-Amerilife email upgrade
                                  details.
                                </strong>
                              </h5>
                              <MDBBtn
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    showMigrationOtp:
                                      !this.state.showMigrationOtp,
                                  }))
                                }
                              >
                                Click to Read Now!
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol>
                              <MDBCollapse isOpen={showMigrationOtp}>
                                <ul>
                                  <li>
                                    <strong>
                                      The USABG corporate email server updates
                                      have been completed.
                                    </strong>
                                  </li>
                                  <li>
                                    If you have a USABG corporate email and you
                                    are experiencing any issues, please contact
                                    support as soon as possible.
                                  </li>
                                  <li>
                                    <a href="tel:17272167000">
                                      1 (727) 216-7000 Opt. 3
                                    </a>
                                    <br />
                                    OR
                                    <br />
                                    <a href="mailto:itsupport@amerilife.com">
                                      ITSupport@Amerilife.com
                                    </a>
                                  </li>
                                </ul>
                                <hr />
                                <h6>
                                  <strong>
                                    Looking for Self-Help or Setup Instructions?
                                  </strong>
                                </h6>
                                <ul>
                                  <li>
                                    You can download a step-by-step guide here:{' '}
                                    <a
                                      href="https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/agency_document%2Fagency_document.a905a97b-f330-4fcd-a358-a4dc9afb2969.pdf?alt=media"
                                      target="_BLANK"
                                    >
                                      Download How-To
                                    </a>
                                    .
                                  </li>
                                </ul>
                                {enableMigrationOtp ? (
                                  <>
                                    <hr />
                                    <h6>
                                      <strong>Your Account Details:</strong>
                                    </h6>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>Email:</td>
                                          <td>
                                            {UserProfileService.get(
                                              'migration_acct_eml'
                                            )}
                                            &nbsp;
                                            {this.state.copiedEmail ? (
                                              <span className="blue-text p-1">
                                                Copied!
                                              </span>
                                            ) : (
                                              <CopyToClipboard
                                                text={UserProfileService.get(
                                                  'migration_acct_eml'
                                                )}
                                                onCopy={() =>
                                                  this.setState(
                                                    { copiedEmail: true },
                                                    () =>
                                                      setTimeout(
                                                        () =>
                                                          this.setState({
                                                            copiedEmail: false,
                                                          }),
                                                        3500
                                                      )
                                                  )
                                                }
                                              >
                                                <MDBIcon
                                                  icon="copy"
                                                  className="blue-text p-1"
                                                />
                                              </CopyToClipboard>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Password:</td>
                                          <td>
                                            {UserProfileService.get(
                                              'migration_acct_otp'
                                            )}
                                            &nbsp;
                                            {this.state.copiedPass ? (
                                              <span className="blue-text p-1">
                                                Copied!
                                              </span>
                                            ) : (
                                              <CopyToClipboard
                                                text={UserProfileService.get(
                                                  'migration_acct_otp'
                                                )}
                                                onCopy={() =>
                                                  this.setState(
                                                    { copiedPass: true },
                                                    () =>
                                                      setTimeout(
                                                        () =>
                                                          this.setState({
                                                            copiedPass: false,
                                                          }),
                                                        3500
                                                      )
                                                  )
                                                }
                                              >
                                                <MDBIcon
                                                  icon="copy"
                                                  className="blue-text p-1"
                                                />
                                              </CopyToClipboard>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">
                                            <MDBBtn
                                              disabled={
                                                !!UserProfileService.get(
                                                  'migration_acct_ack'
                                                )
                                              }
                                              onClick={() =>
                                                this.setState((prevState) =>
                                                  UserProfileService.upsertUserMeta(
                                                    'system---aml-migration-ack',
                                                    new Date()
                                                  )
                                                )
                                              }
                                            >
                                              Click here when you have saved the
                                              one-time password.
                                            </MDBBtn>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </MDBCollapse>
                            </MDBCol>
                          </MDBRow>
                        </MDBAlert>
                      </div>
                    </>
                  )}

                  {/* ----- Top 5 PIP & Performance Widgets ----- */}
                  <PerformanceWidget />
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default DashboardPage
