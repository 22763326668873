import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AuthStore from './../Auth.store'
import AuthMessages from './../AuthMessages/AuthMessages.component'
import { UIInputCheckbox } from '../../forms/form-fields'
import StorageService from '../../../shared/services/Storage.service'
import {
  ClarityTag,
  ClarityLogin,
} from './../../../core/tracking/Clarity.functions'
import { MDBCollapse, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import SignatureAgentTermsAcceptance from './SignatureAgentTermsAcceptance.component'

import './Login.scss'

const Login = ({ onSuccess }) => {
  const onLogin = async (event) => {
    event.preventDefault()

    ClarityTag('auth', 'login')

    let userDetails = {}
    try {
      if (!(userDetails = await AuthStore.handleLogin())) return
    } catch (ex) {}

    if (userDetails && userDetails?.usertype_id) {
      ClarityLogin({ userId: userDetails?.id })
      setTimeout(() => onSuccess(userDetails?.usertype_id), 500)
    }
  }

  return (
    <div id="LoginComponent">
      <div className="block-shadow text-center">
        <div className="block-inner">
          <p className="h7 text-abbey text-bold">Login To Your Account</p>
          <div className="offset-top-40 offset-sm-top-60">
            <span className="icon icon-xl icon-gray-base material-icons-lock_open"></span>
          </div>
        </div>
        <form
          method="POST"
          className="rd-mailform form-modern form-darker offset-top-40"
          onSubmit={onLogin}
          noValidate
        >
          <div className="block-inner">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Login Email"
                value={AuthStore.email}
                onKeyPress={() => (AuthStore.error = null)}
                onChange={(e) => (AuthStore[e.target.name] = e.target.value)}
              />
            </div>
            <div className="form-group offset-top-22">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                value={AuthStore.password}
                onKeyPress={() => (AuthStore.error = null)}
                onChange={(e) => (AuthStore[e.target.name] = e.target.value)}
              />
            </div>
            <SignatureAgentTermsAcceptance />
          </div>
          <AuthMessages />
          <div className="submit-wrapper">
            <button type="submit" className="btn btn-primary">
              {AuthStore.loading ? 'Logging In...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
      <span className="help-link">
        Forgot Password?&nbsp;&nbsp;&nbsp;
        <a
          href="/forgot-password"
          onClick={(e) => {
            e.preventDefault()
            ClarityTag('auth', 'forgot-password')
            AuthStore.goToState('forgot-password')
          }}
        >
          Click Here
        </a>
      </span>
    </div>
  )
}

export default observer(Login)
