import { makeAutoObservable } from 'mobx'
import LocationService from './../../shared/services/Location.service'
import { LocationFactory, ContactFactory } from './../../shared/factories'
import UserProfileService from './../../shared/services/UserProfile.service'

const isJson = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (ex) {
    return false
  }
}

const getContacts = async (locationId) => {
  try {
    try {
      return await ContactFactory.search({
        search: { location_id: locationId },
        pagination: false,
      })
    } catch (ex) {
      return []
    }
  } catch (ex) {
    return false
  }
}

const getUserLocationIds = async () => {
  let locationIds
  try {
    locationIds = (
      (await UserProfileService.getUserMetas('10x---location-id')) || []
    ).shift()
  } catch (ex) {
    return {}
  }

  if (isJson(locationIds)) locationIds = JSON.parse(locationIds)

  locationIds =
    locationIds && typeof locationIds === 'object' ? locationIds : null
  if (locationIds && locationIds?.meta_value) {
    if (
      typeof locationIds?.meta_value === 'string' &&
      isJson(locationIds?.meta_value)
    )
      return JSON.parse(locationIds.meta_value)
    if (typeof locationIds?.meta_value === 'object')
      return locationIds.meta_value
  }

  return {}
}

const getLocations = async (locationIds) => {
  const ids = (Array.isArray(locationIds) ? locationIds : []).filter((n) => !!n)
  if (ids.length) {
    try {
      return await LocationFactory.search({
        search: { id: locationIds },
        pagination: false,
      })
    } catch (ex) {
      return []
    }
  }
  return []
}

class CRMStore {
  Locations = []
  Location = null
  Contacts = []
  Contact = null
  myLocations = { recruiter: null, sales: null }
  isInitializing = false
  isInitialized = false

  constructor() {
    makeAutoObservable(this)
  }

  setMyLocations = async () => {
    const { recruiter, sales } = await getUserLocationIds(),
      myLocationIds = [recruiter, sales].filter((n) => !!n),
      MyLocations = myLocationIds.length && (await getLocations(myLocationIds))

    this.Contact = null
    this.Contacts = []
    this.Locations = MyLocations
    this.myLocations = { recruiter, sales }
    this.isInitialized = true
  }

  setLocation = (Location, fetchContacts) => {
    return !!(this.Location = Location || null)
  }

  update = (k, v) => {
    const Contact = this.Contact

    if (Contact && Contact?.set) console.log('set: ', k, v, Contact.set(k, v))

    this.Contact = Contact
  }

  createNewContact = async () => {
    const Contact = await ContactFactory.create({
      location_id: this.Location?.id(),
    })

    this.Contact = Contact
  }

  fetchContactsByLocation = async (locationId) => {
    let Contacts = []
    if (locationId) {
      try {
        Contacts = await getContacts(locationId)
      } catch (ex) {}
    }

    this.Contacts = Contacts
    return Contacts
  }

  canEditContact = () => {
    return this.Contact === null
  }

  editContact = async (Contact) => {
    this.Contact = Contact
  }

  init = async () => {
    if (this.isInitialized) return true

    this.isInitializing = true

    await this.setMyLocations()

    this.isInitializing = false
    this.isInitialized = true
  }
}

export default new CRMStore()
