import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  MDBCollapse,
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBSelect,
} from 'mdbreact'
import { UIInputCheckbox } from './../../../../components/forms/form-fields'
import ReportsStore from './../../store/ReportsPage.store'
import UserProfileService from './../../../../shared/services/UserProfile.service'

import './ReportFilters.scss'

const ReportFilters = ({
  category,
  coverages,
  isFetching,
  onChange,
  onChangeDivision,
  opts,
}) => {
  opts = opts && typeof opts === 'object' ? opts : {}
  const [cvrges, setCvrges] = useState(
    coverages
      ? Array.isArray(coverages)
        ? coverages
        : `${coverages}`.split(/-/)
      : []
  )
  const isCoverageSelected = (coverage) => cvrges.indexOf(coverage) > -1

  const onFilterChange = (changed) => {
    let c = cvrges
      ? Array.isArray(cvrges)
        ? cvrges
        : `${cvrges}`.split(/-/)
      : []

    if (isCoverageSelected(changed)) c.splice(c.indexOf(changed), 1)
    else c.push(changed)

    c.sort()

    onChange({ coverages: c.join('-') })
    setCvrges(c.join('-'))
  }

  const [divisions, setDivisions] = useState([])

  const canSelectDivision = () => {
    if (UserProfileService.isA('system-admin')) return true

    let details = UserProfileService.getUserDetails()
    return details && parseInt(details.u_upline_id) === 0
  }

  // const shouldEnableMedAdvBtn = () =>
  //   category &&
  //   [
  //     'division-totals',
  //     'region-totals',
  //     'district-totals',
  //     'career-totals',
  //   ].includes(`${category}`.trim().toLowerCase())

  useEffect(() => {
    if (
      canSelectDivision() &&
      (!category || category.indexOf('division') > -1)
    ) {
      ReportsStore.getDivisions().then((deps) => setDivisions(deps))
      onChangeDivision('all')
    }
  }, [category, onChangeDivision])

  return (
    <div id="ReportFilters">
      <MDBRow>
        <MDBCol>
          <MDBBtn
            className={
              'btn-orange btn-block ' +
              (isCoverageSelected('ACA') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('ACA')}
          >
            <MDBIcon icon="check" />
            ACA LIVES
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-red btn-block ' +
              (isCoverageSelected('HEALTH') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('HEALTH')}
          >
            <MDBIcon icon="check" />
            HEALTH
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-green btn-block ' +
              (isCoverageSelected('LIFE') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('LIFE')}
          >
            <MDBIcon icon="check" />
            LIFE
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn
            className={
              'btn-purple btn-block ' +
              (isCoverageSelected('MED_ADV') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('MED_ADV')}
          >
            <MDBIcon icon="check" />
            MED ADVANTAGE
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-caramel btn-block ' +
              (isCoverageSelected('MED_SUPPS') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('MED_SUPPS')}
          >
            <MDBIcon icon="check" />
            MED SUPPS
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBCollapse isOpen={true} className="remove-aca-av-toggle">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <UIInputCheckbox
                    label="Remove ACA from AV totals?"
                    id={`remove_aca_av`}
                    name={`remove_aca_av`}
                    checked={opts?.remove_aca_av || false}
                    disabled={isFetching}
                    onChange={(evt) => {
                      onChange({
                        options: {
                          remove_aca_av: !(opts?.remove_aca_av || false),
                        },
                      })
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default observer(ReportFilters)
