import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBCard, MDBCardBody } from 'mdbreact'
import appConstants from './../../constants/appConstants'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import AgencyContentStore from '../../shared/stores/agencyContent.store'
import AdminContentEditPopupComponent from '../../components/adminShared/AdminContentEditPopupComponent'

class EmailSignatures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formError: false,
      errorMessage: '',
      userData: [],
      content: null,
      content_id: null,
    }
  }

  componentDidMount() {
    this.fetchUserdata()
  }

  //for responsivness of content acc to screen size

  fetchUserdata = async () => {
    this.setState({ loading: true })

    AdminApi.getProfileData()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ userData: result.data.data })
        }
      })
      .then(async () => {
        await AgencyContentStore.fetchByContentObj({
          contentSlug: 'agent-email-signature',
        })
        this.setState({
          content: AgencyContentStore.content[
            'agent-email-signature'
          ][0]?.content?.replace(
            /{(u_fname|u_lname|u_phone|u_email|logo)}/g,
            (match, p1) => {
              const replacements = {
                u_fname: this.state.userData?.u_fname || '',
                u_lname: this.state.userData?.u_lname || '',
                u_phone: this.state.userData?.u_phone || '',
                u_email: this.state.userData?.u_email || '',
                logo: appConstants.logos.master.md,
              }
              return replacements[p1]
            }
          ),
          content_id:
            AgencyContentStore.content['agent-email-signature'][0]?.id,
        })

        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { loading, content_id, content } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Email Signatures</h2>
              <hr />
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <p>In order to change your signature in Microsoft Outlook:</p>
                  <ul>
                    <li>Click on the File tab.</li>
                    <li>Click Options.</li>
                    <li>Click Mail.</li>
                    <li>
                      In the Compose messages section at the very top, click the
                      Signatures button.
                    </li>
                    <li>
                      Either select Delete, New or just highlight a signature
                      and edit.
                    </li>
                    <li>
                      Copy and paste in your signature or create a new one
                    </li>
                    <li>When you are finished, click OK until you are out.</li>
                  </ul>
                  <p>
                    The following information is taken from your Contact
                    Information in our database. If you need to update it you
                    may do so in your profile.
                  </p>
                  <MDBCard>
                    <MDBCardBody>
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        {content ? (
                          <AdminContentEditPopupComponent
                            type="RTE"
                            color="blue"
                            pageId={content_id}
                            iconPosition={'top-right'}
                            iconStyle={{
                              transform: 'translate(-3px, 3px)',
                            }}
                            callback={() => this.fetchUserdata()}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default EmailSignatures
