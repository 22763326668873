import React from 'react'
import { observer } from 'mobx-react-lite'
import SalesTrackerStore from './../../store'
import { MDBCol } from 'mdbreact'
import { UIDropdown } from './../../../../components/forms/form-fields'

import './PointOverrides.component.scss'

const getOptions = (Policy, Overrides) => {
  return Overrides.map((Override) => ({
    text: <>{Override.get('label') + ' '}</>,
    value: `${Override.id()}`,
    checked: !!(
      Policy.get('pts_override_id') &&
      parseInt(Policy.get('pts_override_id')) === parseInt(Override.id())
    ),
  }))
}

const renderAmericaChoiceFamily = (Policy, policyIdx, Overrides) => {
  if (Array.isArray(Overrides))
    Overrides = [...Overrides].filter(
      (O) =>
        `${O.get('carrier_id')}` === `${Policy.get('carrier_id')}` ||
        `${O.get('coverage_id')}` === `${Policy.get('coverage_id')}`
    )

  if (Array.isArray(Overrides) && !!Overrides.length) {
    const O = [...Overrides].shift()
    return (
      <UIDropdown
        label="Family Selection:"
        name={O.get('field')}
        className="av-point-override"
        options={getOptions(Policy, Overrides)}
        selected={Policy.get('pts_override_id')}
        onChange={(evt) => {
          const value = parseInt(evt.target.value)
          if (value !== parseInt(Policy.get('pts_override_id')))
            Policy.set('pts_override_id', value)
        }}
        required={true}
        value={Policy.get('pts_override_id')}
        rules={{ required: true }}
        showValidity={SalesTrackerStore.showErrors}
        onValidityChange={(validity) =>
          SalesTrackerStore.setValidity(
            policyIdx,
            O.get('field'),
            validity.isValid
          )
        }
      />
    )
  }

  return <></>
}

export default observer(
  ({ Policy, coverageId, carrierId, policyIdx, Fields }) => {
    return [
      renderAmericaChoiceFamily(Policy, policyIdx, Fields?.ac_family),
    ].map((inputField, idx) => (
      <MDBCol
        key={`pts-override-field-${policyIdx}-${idx}`}
        size="12"
        md="6"
        xl="4"
        className="av-input-wrapper"
      >
        {inputField}
      </MDBCol>
    ))
  }
)
