import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBIcon,
  MDBDataTable,
} from 'mdbreact'
import UserDocumentService from '../../../../shared/services/UserDocument.service'
import { UserDocumentFactory } from '../../../../shared/factories'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import UserMetaService from '../../../../shared/services/UserMeta.service'
import UserStateLicenseService from '../../../../shared/services/UserStateLicense.service'
import UpdateVoidedCheckModal from '../../../../components/modals/UpdateVoidedCheckModal'
import MediaUploaderModal from '../../../../components/modals/MediaUploaderModal'
import UpdateStateLicenseModal from '../../../../components/modals/UpdateStateLicenseModal'
import ConfirmDeleteModalComponent from '../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import LoadingSpinner from '../../../../components/shared/LoadingSpinner.component'
import env from '../../../../environments/environment'
import { toast } from 'react-toastify'

import './UploadWidget.component.scss'

const UploadWidgetComponent = ({ type }) => {
  const [rows, setRows] = useState([]),
    [isLoading, setIsLoading] = useState(false),
    [deletedDoc, setDeletedDoc] = useState(null),
    [deletecDocId, setDeletedDocId] = useState(null),
    history = useHistory()

  const downloadDoc = (doc_path) => {
    const url = `https://firebasestorage.googleapis.com/v0/b/${
      env.integrations.firebase.config.storageBucket
    }/o/${encodeURIComponent(`${doc_path}`.replace(/^\//, ''))}?alt=media`
  }

  const downloadDocFromBase64 = async (Doc) => {
    let base64
    try {
      base64 = await Doc.download()
    } catch (ex) {
      console.error(`${ex}`)
    }

    if (base64.url) {
      window.open(base64.url, '_blank')
    }
  }

  const downloadDocument = (Document) => {
    if (Document && typeof Document === 'object') {
      if (
        Document &&
        Document?.constructor &&
        /UserDocument/i.test(Document?.constructor?.name)
      ) {
        return downloadDocFromBase64(Document)
      }
    } else if (Document && typeof Document === 'string') {
      window.open(
        `https://firebasestorage.googleapis.com/v0/b/${
          env.integrations.firebase.config.storageBucket
        }/o/${encodeURIComponent(`${Document}`.replace(/^\//, ''))}?alt=media`
      )
      return
    }
  }

  const deleteDoc = (id) => {
    setDeletedDocId(id)
    setDeletedDoc(type?.title)
  }

  const confirmDeleteDoc = async () => {
    setIsLoading(true)

    try {
      switch (type?.title) {
        case 'Direct Deposit':
          await UserMetaService.delete(deletecDocId)
          setDeletedDocId(null)
          setDeletedDoc(null)
          break
        default:
          break
      }
      toast.success('Document has been deleted.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } catch (error) {
      toast.error('Failed to delete a doc', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    await getDocs()
  }

  const renderAction = useCallback(
    (doc_path, id) => {
      if (doc_path && typeof doc_path === 'object') {
        return (
          <div className="d-flex h-100 align-items-center justify-content-center">
            <MDBIcon
              fas
              icon="download"
              size="1x"
              className="cursor-pointer"
              onClick={(evt) => downloadDocument(doc_path)}
            ></MDBIcon>
            {type?.can_delete && (
              <MDBIcon
                fas
                icon="trash"
                size="1x"
                className="cursor-pointer ml-3"
                onClick={(evt) => deleteDoc(doc_path.id())}
              ></MDBIcon>
            )}
          </div>
        )
      }
      // doc?.filepath, doc_meta?.id

      return (
        <div className="d-flex h-100 align-items-center justify-content-center">
          <MDBIcon
            fas
            icon="download"
            size="1x"
            className="cursor-pointer"
            onClick={(evt) => downloadDocument(doc_path)}
          ></MDBIcon>
          {type?.can_delete && (
            <MDBIcon
              fas
              icon="trash"
              size="1x"
              className="cursor-pointer ml-3"
              onClick={(evt) => deleteDoc(id)}
            ></MDBIcon>
          )}
        </div>
      )
    },
    [type]
  )

  const getW9AndDirectDepositDocs = async (meta_key) => {
    const doc_meta = (
      (
        await UserMetaService.search({
          pagination: false,
          search: {
            user_id: UserProfileService.getUserId(),
            meta_key: meta_key,
          },
          order_by: { created_at: 'DESC' },
        })
      )?.models || []
    ).shift()

    if (doc_meta) {
      const doc = await UserDocumentFactory.findById(doc_meta?.meta_value)

      setRows([
        {
          filename: doc?.get('filename'),
          actions: renderAction(doc),
        },
      ])
    } else setRows([])
  }

  const getVoidedChecks = async () => {
    const listed_docs =
      (
        await UserMetaService.search({
          pagination: false,
          search: {
            user_id: UserProfileService.getUserId(),
            meta_key: 'profile---voided-check',
          },
          order_by: { created_at: 'DESC' },
        })
      )?.models || []

    setRows(
      listed_docs.map((doc) => {
        return {
          filename: doc?.meta_value?.split('/').pop(),
          actions: renderAction(doc?.meta_value, doc?.id),
        }
      })
    )
  }

  const getFFMDocs = async () => {
    const listed_docs =
      (
        await UserDocumentService.search({
          pagination: false,
          search: {
            user_id: UserProfileService.getUserId(),
            relation_model: 'FFMDocument',
          },
          order_by: { created_at: 'DESC' },
        })
      )?.models || []

    setRows(
      listed_docs.map((doc) => {
        return {
          filename: doc?.filename,
          actions: renderAction(doc?.filepath, doc?.id),
        }
      })
    )
  }

  const getStateLicenses = async () => {
    const listed_docs =
      (
        await UserStateLicenseService.search({
          pagination: false,
          search: {
            user_id: UserProfileService.getUserId(),
          },
          order_by: { created_at: 'DESC' },
        })
      )?.models || []

    setRows(
      listed_docs.map((doc) => {
        return {
          state: doc?.state_abrev,
          filename: doc?.license_img?.split('/').slice(-1),
          actions: renderAction(doc?.license_img, doc?.id),
        }
      })
    )
  }

  const getDocs = async () => {
    setIsLoading(true)
    try {
      switch (type?.title) {
        case 'W9':
          await getW9AndDirectDepositDocs('wallet---wallet-w9')
          break
        case 'Direct Deposit':
          await getW9AndDirectDepositDocs('wallet---wallet-dd')
          break
        case 'Voided Checks':
          await getVoidedChecks()
          break
        case 'FFM/AHIP':
          await getFFMDocs()
          break
        case 'State Licenses':
          await getStateLicenses()
          break
        default:
          break
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error('Failed to get docs', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  useEffect(() => {
    getDocs()
  }, [type])

  const uploadDocs = () => {
    switch (type?.title) {
      case 'W9':
        updateAgentDocs(
          'wallet_w9',
          'Upload your most recent W9',
          '* USABG does not send your W9 to carriers automatically. It is your responsibility to update & notify carriers upon W9 changes.'
        )
        break
      case 'Direct Deposit':
        updateAgentDocs('wallet_dd', 'Upload your direct deposit form.')
        break
      case 'Voided Checks':
        updateVoidedCheck()
        break
      case 'FFM/AHIP':
        updateAgentDocs('ffm_document', 'Upload your FFM Document')
        break
      case 'State Licenses':
        updateStateLicenses()
        break
      default:
        break
    }
  }

  const updateVoidedCheck = () => {
    UpdateVoidedCheckModal.open({
      preventAutoClose: true,
      onSuccess: () => {
        toast.success('Document has been uploaded.', {
          position: toast.POSITION.TOP_RIGHT,
        })
        getDocs()
      },
    })
      .then()
      .catch((err) => {
        if (err)
          console.log(
            'Failed to complete uploading voided check documents.',
            err
          )
      })
  }

  const updateAgentDocs = (
    doc_type,
    modalContentTopTitle,
    modalContentTopDisclaimer
  ) => {
    MediaUploaderModal.open({
      uploadType: doc_type,
      modalContentTop: (
        <div>
          <div className="text--center fw--500">
            <h6 className="fw--500">{modalContentTopTitle}</h6>
          </div>
          {modalContentTopDisclaimer && (
            <div>
              <small>
                <strong>{modalContentTopDisclaimer}</strong>
              </small>
            </div>
          )}
        </div>
      ),
      preventAutoClose: true,
      onSuccess: () => {
        toast.success('Document has been uploaded.', {
          position: toast.POSITION.TOP_RIGHT,
        })
        getDocs()
      },
      validationRules: {
        contentType: ['application/pdf'],
      },
    })
      .then()
      .catch((err) => {
        if (err)
          console.log('Failed to complete uploading agent documents.', err)
      })
  }

  const updateStateLicenses = () => {
    UpdateStateLicenseModal.open({
      preventAutoClose: true,
      onSuccess: () => {
        getDocs()
      },
    })
      .then()
      .catch((err) => {
        if (err)
          console.log('Failed to complete uploading State Licenses.', err)
      })
  }

  return (
    <MDBCard>
      <MDBCardHeader color="indigo">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs--125rem">{type.title}</div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="cursor-pointer" onClick={(evt) => uploadDocs()}>
              <MDBIcon icon="upload" color="white" size="1x" className="mr-2" />
              Upload
            </div>
            {type?.redirect && (
              <div
                className="ml-4 cursor-pointer"
                onClick={(evt) => history.push(type.redirect)}
              >
                <MDBIcon fas icon="arrow-circle-right" size="lg" />
              </div>
            )}
          </div>
        </div>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBRow>
          <MDBCol size="12">
            {isLoading && (
              <div className="loader">
                <LoadingSpinner isActive={true} size="md" />
              </div>
            )}
            <div>
              <MDBDataTable
                sortRows={[]}
                striped
                bordered
                small
                data={{ columns: type?.table_cols, rows }}
                responsive
                className="upload-widget-table"
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
      {deletedDoc ? (
        <ConfirmDeleteModalComponent
          confirm={deletedDoc}
          onConfirm={() => confirmDeleteDoc()}
          shouldEnable={true}
          onCancel={() => {
            setDeletedDoc(null)
            setDeletedDocId(null)
          }}
        />
      ) : (
        <></>
      )}
    </MDBCard>
  )
}

export default UploadWidgetComponent
