import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { UIDropdown } from './../../components/forms/form-fields'
import { MDBIcon, MDBBtn } from 'mdbreact'
import Store from './CRM.store'

const ContactList = () => {
  const {
      Location,
      Contacts,
      fetchContactsByLocation,
      editContact,
      canEditContact,
    } = Store,
    locationId = Location?.id(),
    isDisabled = !Store.Locations || !Store.Locations.length,
    options = toJS(Store.Locations || []).sort((a, b) =>
      a.get('name').localeCompare(b.get('name'))
    ),
    canEdit = canEditContact()

  useEffect(() => {
    fetchContactsByLocation(locationId)
  }, [locationId])

  return (
    <div id="ContactListComponent">
      <ul>
        {(Array.isArray(Contacts) ? Contacts : []).map((Contact, idx) => {
          return (
            <li key={`10x-contact-${idx}-${Contact?.id()}`}>
              {Contact.get('first_name')} {Contact.get('last_name')}
              <MDBBtn disabled={!canEdit}>
                <MDBIcon
                  onClick={() => editContact(Contact)}
                  icon="edit"
                ></MDBIcon>
              </MDBBtn>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default observer(ContactList)
