import React, { Component } from 'react'
import { MDBContainer } from 'mdbreact'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import UsertypeService from './../../shared/services/Usertype.service'
import DivisionService from './../../shared/services/Division.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import PageHeading from './../../components/shared/PageHeading.component'
import Store from './store'
import ProfileForm from './components/ProfileForm/ProfileForm.component'

class CreateUser extends Component {
  state = {
    pageTitle: 'Create Admin/BA/CSR Agent',
    type: 'division',
    method: 'agent',
  }

  init = async () => {
    const config = {}

    switch (`${this.props?.location?.pathname}`.replace(/[^A-Za-z0-9-_]/, '')) {
      case 'admin-create-user':
        this.setState({ method: 'admin', pageTitle: 'Create User' })
        break

      case 'create-admin-or-csr-agent':
        if (
          !UserProfileService.isA([
            'system-admin',
            'internal-admin',
            'internal-staff',
            'agency-owner',
          ])
        ) {
          config.division_id = UserProfileService.get('u_devision', true)

          if (UserProfileService.isA(['divisional-leader', 'division-admin'])) {
            config.comm_level_ids = [210, 212]
            config.account_statuses = [1]
            config.usertype_ids = [
              UsertypeService.nameToId('division-admin'),
              UsertypeService.nameToId('csr-agent'),
            ]
            config.upline_ids = [
              (await DivisionService.get(UserProfileService.get('u_devision')))
                ?.d_owner_id,
            ]
          }

          if (UserProfileService.isA(['regional-leader', 'region-admin'])) {
            this.setState({ type: 'region' })
            config.comm_level_ids = [210, 212]
            config.account_statuses = [1]
            config.usertype_ids = [
              UsertypeService.nameToId('region-admin'),
              UsertypeService.nameToId('csr-agent'),
            ]
            config.upline_ids = [await UserProfileService.getRegion()]
          }

          if (UserProfileService.isA(['district-leader', 'district-admin'])) {
            this.setState({ type: 'district' })
            config.comm_level_ids = [210, 212]
            config.account_statuses = [1]
            config.usertype_ids = [
              UsertypeService.nameToId('district-admin'),
              UsertypeService.nameToId('csr-agent'),
            ]
            config.upline_ids = [await UserProfileService.getDistrict()]
          }

          if (UserProfileService.isA(['career-agent'])) {
            this.setState({ type: 'career' })
            config.comm_level_ids = [210]
            config.account_statuses = [1]
            config.usertype_ids = [UsertypeService.nameToId('csr-agent')]
            config.upline_ids = [UserProfileService.getUserId()]
          }
        } else {
          config.division_id = UserProfileService.get('u_devision', true)
          config.usertype_ids = [
            UsertypeService.nameToId('csr-agent'),
            UsertypeService.nameToId('division-admin'),
            UsertypeService.nameToId('region-admin'),
            UsertypeService.nameToId('district-admin'),
          ]
        }

        break

      default:
        break
    }

    // switch (`${this.props?.location?.pathname}`.replace(/[^A-Za-z0-9-_]/, '')) {
    //   case 'admin-create-user':
    //     break

    //   case 'create-csr-agent':
    //     pageTitle = 'Create CSR/BA Agent'
    //     config.usertype_ids = [UsertypeService.nameToId('csr-agent')]

    //     if (
    //       !UserProfileService.isA([
    //         'system-admin',
    //         'internal-admin',
    //         'agency-owner',
    //       ])
    //     ) {
    //       config.comm_level_ids = [210]
    //       config.account_statuses = [1]
    //     }
    //     break

    //   case 'create-division-admin':
    //     pageTitle = 'Create Division Admin'
    //     config.usertype_ids = [UsertypeService.nameToId('division-admin')]
    //     config.upline_ids = [
    //       (await DivisionService.get(UserProfileService.get('u_devision')))
    //         ?.d_owner_id,
    //     ]

    //     if (
    //       !UserProfileService.isA([
    //         'system-admin',
    //         'internal-admin',
    //         'agency-owner',
    //       ])
    //     ) {
    //       config.comm_level_ids = [212]
    //       config.account_statuses = [1]
    //     }
    //     break

    //   case 'create-region-admin':
    //     pageTitle = 'Create Region Admin'
    //     config.usertype_ids = [UsertypeService.nameToId('region-admin')]
    //     config.upline_ids = [await UserProfileService.getRegion()]

    //     if (
    //       !UserProfileService.isA([
    //         'system-admin',
    //         'internal-admin',
    //         'agency-owner',
    //       ])
    //     ) {
    //       config.comm_level_ids = [212]
    //       config.account_statuses = [1]
    //     }
    //     this.setState({ type: 'region' })
    //     break

    //   case 'create-district-admin':
    //     pageTitle = 'Create District Admin'
    //     config.usertype_ids = [UsertypeService.nameToId('district-admin')]
    //     config.upline_ids = [await UserProfileService.getDistrict()]

    //     if (
    //       !UserProfileService.isA([
    //         'system-admin',
    //         'internal-admin',
    //         'agency-owner',
    //       ])
    //     ) {
    //       config.comm_level_ids = [212]
    //       config.account_statuses = [1]
    //     }
    //     this.setState({ type: 'district' })
    //     break

    //   default:
    //     config.usertype_ids = []
    //     config.comm_level_ids = []
    //     config.account_statuses = []
    //     break
    // }

    // if (this.state.pageTitle !== pageTitle) this.setState({ pageTitle })
    Store.init(config)
  }

  componentDidMount() {
    this.init()
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="CreateUserPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <PageHeading label={this.state.pageTitle} />
            </MDBContainer>
            <MDBContainer>
              <div className="content-wrapper">
                <ProfileForm
                  type={this.state.type}
                  method={this.state.method}
                />
              </div>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default CreateUser
