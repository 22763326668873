import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import UserProfileService from './../../shared/services/UserProfile.service'
import UserService from './../../shared/services/User.service'
import RecentApplications from './components/RecentApplications/RecentApplications.component'
import DateService from './../../shared/services/Date.service'
import { CrmStore } from './../../components/crm'
import SalesGuidelines from './components/SalesGuidelines/SalesGuidelines.component'
import ResetPointsButton from './components/ResetPointsButton/ResetPointsButton.component'
import SalesTracker from './components/SalesTracker.component'
import SubmitSalesService from './shared/SubmitSales.service'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
} from 'mdbreact'
import SalesTrackerStore from './store'

import './SalesTracker.page.scss'

class SalesTrackerPage extends React.Component {
  isAdmin = false
  avUserId = null

  state = {
    loading: true,
    showGuidelines: false,
    enableSalesTracker: false,
    resettingAcceptance: false,
    resetPoints: null,
    canShowGuidelines: false,
    avUserId: null,
    hasAccepted: null,
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */

  useCrm = () => !UserProfileService.isA(['affiliate-group', 'associate-group'])

  isCrmValid = () => {
    return this.useCrm() && CrmStore.Contact && CrmStore.isAssigned
  }

  showGuidelines = () => this.setShowGuidelines('guidelines')

  hasAccepted = (hasAccepted) => this.setState({ hasAccepted })

  resetAcceptance = async () => {
    if (this.isAdmin && this.avUserId) {
      this.setState({ resettingAcceptance: true })
      await SubmitSalesService.resetAcceptance(this.avUserId)
      this.setState({ resettingAcceptance: false, hasAccepted: null })
    }
  }

  setShowGuidelines = (open) => {
    if (open === 'guidelines')
      return this.setState({
        loading: false,
        showGuidelines: true,
        enableSalesTracker: false,
        canShowGuidelines: false,
      })

    if (open === 'form')
      return this.setState({
        loading: false,
        showGuidelines: false,
        enableSalesTracker: true,
        canShowGuidelines: true,
      })
  }

  renderAcceptanceNotification = () => {
    if (this.isAdmin && UserProfileService.isAssumed()) {
      if (this.state.hasAccepted)
        return (
          <MDBAlert color="info" className="text-center">
            <span style={{ fontWeight: 500 }}>
              The agent accepted the Sales Guidelines on{' '}
              {DateService.dateOnlyToString(
                this.state.hasAccepted,
                'MM/DD/YYYY'
              )}
              .
            </span>
          </MDBAlert>
        )
      if (!this.state.hasAccepted)
        return (
          <MDBAlert color="danger" className="text-center">
            <span style={{ fontWeight: 500 }}>
              The agent has not accepted the Sales Guidelines.
            </span>
          </MDBAlert>
        )
    }
  }

  renderResetAcceptanceButton = () => {
    if (
      this.isAdmin &&
      this.avUserId &&
      this.state.hasAccepted &&
      UserProfileService.isAssumed()
    )
      return (
        <MDBBtn
          className="btn-block mb-3"
          disabled={this.state.resettingAcceptance}
          style={{ fontWeight: 500 }}
          color="indigo"
          onClick={() => this.resetAcceptance()}
        >
          {this.state.resettingAcceptance
            ? 'Resetting ...'
            : "Reset Agent's Sales Guideline Acceptance"}
        </MDBBtn>
      )
  }

  checkUserPermissions = async () => {
    let userDetails = UserProfileService.getUserDetails(),
      uplineId =
        userDetails &&
        typeof userDetails === 'object' &&
        userDetails.u_upline_id
          ? parseInt(userDetails.u_upline_id, 10)
          : false,
      uplineDetails = uplineId
        ? await UserService.getUserDetails(uplineId)
        : null,
      sellingAgentList = [
        {
          text: [userDetails.u_fname, userDetails.u_lname].join(' ').trim(),
          value: `${userDetails.id}`,
        },
      ],
      sellingAgentId = UserProfileService.getUserId(),
      avSellingAgent = null

    if (
      UserProfileService.isA([
        'csr-agent',
        'division-admin',
        'region-admin',
        'district-admin',
      ])
    )
      sellingAgentList.push({
        text: [uplineDetails.u_fname, uplineDetails.u_lname].join(' ').trim(),
        value: `${uplineDetails.id}`,
      })

    if (
      Array.isArray(sellingAgentList) &&
      sellingAgentList.filter((s) => s).length
    ) {
      if (
        SalesTrackerStore.AOR.sellingAgentId &&
        !isNaN(SalesTrackerStore.AOR.sellingAgentId)
      ) {
        if (
          sellingAgentList.filter(
            (item) =>
              `${item.value}` === `${SalesTrackerStore.AOR.sellingAgentId}`
          ).length > 0
        )
          sellingAgentId = SalesTrackerStore.AOR.sellingAgentId
      }

      sellingAgentId = sellingAgentId
        ? sellingAgentId
        : sellingAgentList[0].value
      if (sellingAgentId)
        avSellingAgent = await SalesTrackerStore.getAvSellingAgent(
          sellingAgentId
        )
    }

    SalesTrackerStore.AOR = { sellingAgentList, sellingAgentId, avSellingAgent }
  }

  initCrm = async () => {
    if (UserProfileService.isA(['affiliate-group', 'associate-group']))
      return false

    return await CrmStore.init()
  }

  initPolicy = async (avId) => {
    SalesTrackerStore.setClientType('individual')
    SalesTrackerStore.fetchById(avId).finally(() => this.checkUserPermissions())
  }

  initSalesTracker = async (avId) => {}

  updateSalesTracker = async (avId) => {}

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    this.isAdmin = UserProfileService.isA(
      ['system-admin', 'agency-owner', 'internal-admin'],
      true
    )
    this.avUserId = UserProfileService.getUserId()

    const {
      match: { params },
    } = this.props

    this.initPolicy(params && params?.id)

    Promise.all([
      this.initCrm(),
      SalesTrackerStore.fetchCarriers(),
      SalesTrackerStore.fetchPointOverrides(),
      SalesTrackerStore.fetchCoverages(),
      SalesTrackerStore.fetchLeadSources(),
    ])
  }

  componentDidUpdate() {
    // const {
    //   match: { params },
    // } = this.props

    // if (!params?.id) this.initPolicy(null)
    Promise.all([SalesTrackerStore.fetchPointOverrides()])
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="SalesTrackerPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Submit Sales</h2>
              <hr />

              {/* (Collapsible) Sales Tracker Guildelines. */}
              <MDBCollapse isOpen={this.state.showGuidelines}>
                <MDBRow>
                  <MDBCol size="12">
                    <SalesGuidelines
                      open={this.setShowGuidelines}
                      hasAccepted={this.hasAccepted}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>

              {/* (Collapsible) Sales Tracker Forms. */}
              <MDBCollapse isOpen={this.state.enableSalesTracker}>
                <MDBRow>
                  <MDBCol size="12" sm="7" lg="8">
                    <div className="content-wrapper">
                      <SalesTracker />
                    </div>
                  </MDBCol>
                  <MDBCol size="12" sm="5" lg="4">
                    {this.state.canShowGuidelines ? (
                      <MDBBtn
                        className="btn-block mb-3"
                        style={{ fontWeight: 500 }}
                        color="indigo"
                        onClick={() => this.showGuidelines()}
                      >
                        Show Sales Guidelines
                      </MDBBtn>
                    ) : (
                      <></>
                    )}
                    <ResetPointsButton />
                    {this.renderAcceptanceNotification()}
                    {this.renderResetAcceptanceButton()}
                    <RecentApplications
                      goTo={(avId) => {
                        this.initPolicy(avId)
                        this.props.history.push(`/submit-sales/${avId}/edit`)
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default SalesTrackerPage
