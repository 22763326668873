import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AuthStore from './../Auth.store'
import { UIInputCheckbox } from '../../forms/form-fields'
import { MDBCollapse, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

export default observer(() => {
  const [viewTerms, setViewTerms] = useState(false),
    { shouldPromptForTerms, Terms } = AuthStore,
    SigAgentTerm = (Array.isArray(Terms) ? Terms : [])
      .filter((T) => T.get('term_slug') === 'sig-agent-terms')
      .shift(),
    sigAgentTermLink = SigAgentTerm && SigAgentTerm?.get('term_link')

  return (
    <div id="SignatureAgentTermsAcceptanceComponent">
      <MDBCollapse isOpen={shouldPromptForTerms()}>
        <div className="sig-term-accept-check form-group offset-top-22">
          <div className="check-input">
            <UIInputCheckbox
              name={`accept_sig_agent_terms`}
              id={`accept_sig_agent_terms`}
              onChange={(evt) =>
                (AuthStore.sig_term_accept = !AuthStore.sig_term_accept)
              }
              checked={AuthStore.sig_term_accept}
            />
          </div>
          <div className="check-label">
            <div
              className="clabel"
              onClick={(evt) =>
                (AuthStore.sig_term_accept = !AuthStore.sig_term_accept)
              }
            >
              I have read and understand the updated Signature Agent Terms and
              Conditions.
            </div>
            <div className="chandler" onClick={(evt) => setViewTerms(true)}>
              Click to view
            </div>
          </div>
        </div>
      </MDBCollapse>
      <MDBModal isOpen={viewTerms} toggle={() => setViewTerms(false)}>
        <MDBModalHeader toggle={() => setViewTerms(false)}>
          USA Benefit Group Terms
        </MDBModalHeader>
        <MDBModalBody>
          <MDBCollapse isOpen={AuthStore.isFetchingTerms}>
            <div className="loading-spinner">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <i className="fa fa-spin fa-spinner" />
                    </td>
                    <td>Loading Terms</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </MDBCollapse>
          <MDBCollapse isOpen={sigAgentTermLink && !AuthStore.isFetchingTerms}>
            <iframe
              src={sigAgentTermLink}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={`USABG Signature Agent Terms`}
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          </MDBCollapse>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
})
