import React, { useState, useEffect } from 'react'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBInput,
  MDBToast,
  MDBToastContainer,
} from 'mdbreact'
import { toast } from 'react-toastify'
import KpiMetricService from './../../../../shared/services/KpiMetric.service'

const BulkEditDescriptionsModal = ({ isOpen, toggle, kpis, onSave }) => {
  const [descriptions, setDescriptions] = useState({})
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showErrorToast, setShowErrorToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  // Initialize descriptions state when modal opens or kpis change
  useEffect(() => {
    if (isOpen) {
      const initialDescriptions = {}
      kpis.forEach((kpi) => {
        initialDescriptions[kpi.id] = kpi.description || ''
      })
      setDescriptions(initialDescriptions)
    }
  }, [isOpen, kpis])

  const handleDescriptionChange = (id, value) => {
    setDescriptions((prev) => ({
      ...prev,
      [id]: value,
    }))
  }

  const handleSave = async () => {
    try {
      await Promise.all(
        Object.entries(descriptions).map(([id, description]) => {
          return KpiMetricService.update(id, { description })
        })
      )
      onSave(descriptions)
      toast.success('Descriptions updated successfully! Please refresh page.')
      toggle()
    } catch (error) {
      console.error('Error updating descriptions:', error)
      toast.success('Failed to update descriptions. Please try again.')
      setShowErrorToast(true)
    }
  }

  return (
    <>
      <MDBModal isOpen={isOpen} toggle={toggle} size="xl">
        <MDBModalHeader toggle={toggle}>Edit All Descriptions</MDBModalHeader>
        <MDBModalBody>
          <MDBTable>
            <MDBTableHead>
              <tr style={{ padding: 0 }}>
                <th style={{ width: '20%', padding: '1px 1px' }}>
                  Metric Name
                </th>
                <th style={{ padding: '1px 1px' }}>Description</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {kpis.map((kpi) => (
                <tr key={kpi.id} style={{ padding: 0 }}>
                  <td
                    style={{
                      width: '20%',
                      padding: '1px 1px',
                      verticalAlign: 'top',
                    }}
                  >
                    {kpi.label}
                  </td>
                  <td style={{ padding: '1px 1px' }}>
                    <MDBInput
                      type="textarea"
                      value={descriptions[kpi.id] || ''}
                      onChange={(e) =>
                        handleDescriptionChange(kpi.id, e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>
            Close
          </MDBBtn>
          <MDBBtn color="primary" onClick={handleSave}>
            Save Changes
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* Success Toast */}
      <MDBToastContainer position="top-right">
        <MDBToast
          show={showSuccessToast}
          autohide
          delay={3000}
          onClose={() => setShowSuccessToast(false)}
          headerClassName="bg-success text-white"
          bodyClassName="bg-white"
        >
          <div className="d-flex flex-grow-1 align-items-center">
            <i className="fas fa-check-circle fa-lg text-success mr-3"></i>
            <div className="mr-auto">{toastMessage}</div>
          </div>
        </MDBToast>
      </MDBToastContainer>

      {/* Error Toast */}
      <MDBToastContainer position="top-right">
        <MDBToast
          show={showErrorToast}
          autohide
          delay={3000}
          onClose={() => setShowErrorToast(false)}
          headerClassName="bg-danger text-white"
          bodyClassName="bg-white"
        >
          <div className="d-flex flex-grow-1 align-items-center">
            <i className="fas fa-exclamation-circle fa-lg text-danger mr-3"></i>
            <div className="mr-auto">{toastMessage}</div>
          </div>
        </MDBToast>
      </MDBToastContainer>
    </>
  )
}

export default BulkEditDescriptionsModal
