import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBCollapse, MDBCol, MDBIcon } from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import { ContactManager, CrmStore } from './../../../components/crm' // LocationSelector,
import ClientForm from './ClientForm/ClientForm.component'
import AvPoliciesList from './AvPoliciesList/AvPoliciesList.component'
import PolicySubmitter from './PolicySubmitter/PolicySubmitter.component'

const doesUserHaveLocations = async (source) => {
  const User = await UserProfileService.instance(),
    metaValue = JSON.parse(
      ((await User.meta(true).key('10x---location-id')) || [])
        .shift()
        ?.get('meta_value') || '{}'
    )
  return !!metaValue?.[source] && metaValue?.[source]
}

export default observer(() => {
  const [locationId, setLocationId] = useState(null),
    [skipIntegration, setSkipIntegration] = useState(false),
    { Contact } = CrmStore

  if (locationId === null) {
    doesUserHaveLocations('sales').then((lId) => {
      if (lId === false) setSkipIntegration(true)
      setLocationId(lId)
    })
  }

  const isCrmUsePermitted = () =>
      !UserProfileService.isA(['affiliate-group', 'associate-group']),
    isCrmValid = !!(
      isCrmUsePermitted() &&
      CrmStore.Contact &&
      CrmStore.isAssigned
    )

  const renderContactManager = () => {
    if (isCrmUsePermitted() && !skipIntegration) {
      return (
        <>
          <ContactManager locationId={locationId} />
          {!isCrmValid ? (
            <MDBCol size="12">
              <button
                className="skip-crm-btn"
                onClick={() => setSkipIntegration(true)}
              >
                Skip integration for now&nbsp;
                <MDBIcon fas icon="arrow-right" />
              </button>
            </MDBCol>
          ) : (
            <></>
          )}
          <br />
        </>
      )
    }
  }

  return (
    <div id="SalesTrackerComponent">
      {renderContactManager()}
      <MDBCollapse isOpen={isCrmValid || skipIntegration}>
        <ClientForm Contact={Contact} />
        <AvPoliciesList />
        <br />
        <PolicySubmitter />
      </MDBCollapse>
    </div>
  )
})
