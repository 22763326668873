import React from 'react'
import { observer } from 'mobx-react-lite'
import CrmStore from './../crm.store'
import { MDBIcon } from 'mdbreact'

import './NewContactBtn.component.scss'

export default observer(({ locationId, disabled }) => {
  const { Location, createNewContact } = CrmStore

  if (!locationId && (!Location || !Location?.id())) return <></>

  return (
    <button
      id="NewContactBtnComponent"
      onClick={() => createNewContact(locationId)}
      disabled={disabled}
    >
      Create New Contact&nbsp;
      <MDBIcon icon="plus-circle" />
    </button>
  )
})
