import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBBtn, MDBIcon, MDBDataTable } from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import CarrierMgmtStore from './../../CarrierMgmt.store'

import './CarrierTable.scss'

const TABLE_COLUMNS = [
  {
    label: 'Carriers',
    field: 'name',
    sort: 'asc',
  },
  {
    label: 'Description',
    field: 'description',
  },
  {
    label: '',
    field: 'edit',
  },
]

const CarrierTable = ({ goTo, filters }) => {
  filters = filters && typeof filters === 'object' ? filters : {}
  const showSpec = !!filters?.spec,
    limitPriority = showSpec && !!filters?.priority,
    spec = showSpec && filters.spec,
    priority = limitPriority && filters?.priority,
    columns = (
      showSpec
        ? [{ label: '', field: 'priority' }].concat(TABLE_COLUMNS)
        : TABLE_COLUMNS
    ).map((col) =>
      col.field === 'name'
        ? {
            ...col,
            label:
              CarrierMgmtStore.getView() === 'active'
                ? 'Active Carriers'
                : 'Inactive Carriers',
          }
        : col
    )

  const renderEditBtn = (carrierId) => (
    <MDBBtn onClick={() => goTo(carrierId)}>
      <MDBIcon icon={'edit'} />
    </MDBBtn>
  )

  const buildRow = (Carrier) => {
    let carObj = {
      name: Carrier.get('c_name'),
      description: Carrier.get('c_description'),
      edit: renderEditBtn(Carrier.id()),
    }

    if (showSpec) {
      if (!Carrier.isSpeciality(spec)) return false

      if (limitPriority) {
        if (['core', 'preferred', 'additional'].includes(priority)) {
          if (!Carrier.isPriority(spec, 92, priority)) return false
        } else {
          return false
        }
      }

      carObj.priority = Carrier.renderPriorityIndicator(92)
    }

    return carObj
  }

  const renderTable = (carriers) => (
    <MDBDataTable
      sortRows={['name']}
      order={['name', 'asc']}
      bordered
      small
      data={{ columns, rows: carriers.map(buildRow).filter((c) => c) }}
      displayEntries={false}
      entriesOptions={[250]}
      entries={250}
      className={'text-capitalize'}
    />
  )

  const renderActiveTable = () =>
    renderTable(
      CarrierMgmtStore.getCarriers(true).filter(
        (Carrier) => !!Carrier.get('c_active')
      )
    )

  const renderInctiveTable = () =>
    renderTable(
      CarrierMgmtStore.getCarriers(true).filter(
        (Carrier) => !Carrier.get('c_active')
      )
    )

  return (
    <div
      className={'CarrierTableComponent ' + (showSpec ? 'show-priority' : '')}
    >
      <div className="view-selector">
        <ul>
          <li
            className={CarrierMgmtStore.getView() === 'active' ? 'active' : ''}
            onClick={() => CarrierMgmtStore.setView('active')}
          >
            Active Carriers
          </li>
          <li
            className={
              CarrierMgmtStore.getView() === 'inactive' ? 'active' : ''
            }
            onClick={() => CarrierMgmtStore.setView('inactive')}
          >
            Inactive Carriers
          </li>
          <li
            className={
              'loader-wheel ' +
              (CarrierMgmtStore.getIsLoading('fetch.carriers')
                ? 'is-loading'
                : '')
            }
          >
            <span>
              <i className="fa fa-spin fa-spinner" />
            </span>
            &nbsp;Updating Carriers ...
          </li>
        </ul>
      </div>
      {CarrierMgmtStore.getView() === 'active'
        ? renderActiveTable()
        : renderInctiveTable()}
    </div>
  )
}

export default observer(CarrierTable)
