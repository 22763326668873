import React, { useState } from 'react'
import { makeAutoObservable, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MDBCollapse, MDBBtn, MDBIcon } from 'mdbreact'
import CheckoutStore from './../Checkout.store'
import ACMStore from './../AgentContractManager.store'
import { toast } from 'react-toastify'
import ConfirmDeleteModalComponent from './../../adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import UserProfileService from '../../../shared/services/UserProfile.service'

import './ContractingPacket.scss'
import './CartItemComponent.scss'

const CartItemComponent = ({ item, isOpen, isDisabled }) => {
  return (
    <>
      <div
        className={
          'CartItemComponent ' +
          (isOpen ? 'expand ' : '') +
          (isDisabled ? 'disable ' : '')
        }
      >
        <div className="carrier-name">
          <div>
            {ACMStore.CarriersById[item.get('carrier_id')]?.get('c_name')}
          </div>
          <div className="carrier-descrip">
            {ACMStore.CarriersById[item.get('carrier_id')]?.get(
              'c_description'
            )}
          </div>
        </div>
        <div className="carrier-descrip">
          <div>
            {ACMStore.CarriersById[item.get('carrier_id')]?.get(
              'c_description'
            )}
          </div>
        </div>
        <div className="carrier-comm-level">
          {(ACMStore.Packet?.comm_codes &&
            ACMStore.Packet.comm_codes[item.get('carrier_id')]) || (
            <small>Agent</small>
          )}
        </div>
        {ACMStore.Packet?.instructions?.carrier[item.get('carrier_id')] ? (
          <div className="carrier-instruct">
            {ACMStore.Packet?.instructions?.carrier[item.get('carrier_id')]}
          </div>
        ) : (
          <div className="carrier-instruct is-empty">
            No additional instructions supplied for this carrier contract.
          </div>
        )}
      </div>
    </>
  )
}

const CartItem = observer(CartItemComponent)

class ContractingPacketStore {
  confirmDelete = false
  confirmPacketId = null

  constructor() {
    makeAutoObservable(this)
  }
}

const Store = new ContractingPacketStore()

let intTimerHandle
const ContractingPacket = ({
  group,
  packetId,
  Packet,
  onCheckingOutToggle,
}) => {
  const [timer, setTimer] = useState(0),
    // [activeConfirmDeleteModal, setActiveConfirmDeleteModal] = useState(false),
    [isActivating, setIsActivating] = useState(false),
    [isBuilding, setIsBuilding] = useState(false),
    isOpen = ACMStore?.Packet?.id === packetId,
    isDisabled = CheckoutStore.groupId !== packetId && CheckoutStore.groupId,
    activate = async () => {
      if (isBuilding) return

      // groupId && CheckoutStore.checkoutCart(groupId)
      setIsActivating(true)
      intTimerHandle = setInterval(
        () => setTimer((prevTimer) => prevTimer + 1),
        1000
      )

      if (!isBuilding) setIsBuilding(true)

      try {
        await ACMStore.viewPacket(packetId)
      } catch (ex) {
        toast.error(`${ex}`, { position: toast.POSITION.TOP_RIGHT })
      }

      setIsBuilding(false)
    },
    deactivate = () => {
      if (isBuilding) return
      ACMStore.packetId = null
      ACMStore.Packet = null
      setIsActivating(false)
      if (intTimerHandle) {
        clearInterval(intTimerHandle)
        intTimerHandle = null
      }
    },
    complete = (link) => {
      let isCompleted = link === true
      link =
        link && typeof link === 'string' && `${link}`.trim().length > 0
          ? `${link}`.trim()
          : null

      // indicate some kind of update?
      ACMStore.Packet.has_started = true

      // Check if this is the final cart item.
      // If so, we can close the cart.
      if (isCompleted) {
        ACMStore.completePacket()

        deactivate()
      } else {
        ACMStore.openPacket(link)
      }
    },
    cancel = () => {
      Store.confirmDelete = false
      ACMStore.deletePacket(Store.confirmPacketId).then(async () => {
        if (ACMStore.CartItems.length === 0) {
          onCheckingOutToggle(false)
          ACMStore.removeCheckout()
        }
      })
    },
    renderContractTypeInstructions = () => {
      const cTypes = ACMStore.Packet?.instructions?.contract_type
        ? toJS(ACMStore.Packet.instructions.contract_type)
        : {}
      return (
        <div className="group-ctype-instruct">
          <ul>
            {Object.keys(cTypes).map((cType, idx) => {
              if (`${cType}`.toLowerCase() === 'pdf')
                return (
                  <li key={`contract-type-instr-${idx}-${cType}`}>
                    <strong style={{ textDecoration: 'underline' }}>
                      Online PDF Contracting
                    </strong>
                    <br />
                    {cTypes[cType] ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: cTypes[cType] }}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </li>
                )
              if (`${cType}`.toLowerCase() === 'link')
                return (
                  <li key={`contract-type-instr-${idx}-${cType}`}>
                    <strong style={{ textDecoration: 'underline' }}>
                      Online Link Contracting
                    </strong>
                    <br />
                    {cTypes[cType] ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: cTypes[cType] }}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </li>
                )
              if (`${cType}`.toLowerCase() === 'acr')
                return (
                  <li key={`contract-type-instr-${idx}-${cType}`}>
                    <strong style={{ textDecoration: 'underline' }}>
                      Online Agent Contract Request
                    </strong>
                    <br />
                    {cTypes[cType] ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: cTypes[cType] }}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </li>
                )
              return ''
            })}
          </ul>
        </div>
      )
    },
    renderFMOInstructions = () => {
      const fmo = ACMStore.Packet?.instructions?.fmo
          ? toJS(ACMStore.Packet.instructions.fmo)
          : {},
        fmos = ACMStore.Packet?.fmos ? toJS(ACMStore.Packet.fmos) : {}

      if (fmo && Object.values(fmo).filter((n) => !!n).length > 0)
        return (
          <div className="group-fmo-instruct">
            <ul>
              {Object.keys(fmo).map((fmoId, idx) => (
                <li key={`fmo-instr-${idx}-${fmoId}`}>
                  <strong style={{ textDecoration: 'underline' }}>
                    {fmos[fmoId]} Instructions:
                  </strong>
                  <br />
                  {fmo[fmoId]}
                </li>
              ))}
            </ul>
          </div>
        )
    },
    renderActionButtons = () => {
      const links = toJS(ACMStore.Packet?.links)

      if (!links || !links.length) {
        return (
          <>
            <div className="row btn-row">
              <div>
                <MDBBtn disabled={true}>Start Contract Packet</MDBBtn>
              </div>
              <div>
                <MDBBtn color="info" onClick={() => complete(true)}>
                  Click here once you've <strong>completed</strong> this
                  contract.
                </MDBBtn>
              </div>
            </div>
          </>
        )
      }

      if (links.length === 1) {
        return (
          <>
            <div
              className={
                'row btn-row ' +
                (!ACMStore.Packet.has_completed && ACMStore.Packet.has_started
                  ? 'can-mark-completed '
                  : '')
              }
            >
              <div>
                <MDBBtn
                  disabled={ACMStore.Packet.has_completed}
                  onClick={() => complete(links[0])}
                >
                  {ACMStore.Packet.has_completed
                    ? 'Contract Packet Complete'
                    : ACMStore.Packet.has_started
                    ? 'Continue Contract Packet'
                    : 'Start Contract Packet'}
                </MDBBtn>
              </div>
              <div>
                <MDBBtn color="info" onClick={() => complete(true)}>
                  Click here once you've <strong>completed</strong> this
                  contract.
                </MDBBtn>
              </div>
            </div>
          </>
        )
      }

      return (
        <div
          className={
            'btn-row row multiple ' +
            (!ACMStore.Packet.has_completed && ACMStore.Packet.has_started
              ? 'can-mark-completed '
              : '')
          }
        >
          {links.map((link, idx) => (
            <MDBBtn
              color="info"
              key={`c-actbtn-${idx}`}
              onClick={() => complete(link)}
              disabled={ACMStore.Packet.has_completed}
            >
              {ACMStore.Packet.has_completed
                ? 'Contract Packet Complete'
                : ACMStore.Packet.has_started
                ? 'Continue Contract Packet'
                : 'Start Contract Packet'}{' '}
              {idx + 1}
            </MDBBtn>
          ))}
          <MDBBtn
            color="info"
            onClick={() => complete(true)}
            disabled={ACMStore.Packet.has_completed}
          >
            Click here once you've <strong>completed</strong> this contract.
          </MDBBtn>
        </div>
      )
    }

  if (isOpen && intTimerHandle) {
    clearInterval(intTimerHandle)
    intTimerHandle = null
  }

  return (
    <div
      className={
        'ContractingPacketComponent ' +
        (isOpen ? 'is-open ' : '') +
        (isDisabled ? 'is-disabled ' : '')
      }
    >
      <div className="cart-group-wrapper">
        <div className="group-indicator">
          <div className="ind-bg">contracting packet&nbsp;{packetId}</div>
          <MDBBtn onClick={deactivate}>
            <MDBIcon icon="times" />
          </MDBBtn>
        </div>
        <MDBCollapse isOpen={!!isOpen}>
          {renderContractTypeInstructions()}
          {renderFMOInstructions()}
          <div className="group-contracts-heading">
            <div className="carrier-descrip">Carrier:</div>
            <div className="carrier-comm-level">Commission Code:</div>
            <div className="carrier-instruct">
              Carrier-Specific Instructions:
            </div>
          </div>
        </MDBCollapse>
        <ul className="group-contracts">
          {ACMStore.PacketItems.filter(
            (CartItem) => CartItem.get('item_group') === packetId
          ).map((item, idx) => (
            <li key={`cart-item-${item.get('item_group')}-${idx}`}>
              <CartItem item={item} isOpen={isOpen} isDisabled={isDisabled} />
            </li>
          ))}
        </ul>
        <MDBCollapse isOpen={!!isOpen}>
          <div className="group-footer">
            <div className="contract-actions">{renderActionButtons()}</div>
          </div>
        </MDBCollapse>
      </div>
      <div className="cart-group-button">
        <MDBBtn
          className={
            'view-button ' +
            (parseInt(UserProfileService.get('has_onboarded')) !== 1
              ? 'view-button-only'
              : '')
          }
          disabled={
            !packetId ||
            isBuilding ||
            !!(ACMStore?.Packet?.id !== packetId && ACMStore?.Packet?.id)
          }
          onClick={activate}
        >
          {isBuilding && isActivating ? (
            <>
              Preparing Packet&nbsp;
              <i className="fa fa-spin fa-spinner"></i>
              <br />
              <div className="prep-time">
                <small>
                  Prep Time: Approx{' '}
                  {ACMStore.packetContractTypes[packetId] === 'PDF' ||
                  ACMStore.packetContractTypes[packetId] === 'ACR'
                    ? 20
                    : 6}{' '}
                  seconds.
                </small>
                <br />
                Time:&nbsp;{timer}
              </div>
            </>
          ) : (
            <>
              View Contract Packet {`${packetId}`.toUpperCase()}
              <br />
              {}
            </>
          )}
        </MDBBtn>
        {parseInt(UserProfileService.get('has_onboarded')) === 1 && (
          <MDBBtn
            className="cancel-button"
            disabled={
              !packetId ||
              isBuilding ||
              !!(ACMStore?.Packet?.id !== packetId && ACMStore?.Packet?.id)
            }
            onClick={() => {
              Store.confirmPacketId = packetId
              Store.confirmDelete = true
            }}
          >
            {
              <small>
                Cancel&nbsp;
                <MDBIcon icon="trash" />
              </small>
            }
          </MDBBtn>
        )}
      </div>
      {Store.confirmDelete && (
        <ConfirmDeleteModalComponent
          shouldEnable={true}
          confirm={`Packet ${Store.confirmPacketId}`}
          onConfirm={() => cancel()}
          onCancel={() => (Store.confirmDelete = false)}
        />
      )}
    </div>
  )
}

export default observer(ContractingPacket)
