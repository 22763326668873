import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import TextEditor from '../../components/shared/TextEditor/TextEditor.component'
import { UIDropdown, UIInput } from '../../components/forms/form-fields'
import UserService from '../../shared/services/User.service'
import UsertypeService from '../../shared/services/Usertype.service'
import './EditEmailNotification.scss'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

class EditEmailNotification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      emailtype_name: '',
      email_name: '',
      email_from: '',
      email_cc: '',
      email_bcc: '',
      email_subject: '',
      email_header: '',
      email_body: '',
      email_footer: '',
      merge_fields: [],
      test_email_params: null,
      users: [],
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    this.fetchNotificationEmailData(params.id)

    this.getUsers()
    // this.setState({editorState});
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  fetchNotificationEmailData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getNotificationEmailById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            emailtype_name: content.emailtype_name || '',
            email_name: content.email_name || '',
            email_from: content.email_from || '',
            email_cc: content.email_cc || '',
            email_bcc: content.email_bcc || '',
            email_subject: content.email_subject || '',
            email_header: content.email_header || '',
            email_body: content.email_body || '',
            email_footer: content.email_footer || '',
          })

          // Regular expression to match values within curly braces
          const regex = /{([^}]*)}/g

          // Array to store extracted values
          let placeholders = []

          // Match and push values into the array, get fieldObject
          let match,
            fieldObject = {}
          while ((match = regex.exec(content.email_body)) !== null) {
            placeholders.push(match[1])
          }
          placeholders.forEach((key) => {
            if (
              key.includes('lead_vendor') ||
              key.includes('transaction') ||
              key.includes('week') ||
              key.includes('referral')
            )
              fieldObject[key] = ''
          })
          fieldObject.agent = ''

          switch (parseInt(id)) {
            case 277:
            case 275:
            case 274:
              fieldObject.transaction_id = ''
              placeholders.push('transaction_id')
              break
            case 262:
              fieldObject.week_report_name = ''
              placeholders.push('week_report_name')
            case 256:
              fieldObject.referral_partner_statement_name = ''
              placeholders.push('referral_partner_statement_name')
            default:
              break
          }

          this.setState({
            merge_fields: placeholders.filter(
              (item) =>
                item.includes('lead_vendor') ||
                item.includes('transaction') ||
                item.includes('week') ||
                item.includes('referral')
            ),
            test_email_params: fieldObject,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  getUsers = async () => {
    const users = await UserService.search({
      pagination: false,
      order_by: { u_fname: 'ASC' },
    })

    this.setState({ users: users.models })
  }

  getUserOptions = (selectedId) => {
    return this.state.users
      ?.filter((user) => ![111, 133, 223, 224].includes(user.usertype_id))
      .map((user) => ({
        text: (
          <>
            {user.u_fname + ' ' + user.u_lname + ' '}
            <br></br>
            <small>
              {user.u_email} | {UsertypeService.idToName(user.usertype_id)}
            </small>
          </>
        ),
        value: `${user.id}`,
        checked: !!(selectedId && `${selectedId}` === `${user.id}`),
      }))
  }

  updateNotificationEmail = async () => {
    const id = this.state.urlParams.id
    const {
      loading,
      emailtype_name,
      email_name,
      email_from,
      email_cc,
      email_bcc,
      email_subject,
      email_header,
      email_body,
      email_footer,
    } = this.state
    if (loading) {
      return
    }
    const data = {
      emailtype_name: emailtype_name,
      email_name: email_name,
      email_from: email_from,
      email_cc: email_cc,
      email_bcc: email_bcc,
      email_subject: email_subject,
      email_header: email_header,
      email_body: email_body,
      email_footer: email_footer,
      table_name: 'emails',
      id: id,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateNotificationEmail(form_data)
      .then((result) => {
        this.setState({ loading: false })
        // this.props.history.goBack();
        this.props.history.push('/admin-emails')
        toast.success('Email updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  sendTestEmail = async (event) => {
    event.preventDefault()

    const id = this.state.urlParams.id
    const { loading, test_email_params } = this.state
    if (loading) {
      return
    }
    const data = {
      id: id,
      ...test_email_params,
    }

    this.setState({ loading: true })
    AdminApi.sendTestEmail(data)
      .then((result) => {
        this.setState({ loading: false })

        toast.success('Test email sent successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { loading, merge_fields } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main id="EditEmailNotificationPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-emails">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Edit Email</MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>Edit Email</h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="8">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="email_from">
                          Send from
                        </label>
                        <input
                          type="text"
                          readOnly={true}
                          className="form-control"
                          name="email_from"
                          autoComplete="off"
                          id="email_from"
                          value={this.state.email_from}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="email_name">
                          Send to
                        </label>
                        <input
                          type="text"
                          readOnly={true}
                          className="form-control"
                          autoComplete="off"
                          name="email_name"
                          id="email_name"
                          value={this.state.email_name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="email_cc">
                          Send CC to
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email_cc"
                          autoComplete="off"
                          id="email_cc"
                          value={this.state.email_cc}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="email_bcc">
                          Send BCC to
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="email_bcc"
                          id="email_bcc"
                          value={this.state.email_bcc}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="email_subject">
                          Email Subject
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email_subject"
                          autoComplete="off"
                          id="email_subject"
                          value={this.state.email_subject}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="">
                          Email Header
                        </label>
                        <TextEditor
                          id="editor1"
                          value={this.state.email_header}
                          onChange={(html) =>
                            this.setState({ email_header: html })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="">
                          Email Body
                        </label>
                        <TextEditor
                          id="editor2"
                          value={this.state.email_body}
                          onChange={(html) =>
                            this.setState({ email_body: html })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="">
                          Email Footer
                        </label>
                        <TextEditor
                          id="editor3"
                          value={this.state.email_footer}
                          onChange={(html) =>
                            this.setState({ email_footer: html })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={this.updateNotificationEmail}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
                <MDBCol size="4">
                  <table className="merge-field-legend w-100">
                    <thead>
                      <tr>
                        <th>Merge Field</th>
                        <th>Example Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{'{agent_id}'}</td>
                        <td>1234</td>
                      </tr>
                      <tr>
                        <td>{'{agent_firstname}'}</td>
                        <td>Jessica</td>
                      </tr>
                      <tr>
                        <td>{'{agent_lastname}'}</td>
                        <td>Banville</td>
                      </tr>
                      <tr>
                        <td>{'{agent_name}'}</td>
                        <td>Jessica Banville</td>
                      </tr>
                      <tr>
                        <td>{'{agent_login}'}</td>
                        <td>jbanville@usabg.com</td>
                      </tr>
                      <tr>
                        <td>{'{agent_username}'}</td>
                        <td>jbanville</td>
                      </tr>
                      <tr>
                        <td>{'{agent_password}'}</td>
                        <td>secret-123</td>
                      </tr>
                      <tr>
                        <td>{'{agent_email}'}</td>
                        <td>jbanville@usabg.com</td>
                      </tr>
                      <tr>
                        <td>{'{agent_phone}'}</td>
                        <td>8135551212</td>
                      </tr>
                      <tr>
                        <td>{'{agent_address1}'}</td>
                        <td>5141 Mariner Blvd</td>
                      </tr>
                      <tr>
                        <td>{'{agent_city}'}</td>
                        <td>Spring Hill</td>
                      </tr>
                      <tr>
                        <td>{'{agent_state}'}</td>
                        <td>Florida</td>
                      </tr>
                      <tr>
                        <td>{'{agent_zip}'}</td>
                        <td>34609</td>
                      </tr>
                      <tr>
                        <td>{'{agent_usertype}'}</td>
                        <td>Divisional Leader</td>
                      </tr>
                      <tr>
                        <td>{'{agent_profile_link}'}</td>
                        <td>https://usabg.com/1234/account-profile</td>
                      </tr>
                      <tr>
                        <td>{'{upline_firstname}'}</td>
                        <td>Rick</td>
                      </tr>
                      <tr>
                        <td>{'{upline_lastname}'}</td>
                        <td>Banville</td>
                      </tr>
                      <tr>
                        <td>{'{upline_name}'}</td>
                        <td>Rick Banville</td>
                      </tr>
                      <tr>
                        <td>{'{upline_email}'}</td>
                        <td>rbanville@usabg.com</td>
                      </tr>
                      <tr>
                        <td>{'{upline_phone}'}</td>
                        <td>8139995555</td>
                      </tr>
                      <tr>
                        <td>{'{division_name}'}</td>
                        <td>Team Banville</td>
                      </tr>
                      <tr>
                        <td>{'{enrolled_by}'}</td>
                        <td>Enrolled By</td>
                      </tr>

                      <tr>
                        <td>{'{email_content}'}</td>
                        <td>(Custom content for body of email)</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_name}'}</td>
                        <td>Lead Vendor Name</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_mgr_name}'}</td>
                        <td>Lead Vendor Contact Name</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_mgr_email}'}</td>
                        <td>Lead Vendor Contact Email</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_package}'}</td>
                        <td>Lead Vendor Purchase Package</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_quantity}'}</td>
                        <td>Lead Vendor Purchase Quantity</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_price}'}</td>
                        <td>Lead Vendor Purchase Price</td>
                      </tr>
                      <tr>
                        <td>{'{lead_vendor_link}'}</td>
                        <td>Lead Vendor Link</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="mt-3">
                    <form
                      className="content-wrapper"
                      onSubmit={this.sendTestEmail}
                    >
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <UIDropdown
                            label="Agent"
                            name="agent"
                            options={this.getUserOptions(
                              this.state.test_email_params?.agent
                            )}
                            value={this.state.test_email_params?.agent || ''}
                            onChange={(event) => {
                              this.setState({
                                test_email_params: {
                                  ...this.state.test_email_params,
                                  ...{ agent: event.target.value },
                                },
                              })
                            }}
                            required={true}
                            rules={{ required: true }}
                            search
                          />
                        </div>
                        {merge_fields?.map((field, index) => {
                          return (
                            <div className="form-group col-md-12" key={index}>
                              <UIInput
                                label={capitalize(field.replace(/_/g, ' '))}
                                name={field}
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    test_email_params: {
                                      ...this.state.test_email_params,
                                      ...{ [field]: event.target.value },
                                    },
                                  })
                                }}
                                value={this.state.test_email_params[field]}
                                rules={{ required: true }}
                              />
                            </div>
                          )
                        })}
                      </div>

                      <div className="form-row text-center mt-4">
                        <MDBBtn
                          color="unique"
                          type="submit"
                          className="btn-block"
                          disabled={!this.state.test_email_params?.agent}
                        >
                          {loading ? 'Sending...' : 'Send Test Email'}
                        </MDBBtn>
                      </div>
                    </form>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default EditEmailNotification
