import React, { useState } from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import AvService from './../../../../shared/services/Av.service'
import { observer } from 'mobx-react-lite'
import SalesTrackerStore from './../../store'
import { MDBBtn } from 'mdbreact'

import './ResetPointsButton.component.scss'

const ResetPointsButton = () => {
  const [isResetting, setIsResetting] = useState(false),
    [wasReset, setWasReset] = useState(false),
    isAdmin = UserProfileService.isA(
      ['system-admin', 'agency-owner', 'internal-admin'],
      true
    ),
    canReset = isAdmin && SalesTrackerStore.Policy?.isNew() === false

  const resetPoints = () => {
    if (canReset) {
      setIsResetting(true)
      const avGroupId = SalesTrackerStore.Policy.get('av_group_id')
      AvService.resetPoints(avGroupId).finally(() => {
        setIsResetting(false)
        setWasReset(true)
        setTimeout(() => {
          setWasReset(false)
        }, 4000)
      })
    }
  }

  if (!isAdmin || !canReset) return <></>

  return (
    <MDBBtn
      className="btn-block mb-3"
      disabled={isResetting}
      style={{ fontWeight: 500 }}
      color="indigo"
      onClick={() => resetPoints()}
    >
      {isResetting
        ? wasReset
          ? `Points has been reset.`
          : 'Resetting ...'
        : 'Reset Sales Points'}
    </MDBBtn>
  )
}

export default observer(ResetPointsButton)
