import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBIcon, MDBBtn, MDBCollapse, MDBAlert } from 'mdbreact'
import CrmStore from './../crm.store'
import { UITypeAhead } from './../../forms/form-fields'

import './ContactsList.component.scss'

let searchTimer = null

const ContactsList = ({ locationId, disabled }) => {
  const {
      Location,
      Contacts,
      fetchContactsByLocation,
      editContact,
      canEditContact,
      assignContact,
      emptyResults,
    } = CrmStore,
    canEdit = canEditContact(),
    searchContacts = (locationId, searchTerm) => {
      if (searchTimer) {
        clearTimeout(searchTimer)
        searchTimer = null
      }

      setTimeout(() => {
        CrmStore.searchContacts(locationId, searchTerm)
        clearTimeout(searchTimer)
        searchTimer = null
      }, 250)
    },
    onSearchEvent = async (searchTerm) => {
      CrmStore.searchTerm = searchTerm

      if (searchTerm && `${searchTerm}`.length > 2) {
        CrmStore.isSearchingContacts = true
        return searchContacts(locationId, searchTerm)
      }

      CrmStore.emptyResults = false
    }

  // useEffect(() => {
  //   fetchContactsByLocation(locationId)
  // }, [locationId, fetchContactsByLocation])

  const Cts = Contacts.map((Contact) => {
    return {
      value: Contact.id(),
      text: `${Contact.get('first_name')} ${Contact.get(
        'last_name'
      )} (${Contact.get('email')})`,
    }
  })

  return (
    <div className="ContactsListComponent">
      <UITypeAhead
        placeholder="Search Contacts"
        onSelect={(option) =>
          assignContact(
            Contacts.filter((C) => C.id() === (option?.value || false)).shift()
          )
        }
        options={Contacts.map((Contact) => ({
          value: Contact.id(),
          text: `${Contact.get('first_name')} ${Contact.get(
            'last_name'
          )} (${Contact.get('email')})`,
        }))}
        disabled={!!disabled}
        readonly={!!disabled}
        value={''}
        required={true}
        rules={{ required: true }}
        onValidityChange={(validity) => {}}
        isSearching={CrmStore.isSearchingContacts}
        onSearchChange={(searchTerm) => onSearchEvent(searchTerm)}
      ></UITypeAhead>
      <MDBCollapse isOpen={emptyResults}>
        <MDBAlert color="danger">
          You have no contacts in your crm matching the search term '
          {CrmStore.searchTerm}'.
        </MDBAlert>
      </MDBCollapse>
    </div>
  )
}

export default observer(ContactsList)
