import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import CrmStore from './../crm.store'
import { UIDropdown } from './../../forms/form-fields'

export default observer(() => {
  const { Locations, Location, setLocation, initLocation } = CrmStore,
    isDisabled = !Locations || !Locations.length || CrmStore.isAssigned,
    options = toJS(Locations || []).sort((a, b) =>
      a.get('name').localeCompare(b.get('name'))
    )

  return (
    <div className={['location-selector-component'].join(' ')}>
      <table>
        <tbody>
          <tr>
            <td>
              <UIDropdown
                label="My 10X Location"
                name="location_id"
                disabled={isDisabled}
                className="location-selector"
                options={options.map((L) => ({
                  text: (
                    <>
                      {L.get('name') + ' '}
                      <small>{L.id()}</small>
                    </>
                  ),
                  value: `${L.id()}`,
                  checked:
                    Location?.id() && `${Location?.id()}` === `${L.id()}`,
                }))}
                selected={Location && Location?.id()}
                onChange={(evt) =>
                  setLocation(
                    Locations.find((L) => L.id() === evt.target.value)
                  ) && initLocation(evt.target.value)
                }
                required={true}
                value={Location && Location?.id()}
                rules={{ required: true }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
})
