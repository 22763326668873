import React, { useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  MDBAlert,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import CarrierStore from './../Carrier.store'
import CheckoutStore from './../Checkout.store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ConfirmDeleteModalComponent from './../../adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import SendToCarrierButton from './../SendToCarrierButton/SendToCarrierButton.component'
import AdditionalDocumentsButton from './../AdditionalDocumentsButton/AdditionalDocumentsButton.component'
import RenderContractDates from './../RenderContractDates/RenderContractDates.component'
import ACMStore from './../AgentContractManager.store'
import { isAdobeContract } from './../functions'
import MediaUploaderModal from './../../modals/MediaUploaderModal'
import './AgentContractsTable.scss'
import CarrierService from '../../../shared/services/Carrier.service'
import AgentContractGuideModal from '../AgentContractGuideModal/AgentContractGuideModal.component'

const canSend = {}

const getStateCounts = () => {
  let counts = { current: 0, expired: 0 }
  ACMStore.Contracts.forEach(
    (contract) =>
      counts[
        contract.get('disposition') === 'expired' ? 'expired' : 'current'
      ]++
  )
  return counts
}

let rowIndex = 0

const AgentContractTableSelector = ({ activeViewState, onChange }) => {
  let counts = getStateCounts(),
    // check for contracts waiting on agent.
    reqAgent =
      counts.current > 0
        ? CarrierStore.getAgentCarriers().filter(
            (carrier) =>
              carrier.disposition !== 'completed' &&
              carrier.disposition !== 'expired' &&
              CarrierStore.dispositionToWaitingOn(carrier) === 'agent'
          ).length
        : 0

  return (
    <div id="AgentContractTableSelector">
      <div className="view-state-label">
        <label>You Are Currently Viewing</label>
        <small>Which of your carrier contracts would you like to review?</small>
      </div>
      <ul className={`view-state-list view-active-${activeViewState}`}>
        <li
          className={
            'view-state-current ' + (reqAgent > 0 ? 'invalid-state' : '')
          }
          onClick={() => onChange('current')}
        >
          <span className="view-state-pill">{counts.current}</span>&nbsp;My
          Current Contracts
        </li>
        <li className="view-state-expired" onClick={() => onChange('expired')}>
          <span className="view-state-pill">{counts.expired}</span>&nbsp;My Past
          Contracts
        </li>
      </ul>
    </div>
  )
}

const getConfirmationTmplt = (Contract) => {
  const Carriers =
      (Array.isArray(ACMStore?.Carriers)
        ? ACMStore.Carriers
        : CarrierStore.getAgentCarriers()) || [],
    Carrier = Carriers.filter(
      (Carrier) =>
        parseInt(
          typeof Carrier?.id === 'function' ? Carrier.id() : Carrier?.id
        ) === parseInt(Contract.get('carrier_id'))
    ).shift()

  return (
    <>
      {Carrier?.get('c_name')}
      <br />
      {Contract?.get('disposition')}
      <br />
      {Contract?.get('agent_code')}
    </>
  )
}

const AgentContractsTable = ({ activeSpecs }) => {
  const [activeViewState, setActiveViewState] = useState('current'),
    [expire, setExpire] = useState(null),
    [deliveryAttempts, setDeliveryAttempts] = useState(false),
    [showContractGuideModal, setShowContractGuideModal] = useState(false),
    [selectedCarrier, setSelectedCarrier] = useState(null),
    [selectedContract, setSelectedContract] = useState(null),
    onChange = (viewState) => setActiveViewState(viewState)

  const onViewDeliveries = async (C) => {
    setDeliveryAttempts(true)
    const attempts = await C?.getDeliveryAttempts()
    setDeliveryAttempts(attempts || [])
  }

  const isAdminUser = UserProfileService.isA(
      ['internal-admin', 'internal-staff', 'system-admin', 'agency-owner'],
      true
    ),
    isAgent = UserProfileService.isA(['agent'], true)

  const fetchContractPdf = async (contract) => {
    try {
      await CarrierStore.fetchContractPdf(contract)
    } catch (ex) {
      console.log('Failed to download agreement.  ', ex)
      toast.error('Failed to download contract PDF.', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }

    CarrierStore.downloadContract()
  }

  const renderEditButton = (Contract) => {
    const enterWritingNumber = async () => {
      const carrier = await CarrierService.get(Contract?.get('carrier_id'))

      ACMStore.Contract = Contract
      CarrierStore._examineCarrier = carrier
      CarrierStore.examineAgentContract(Contract)
    }

    if (
      UserProfileService.isA(
        ['agency-owner', 'non-agent', 'system-admin'],
        true
      )
    ) {
      return (
        <div>
          <MDBBtn
            disabled={CarrierStore.getIsLoading('update.agent-contract')}
            className="writing-num-btn"
            onClick={(e) => enterWritingNumber()}
          >
            <MDBIcon icon="pencil-alt" />
            &nbsp;
            <span>Edit Contract</span>
          </MDBBtn>
        </div>
      )
    } else if (
      Contract &&
      ['unsent', 'expired'].indexOf(Contract.get('disposition')) < 0
    ) {
      return (
        <div>
          <MDBBtn
            disabled={CarrierStore.getIsLoading('update.agent-contract')}
            className="writing-num-btn"
            onClick={enterWritingNumber}
          >
            <MDBIcon icon="pencil-alt" />
            &nbsp;
            <span>
              {Contract.get('agent_code') ? 'Update' : 'Enter'} Writing Num.
            </span>
          </MDBBtn>
        </div>
      )
    }

    return <></>
  }

  const renderGuideButton = (Contract, Carrier) => {
    const showGuideModal = () => {
      setSelectedCarrier(Carrier)
      setSelectedContract(Contract)
      setShowContractGuideModal(true)
    }

    return (
      <div>
        <MDBBtn
          disabled={CarrierStore.getIsLoading('update.agent-contract')}
          className="writing-num-btn"
          onClick={(e) => showGuideModal()}
        >
          <MDBIcon fas icon="eye" className="guide-btn-icon" />
          &nbsp;
          <span>Guide</span>
        </MDBBtn>
      </div>
    )
  }

  const renderUploadButton = (Contract, Carrier) => {
    return (
      <div>
        <MDBBtn
          disabled={CarrierStore.getIsLoading('update.agent-contract')}
          className="writing-num-btn"
          onClick={() => {
            MediaUploaderModal.open({
              uploadType: 'contract_override',
              modalContent: (
                <div className="fs--1rem fw--500 text--center">
                  Upload a new Contract PDF
                  <br />
                  Carrier: {Carrier?.get('c_name')}
                </div>
              ),
              appendFormData: { contract_id: Contract.id() },
              validationRules: { contentType: ['application/pdf'] },
            })
              .then(async (filePath) => Contract.reload())
              .catch((err) => {
                if (err)
                  console.log(
                    'Failed to complete uploading override contract.',
                    err
                  )
              })
          }}
        >
          <MDBIcon icon="upload" />
          &nbsp;
          <span>Upload Contract</span>
        </MDBBtn>
      </div>
    )
  }

  const renderExpireUnexpireButton = (Contract) =>
    Contract.get('disposition') === 'expired' ? (
      <div>
        <MDBBtn
          disabled={CarrierStore.getIsLoading('update.agent-contract')}
          className="unexpire-btn"
          onClick={async () => {
            Contract.set('disposition', 'pending')
            await Contract.save()
            await Contract.reload()
          }}
        >
          <MDBIcon icon="trash" />
          &nbsp;<span>Restore Contract</span>
        </MDBBtn>
      </div>
    ) : (
      <div>
        <MDBBtn
          disabled={CarrierStore.getIsLoading('update.agent-contract')}
          className="expire-btn"
          onClick={() => setExpire(Contract)}
        >
          <MDBIcon icon="trash" />
          &nbsp;<span>Remove Contract</span>
        </MDBBtn>
      </div>
    )

  const renderDownloadButton = (Contract) => {
    const isOverride = Contract?.get('contract_id') === 'override',
      showBtn = () => {
        const show =
          Contract?.get('disposition') !== 'unset' &&
          Contract?.get('disposition') !== 'pending' &&
          Contract?.get('agreement_id') &&
          isAdobeContract(Contract)

        if (show) return true

        if ((isAdminUser || isAgent) && isOverride) return true

        return false
      }

    return showBtn() ? (
      <div>
        <MDBBtn
          disabled={!!CarrierStore.downloading}
          className="download-btn"
          onClick={() => fetchContractPdf(Contract)}
        >
          {!!CarrierStore.downloading ? (
            <i className="fa fa-spin fa-spinner"></i>
          ) : (
            <MDBIcon icon="file-pdf" />
          )}
          &nbsp;
          <span>
            Download{' '}
            {isOverride
              ? 'PDF'
              : CarrierStore._carrierContractTypes[Contract?.get('carrier_id')]}
          </span>
        </MDBBtn>
      </div>
    ) : (
      <></>
    )
  }

  /*
  const renderContractLink = (carrier) => {
    let link = carrier?.link,
      CartItems = toJS(CheckoutStore.CartItems)

    if (!link) {
      carrier.CartItem = carrier.CartItem
        ? carrier.CartItem
        : Array.isArray(CartItems)
        ? CartItems.filter(
            (CartItem) =>
              parseInt(CartItem.get('user_contract_id')) ===
              parseInt(carrier.id)
          ).shift()
        : null
      link = link ? link : carrier.CartItem && carrier.CartItem.get('link')
    }

    carrier.link = link
    if (carrier.link) {
      // ADD_CONTRACT_LINK_HERE
      // return <a href={carrier.link} target='_BLANK'>Access Carrier Contract</a>
    }
  }
  */

  const renderContractDispositionCell = (Contract) => {
    const isAdobe = isAdobeContract(Contract),
      dispo = Contract?.get('disposition')
    let dispositionDetail = ''

    switch ((isAdobe ? 'adobe-' : '') + dispo) {
      case 'adobe-unsent':
        dispositionDetail = 'Waiting on agent to start contract'
        break
      case 'adobe-pending':
        dispositionDetail = 'Waiting on agent to start contract'
        break
      case 'adobe-reviewing':
        dispositionDetail = 'Quality control reviewing'
        break
      case 'adobe-awaiting':
        dispositionDetail = 'Awaiting agent writing number'
        break
      case 'adobe-completed':
        dispositionDetail = 'Contract Completed'
        break
      case 'unsent':
        dispositionDetail = 'Waiting on agent to start contract'
        break
      case 'pending':
        dispositionDetail = 'Waiting on agent signature'
        break
      case 'reviewing':
        dispositionDetail = 'Quality control reviewing'
        break
      case 'awaiting':
        dispositionDetail = 'Awaiting agent writing number'
        break
      case 'completed':
        dispositionDetail = 'Contract Completed'
        break
      default:
        dispositionDetail = ''
        break
    }

    return (
      <div className={`disposition ${Contract?.get('disposition')}`}>
        <MDBIcon icon="clock" />
        <MDBIcon icon="flag-checkered" />
        &nbsp;{dispositionDetail}
        {/*{renderContractLink(carrier)}*/}
        {Contract?.get('disposition') === 'awaiting' ? (
          <MDBAlert color="warning" className="requires-attention">
            <MDBIcon icon="exclamation-triangle" />
            &nbsp;Requires Your Attention
          </MDBAlert>
        ) : (
          <></>
        )}
        {(dispo === 'completed' || dispo === 'expired') &&
        Contract?.get('agent_code') ? (
          <div className="agent-writing-code">
            Writing Number: {Contract?.get('agent_code')}
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }

  const renderContractDatesCell = (Contract) => (
    <div className="contract-dates">
      <RenderContractDates Contract={Contract} canEdit={false} />
    </div>
  )

  const onExpired = async (ExpiredContract) => {
    setExpire(null)

    // Update contract disposition, save & reload to get updated attribs.
    ExpiredContract.set('disposition', 'expired')
    await ExpiredContract.save()
    await ExpiredContract.reload()

    // after the contract has been expired,
    // the cart may have been closed.  check
    // the cart status, and update if necc.
    let Cart = toJS(CheckoutStore.Cart),
      cartId = Cart ? parseInt(Cart.get('id')) : null

    Cart = await CheckoutStore.fetchOpenCart()
    if (
      (cartId && !Cart) ||
      (cartId && Cart && cartId !== parseInt(Cart.get('id'))) ||
      (!cartId && Cart)
    ) {
      // ---------------------------------------
      // cart has changed.

      // 1. clear out previous selected carriers.
      CarrierStore._selectedCarriers = []
      CheckoutStore.CartItems = []

      // 2. select carriers that have an open
      if (Cart) {
        let CartItems =
          (await CheckoutStore.fetchCartItems(Cart.get('id'))) || []
        CartItems.filter(
          (CartItem) => CartItems.get('item_status') !== 'ABANDONED'
        )
          .map((CartItem) =>
            CartItem.get('carrier_id')
              ? CarrierStore.getCarriers()
                  .filter(
                    (c) =>
                      parseInt(c.id) === parseInt(CartItem.get('carrier_id'))
                  )
                  .shift()
              : null
          )
          .forEach((carrier) => CarrierStore.onCarrierToggle(carrier, true))

        if (CartItems.length) CarrierStore.hasMetRequirements()
      }
    }
  }

  const organizeSiblings = (Carriers) => {
    const CarriersById = {},
      LinkedSiblings = {}
    Carriers.forEach((Carrier) => (CarriersById[Carrier.id()] = Carrier))

    for (let carrierId in CarriersById) {
      if (
        CarriersById.hasOwnProperty(carrierId) &&
        CarriersById[carrierId].get('sibling_ids')
      )
        LinkedSiblings[carrierId] = Array.isArray(
          CarriersById[carrierId].get('sibling_ids')
        )
          ? CarriersById[carrierId].get('sibling_ids')
          : JSON.parse(CarriersById[carrierId].get('sibling_ids'))

      if (
        LinkedSiblings.hasOwnProperty(carrierId) &&
        Array.isArray(LinkedSiblings[carrierId])
      ) {
        LinkedSiblings[carrierId] = LinkedSiblings[carrierId]
          .map((siblingId) => {
            let Sibling
            if (
              CarriersById.hasOwnProperty(siblingId) &&
              CarriersById[siblingId]
            ) {
              Sibling = CarriersById[siblingId]
              delete CarriersById[siblingId]
              return Sibling
            }
          })
          .filter((Carrier) => Carrier)

        if (LinkedSiblings[carrierId].length === 0) {
          delete LinkedSiblings[carrierId]
        } else {
          LinkedSiblings[carrierId].unshift(CarriersById[carrierId])
          delete CarriersById[carrierId]
        }
      }
    }

    return Object.values(CarriersById)
      .concat(Object.values(LinkedSiblings))
      .sort((CarrierA, CarrierB) => {
        CarrierA = Array.isArray(CarrierA) ? CarrierA[0] : CarrierA
        CarrierB = Array.isArray(CarrierB) ? CarrierB[0] : CarrierB
        return `${CarrierA.get('c_name')}`.localeCompare(
          `${CarrierB.get('c_name')}`,
          'en'
        )
      })
  }

  const renderContractRow = (Carrier, Contract, isMulti, isFirst) => {
    const dispoCell = renderContractDispositionCell(Contract),
      datesCell = renderContractDatesCell(Contract),
      priority = Carrier?.getPriority(
        activeSpecs,
        UserProfileService.get('usertype_id')
      )

    rowIndex++

    return (
      <div
        key={`agent-carrier-table-${activeViewState}-${Contract.id()}`}
        className={`agent-carrier-table-row  ${
          isMulti ? 'with-siblings' : ''
        } carrier-is-${priority}`}
      >
        <div className="index">
          <div>{rowIndex}</div>
          <span className="is-priority is-core">$$$</span>
          <span className="is-priority is-preferred">$$</span>
          <span className="is-priority is-additional">$</span>
        </div>
        <div className="identity">
          {isMulti ? (
            isFirst ? (
              <h6>{Carrier?.get('c_name')}</h6>
            ) : (
              <></>
            )
          ) : (
            <h6>{Carrier?.get('c_name')}</h6>
          )}
          <div className="contract-descriptor">
            <small>{Carrier?.get('c_description')}</small>
            <br />
            <MDBAlert
              color="success"
              className={`carrier-priority-pill is-priority is-core`}
            >
              Core Carrier
            </MDBAlert>
            <MDBAlert
              color="info"
              className={`carrier-priority-pill is-priority is-preferred`}
            >
              Preferred Carrier
            </MDBAlert>
            <MDBAlert
              color="secondary"
              className={`carrier-priority-pill is-priority is-additional`}
            >
              Standard Carrier
            </MDBAlert>
          </div>
        </div>
        <div className="dispo-dates-splitter">
          {dispoCell}
          {datesCell}
        </div>
        {dispoCell}
        {datesCell}
        <div>
          {renderGuideButton(Contract, Carrier)}
          {renderEditButton(Contract)}
          {renderUploadButton(Contract, Carrier)}
          {renderDownloadButton(Contract)}
          {
            <SendToCarrierButton
              Contract={Contract}
              onViewDeliveries={() => onViewDeliveries(Contract)}
            />
          }
          {renderExpireUnexpireButton(Contract)}
          {<AdditionalDocumentsButton Contract={Contract} Carrier={Carrier} />}
        </div>
      </div>
    )

    return <></>
  }

  const renderMultiContractRow = (Carriers, Contracts) =>
    Carriers.map((Carrier, idx) =>
      renderContractRow(
        Carrier,
        Contracts.filter(
          (C) => `${C.get('carrier_id')}` === `${Carrier.id()}`
        ).shift(),
        true,
        idx === 0
      )
    )

  const renderTable = () => {
    if (!activeViewState || ['current', 'expired'].indexOf(activeViewState) < 0)
      return <div>Please Make Selection Above</div>

    let Contracts = ACMStore.Contracts.filter(
      (Contract) =>
        (Contract.get('disposition') === 'expired' &&
          activeViewState === 'expired') ||
        (activeViewState !== 'expired' &&
          Contract.get('disposition') !== 'expired')
    )

    const rows = () => {
      if (Contracts.length > 0) {
        rowIndex = 0

        // determine contract-carrier names for
        // sorting by setting carriers by ID.
        const CarriersById = {}
        Contracts.forEach((Contract, idx) => {
          const Carrier = ACMStore.Carriers.filter(
            (Carrier) =>
              parseInt(Carrier.id()) === parseInt(Contract.get('carrier_id'))
          ).shift()
          if (Carrier) CarriersById[Carrier.id()] = Carrier
        })

        // merge carrier siblings for handling duplicates.
        // carriers are returned pre-sorted
        const Carriers = organizeSiblings(Object.values(CarriersById))

        return Carriers.map((Carrier, idx) => {
          if (Array.isArray(Carrier)) {
            // Contracts
            return renderMultiContractRow(
              Carrier,
              Contracts.filter(
                (Contract) =>
                  Carrier.map((C) => parseInt(C.id())).indexOf(
                    parseInt(Contract.get('carrier_id'))
                  ) > -1
              )
            )
          } else {
            return renderContractRow(
              Carrier,
              Contracts.filter(
                (Contract) =>
                  parseInt(Contract.get('carrier_id')) ===
                  parseInt(Carrier.id())
              ).shift()
            )
          }
        })

        // Sort contracts by Carrier name.
        Contracts = Contracts.sort((ContractA, ContractB) => {
          if (ContractA?.get('carrier_id') && ContractB?.get('carrier_id'))
            return `${CarriersById[ContractA.get('carrier_id')].get(
              'c_name'
            )}`.localeCompare(
              `${CarriersById[ContractB.get('carrier_id')].get('c_name')}`,
              'en'
            )
          return 0
        })

        // Iterate Contracts array, rendering each as row.
        return Contracts.map((Contract, idx) => {
          const Carrier = ACMStore.Carriers.filter(
              (Carrier) =>
                parseInt(Carrier.id()) === parseInt(Contract.get('carrier_id'))
            ).shift(),
            dispoCell = renderContractDispositionCell(Contract),
            datesCell = renderContractDatesCell(Contract),
            priority = Carrier?.getPriority(
              activeSpecs,
              UserProfileService.get('usertype_id')
            )

          return (
            <div
              key={`agent-carrier-table-${activeViewState}-${Contract.id()}`}
              className={`agent-carrier-table-row carrier-is-${priority}`}
            >
              <div className="index">
                <div>{idx + 1}</div>
                <span className="is-priority is-core">$$$</span>
                <span className="is-priority is-preferred">$$</span>
                <span className="is-priority is-additional">$</span>
              </div>
              <div className="identity">
                <h6>{Carrier?.get('c_name')}</h6>
                <small>{Carrier?.get('c_description')}</small>
                <br />
                <MDBAlert
                  color="success"
                  className={`carrier-priority-pill is-priority is-core`}
                >
                  Core Contract
                </MDBAlert>
                <MDBAlert
                  color="info"
                  className={`carrier-priority-pill is-priority is-preferred`}
                >
                  Preferred Contract
                </MDBAlert>
                <MDBAlert
                  color="secondary"
                  className={`carrier-priority-pill is-priority is-additional`}
                >
                  Standard Contract
                </MDBAlert>
              </div>
              <div className="dispo-dates-splitter">
                {dispoCell}
                {datesCell}
              </div>
              {dispoCell}
              {datesCell}
              <div className="contract-action-btns">
                {renderEditButton(Contract)}
                {renderUploadButton(Contract, Carrier)}
                {renderDownloadButton(Contract)}
                {UserProfileService.isA(
                  ['agency-owner', 'internal-admin', 'system-admin'],
                  true
                ) ? (
                  <SendToCarrierButton Contract={Contract} />
                ) : (
                  <></>
                )}
                {renderExpireUnexpireButton(Contract)}
                {
                  <AdditionalDocumentsButton
                    Contract={Contract}
                    Carrier={Carrier}
                  />
                }
              </div>
            </div>
          )
        })
      }

      let msg
      if (activeViewState === 'current')
        msg =
          'You do not have any carrier contracts.  Shop new contracts to get started.'
      else if (activeViewState === 'expired')
        msg = 'You do not have any expired or past carrier contracts.'

      return (
        <div className="agent-carrier-table-row alert-row">
          <div className="text-center alert alert-warning">
            <strong>{`${msg}`}</strong>
          </div>
        </div>
      )
    }

    return <div className="agent-carriers-table">{rows()}</div>
  }

  return (
    <>
      <MDBCard id="AgentContractsTableComponent">
        <MDBCardHeader>
          <MDBRow>
            <MDBCol size="12">
              <div>
                <MDBIcon icon="file-contract" />
                &nbsp;My Carrier Contracts
              </div>
              <small>
                These are your current & past contracts. After starting new
                carrier contracts, this is where you will update your Agent
                Writing Number.
              </small>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody>
          <AgentContractTableSelector
            activeViewState={activeViewState}
            onChange={onChange}
          />
          {renderTable()}
        </MDBCardBody>
      </MDBCard>
      <AgentContractGuideModal
        isOpen={showContractGuideModal}
        toggleModal={() => setShowContractGuideModal(false)}
        carrier={selectedCarrier}
        contract={selectedContract}
      ></AgentContractGuideModal>
      <ConfirmDeleteModalComponent
        confirm={expire ? getConfirmationTmplt(expire) : null}
        onConfirm={onExpired}
        shouldEnable={true}
        params={expire}
        onCancel={() => setExpire(null)}
      />
      <MDBModal
        isOpen={deliveryAttempts === true || Array.isArray(deliveryAttempts)}
        toggle={() => setDeliveryAttempts(false)}
      >
        <MDBModalHeader toggle={() => setDeliveryAttempts(false)}>
          Past Contract Delivery Attempts
        </MDBModalHeader>
        <MDBModalBody>
          {Array.isArray(deliveryAttempts) && deliveryAttempts.length > 0 ? (
            <table id="contract-delivery-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Requested At:</th>
                  <th>Outcome:</th>
                  <th>Details:</th>
                </tr>
              </thead>
              <tbody>
                {deliveryAttempts.map((attempt, idx) => {
                  return (
                    <tr
                      key={`c-del-attmpet-${idx}-${attempt?.id}`}
                      className={
                        attempt.sent_at ? 'attempt-success' : 'attempt-danger'
                      }
                    >
                      <td></td>
                      <td>
                        {moment(attempt.created_at).format('h:mm a')}
                        <br />
                        {moment(attempt.created_at).format('M/D/Y')}
                      </td>
                      <td>{attempt.sent_at ? 'SENT' : 'FAILED'}</td>
                      <td>
                        {attempt.outcome
                          ? attempt.outcome
                          : attempt.sent_at
                          ? `Successfully sent at ${attempt.sent_at}`
                          : 'Unable to send email. Please check size of attachments, email addresses or other details & try again.'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : Array.isArray(deliveryAttempts) ? (
            <MDBAlert color="warning">Never sent anywhere</MDBAlert>
          ) : (
            'loading page stuff'
          )}
        </MDBModalBody>
      </MDBModal>
    </>
  )
}

export default observer(AgentContractsTable)
