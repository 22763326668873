import React, { Component } from 'react'
import '../api/FirebaseAuth'
import Navbar from '../components/newhome/Navbar'
import Footer from '../components/newhome/Footer'
import env from './../environments/environment'
import AdminApi from './../api/admin-api/admin-api'

class Referral extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentData: null,
    }
  }

  async componentDidMount() {
    try {
      const Row = await AdminApi.getAgencyContentBySlug({
        slug: 'referral-program-information',
        active: 1,
      }).then((res) => {
        this.content = res?.data?.data
        return res?.data?.data // Return the data if needed for further processing
      })

      let contentData
      if (Row) {
        contentData = Row.shift()
        this.setState({ contentData: contentData?.content })
      }

      const iframeRow = await AdminApi.getAgencyContentBySlug({
        slug: 'referral-form-page',
        active: 1,
      }).then((res) => {
        this.content = res?.data?.data
        return res?.data?.data // Return the data if needed for further processing
      })

      let iframeHTML
      if (iframeRow) {
        iframeHTML = iframeRow.shift()
        this.setState({ iframeHTML: iframeHTML?.content })
      }
    } catch (error) {
      console.error('Error fetching content:', error)
    }
  }

  render() {
    return (
      <>
        <div className="page contact-page-custom-wrapper">
          <Navbar />

          <section
            style={{
              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fbg-referral.jpg?alt=media)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
            className="section-60 section-sm-top-90 section-sm-bottom-100"
          >
            <div
              style={{
                backgroundColor: 'white',
                marginLeft: '10%',
                marginRight: '10%',
              }}
            >
              <div
                style={{
                  padding: '20px',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                }}
              >
                {/* Render the contentData if available */}
                {this.state.contentData && (
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.contentData }}
                  />
                )}
              </div>

              {/* Render the contentData if available */}
              {this.state.iframeHTML && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.iframeHTML }}
                />
              )}
              <script src="https://team.bmagencybuilders.com/js/form_embed.js"></script>
            </div>
          </section>

          <Footer />
        </div>
      </>
    )
  }
}

export default Referral
