import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import {
  MDBBtn,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import { toast } from 'react-toastify'

import './CarrierContractingInstructionsForm.scss'

const getContractingInstructions = (source) => {
  const sources = {
    contract_type: () => {
      switch (CarrierMgmtStore.contract?.contract_type) {
        case 'LINK':
          return 'Click the accompanying link & fill out the web-form to complete the selected carrier contracts.'
        case 'PDF':
          return 'Click the accompanying link, fill out the PDF document & e-sign to complete the selected carrier contracts.'
        default:
          return ''
      }
    },

    fmo: () => `${CarrierMgmtStore.fmo?.contracting_instructions || ''}`,
    carrier: () =>
      `${CarrierMgmtStore.carrier?.contracting_instructions || ''}`,
  }

  return sources.hasOwnProperty(source) ? sources[source]() : ''
}

const CarrierContractingInstructionsForm = ({ table }) => {
  const [instructions, setInstructions] = useState(
    CarrierMgmtStore.carrier?.contracting_instructions || ''
  )
  const [postInstructions, setPostInstructions] = useState(
    CarrierMgmtStore.carrier?.post_contracting_instructions || ''
  )

  const onChange = (event) => {
    switch (event.target.name) {
      case 'contracting_instructions':
        if (event.target.value !== instructions)
          setInstructions(event.target.value)
        break
      case 'contracting_post_instructions':
        if (event.target.value !== postInstructions)
          setPostInstructions(event.target.value)
        break
      default:
        if (event.target.value !== instructions)
          setInstructions(event.target.value)
        break
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    try {
      await CarrierMgmtStore.saveCarrierInstructions(
        instructions,
        postInstructions
      )
      toast.success('Update instructions Successfully.', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return true
    } catch (error) {
      toast.error('Update instructions failed.', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return false
    }
  }

  const renderLoadingWheel = () => {
    if (
      CarrierMgmtStore.getIsLoading('fetch.carrier') ||
      CarrierMgmtStore.getIsLoading('update.carrier')
    )
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      )
  }

  const renderForm = () => {
    if (parseInt(CarrierMgmtStore.carrier?.id) > 0) {
      return (
        <>
          <form noValidate onSubmit={onSubmit}>
            <MDBRow>
              <MDBCol size="12">
                <MDBInput
                  label="Carrier Contracting Instructions"
                  type="textarea"
                  name="contracting_instructions"
                  value={instructions || ''}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12">
                <MDBInput
                  label="Carrier Post-Contracting Instructions"
                  type="textarea"
                  name="contracting_post_instructions"
                  value={postInstructions || ''}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12">
                <MDBBtn className="btn-block" type="submit">
                  {CarrierMgmtStore.getIsLoading('update.carrier-details') ? (
                    <span>
                      Saving...&nbsp;
                      <i className="fa fa-spin fa-spinner" />
                    </span>
                  ) : (
                    'Save Instructions'
                  )}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
          <hr />
        </>
      )
    }
  }

  const renderContractingInstructions = () => {
    let instr = {
      contract_type: getContractingInstructions('contract_type'),
      fmo: getContractingInstructions('fmo'),
      carrier: getContractingInstructions('carrier'),
    }

    if (Object.values(instr).every((n) => !n))
      return (
        <div className="instruction no-instructions">
          <MDBIcon icon="exclamation-triangle" />
          &nbsp;This carrier contract does not have any specific instructions to
          share.
        </div>
      )

    if (Object.values(instr).every((n) => !!n))
      return Object.values(instr).map((inst, idx) => (
        <div key={`instru-key-${idx}`} className="instruction">
          {inst}
        </div>
      ))

    const instructions = (
        <pre className="instruction-example">
          {Object.keys(instr)
            .filter((k) => `${instr[k]}`.trim().length > 0)
            .map((k, idx) => (
              <div key={`instru-key-${idx}`} className="instruction">
                {instr[k]}
              </div>
            ))}
        </pre>
      ),
      assistance = Object.keys(instr)
        .filter((k) => `${instr[k]}`.trim().length === 0)
        .map((k, idx) => {
          if (
            k === 'contract_type' &&
            !CarrierMgmtStore.contract?.contract_type
          )
            return (
              <div
                className="instruction-missing"
                key={'invalid-instr-contract-type'}
              >
                <small>
                  Set Carrier Contract Type to include contract type
                  instructions.
                </small>
              </div>
            )

          if (
            k === 'fmo' &&
            CarrierMgmtStore.fmo &&
            !CarrierMgmtStore.fmo?.contracting_instructions
          )
            return (
              <div className="instruction-missing" key={'invalid-instr-fmo'}>
                <small>
                  Set FMO Contracting Instructions on the admin carriers page to
                  include FMO-specific instructions.
                </small>
              </div>
            )

          if (
            k === 'carrier' &&
            !CarrierMgmtStore.carrier?.contracting_instructions
          )
            return (
              <div
                className="instruction-missing"
                key={'invalid-instr-carrier'}
              >
                <small>
                  Set Carrier Contracting Instructions above to include
                  carrier-specific instructions.
                </small>
              </div>
            )

          return false
        })
        .filter((n) => n !== false)

    return (
      <>
        {instructions}
        <small>Note:</small>&nbsp;{assistance}
      </>
    )
  }

  const ci = `${CarrierMgmtStore.carrier?.contracting_instructions || ''}`,
    pci = `${CarrierMgmtStore.carrier?.post_contracting_instructions || ''}`

  useEffect(() => {
    setInstructions(ci)
    setPostInstructions(pci)
  }, [ci, pci])

  return (
    <MDBCard id="CarrierContractingInstructionsForm">
      <MDBCardHeader>
        Contracting Instructions&nbsp;
        {renderLoadingWheel()}
      </MDBCardHeader>
      <MDBCardBody>
        {renderForm()}
        <MDBRow>
          <MDBCol size="12">
            <h6>Example Contracting Instructions:</h6>
            {renderContractingInstructions()}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierContractingInstructionsForm)
