import { makeAutoObservable } from 'mobx'
import {
  CommissionLevelFactory,
  DivisionFactory,
  UserFactory,
  UsertypeFactory,
} from './../../shared/factories'
import UsertypeService from './../../shared/services/Usertype.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import appConstants from './../../constants/appConstants'
import firebase from 'firebase/app'
import { invalidMarketingTitles } from '../../constants/invalidMarketingTitles'

const saveToFirebase = async (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((result) =>
        result?.user?.uid
          ? resolve(result.user.uid)
          : reject('Invalid server response while creating authentication')
      )
      .catch((error) => reject(error?.message))
  })
}

const getAllUsertypes = async () => {
  try {
    return await UsertypeFactory.search({ search: {}, pagination: false })
  } catch (ex) {
    return []
  }
}

const getAllDivisions = async () => {
  try {
    return await DivisionFactory.search({ search: {}, pagination: false })
  } catch (ex) {
    return []
  }
}

const getAllRegions = async () => {
  try {
    return await UserFactory.search({
      search: { usertype_id: 38 },
      pagination: false,
    })
  } catch (ex) {
    return []
  }
}

const getAllDistricts = async () => {
  try {
    return await UserFactory.search({
      search: { usertype_id: 93 },
      pagination: false,
    })
  } catch (ex) {
    return []
  }
}

const getCommissionLevels = async () => {
  try {
    return await CommissionLevelFactory.search({
      search: {},
      pagination: false,
    })
  } catch (ex) {
    return []
  }
}

const getUplineUsersByDivision = async (divisionId) => {
  try {
    return await UserFactory.search({
      search: {
        usertype_id: UsertypeService.nameToId('recruiter-group'),
        u_devision: divisionId,
      },
      pagination: false,
    })
  } catch (ex) {
    return []
  }
}

const getTimezones = () => {
  return appConstants.Timezone
}

const getAccountStatuses = () => {
  return [
    { id: '1', name: 'Active' },
    { id: '0', name: 'Deactivated' },
    { id: '-1', name: 'Protected' },
    { id: '-2', name: 'Blacklisted' },
  ]
}

const getStates = () => {
  return appConstants.States
}

class CreateUserStore {
  isLoading = false
  isSaving = false
  firebaseUid = null

  lockedDivisionId = null
  lockedRegionId = null
  lockedDistrictId = null
  lockUsertypeIds = [] // empty array is restrictive, null is wide open.
  lockCommLevelIds = [] // empty array is restrictive, null is wide open.
  lockAccountStatuses = [] // empty array is restrictive, null is wide open.
  lockUplineIds = [] // empty array is restrictive, null is wide open.

  Usertypes = []
  Divisions = []
  Regions = []
  Districts = []
  CommLevels = []
  User = null
  UplineUsers = []
  timezones = getTimezones()
  accountStatuses = getAccountStatuses()
  states = getStates()
  formValidity = {}
  showFormValidity = false

  constructor() {
    makeAutoObservable(this)
  }

  init = async (config) => {
    this.loading = true

    config = config && typeof config === 'object' ? config : {}

    this.lockedDivisionId = UserProfileService.isA([
      'system-admin',
      'internal-admin',
      'agency-owner',
    ])
      ? null
      : UserProfileService.get('u_devision')
    this.lockedRegionId = UserProfileService.isA([
      'system-admin',
      'internal-admin',
      'agency-owner',
    ])
      ? null
      : await UserProfileService.getRegion()
    this.lockedDistrictId = UserProfileService.isA([
      'system-admin',
      'internal-admin',
      'agency-owner',
    ])
      ? null
      : await UserProfileService.getDistrict()

    this.lockUsertypeIds =
      (Array.isArray(config?.usertype_ids) &&
        config.usertype_ids.map((c) => `${c}`)) ||
      null

    this.lockCommLevelIds =
      (Array.isArray(config?.comm_level_ids) &&
        config.comm_level_ids.map((c) => `${c}`)) ||
      null
    this.lockAccountStatuses =
      (Array.isArray(config?.account_statuses) &&
        config.account_statuses.map((c) => `${c}`)) ||
      null
    this.lockUplineIds =
      (Array.isArray(config?.upline_ids) &&
        config.upline_ids.map((c) => `${c}`)) ||
      null
    this.lockedRegionId =
      UserProfileService.getCurrentUserTypeId() === 38
        ? UserProfileService.get('id')
        : null
    this.lockedDistrictId =
      UserProfileService.getCurrentUserTypeId() === 93
        ? UserProfileService.get('id')
        : null

    this.User = UserFactory.create({
      u_fname: '',
      u_lname: '',
      u_marketing_title: '',
      u_email: '',
      u_password: '',
      u_phone: '',
      u_timezone: '',
      u_address1: '',
      u_address2: '',
      u_city: '',
      u_state: '',
      u_zip: '',
      u_devision: this.lockedDivisionId || 0,
      u_upline_id:
        Array.isArray(this.lockUplineIds) && this.lockUplineIds.length === 1
          ? this.lockUplineIds[0]
          : '',
      usertype_id:
        Array.isArray(this.lockUsertypeIds) && this.lockUsertypeIds.length === 1
          ? this.lockUsertypeIds[0]
          : '',
      commission_id:
        Array.isArray(this.lockCommLevelIds) &&
        this.lockCommLevelIds.length === 1
          ? this.lockCommLevelIds[0]
          : '',
      u_birthday: '',
      u_enrolled: '',
      u_active:
        Array.isArray(this.lockAccountStatuses) &&
        this.lockAccountStatuses.length === 1
          ? this.lockAccountStatuses[0]
          : 1,
    })
    this.UplineUsers = []

    const [Usertypes, Divisions, Regions, Districts, CommLevels] =
      await Promise.all([
        getAllUsertypes(),
        getAllDivisions(),
        getAllRegions(),
        getAllDistricts(),
        getCommissionLevels(),
      ])

    this.getUplineUsers()

    this.Usertypes = Usertypes.sort((A, B) =>
      `${A?.get('displayname')}`.localeCompare(`${B?.get('displayname')}`, 'en')
    )
    this.Divisions = Divisions.sort((A, B) =>
      `${A?.get('d_name')}`.localeCompare(`${B?.get('d_name')}`, 'en')
    )
    this.Regions = Regions.sort((A, B) =>
      `${A?.get('u_fname')}`.localeCompare(`${B?.get('u_fname')}`, 'en')
    )
    this.Districts = Districts.sort((A, B) =>
      `${A?.get('u_fname')}`.localeCompare(`${B?.get('u_fname')}`, 'en')
    )
    this.CommLevels = CommLevels.sort((A, B) =>
      `${A?.get('com_name')}`.localeCompare(`${B?.get('com_name')}`, 'en')
    )
    this.timezones = getTimezones().sort((A, B) =>
      `${A.name}`.localeCompare(`${B.name}`, 'en')
    )

    this.loading = false
  }

  getUplineUsers = async () => {
    const divisionId = this.User?.get('u_devision')
    if (divisionId && !isNaN(divisionId))
      return (this.UplineUsers = (
        await getUplineUsersByDivision(divisionId)
      ).sort((A, B) =>
        `${A?.get('u_fname')} ${A?.get('u_lname')}`.localeCompare(
          `${B?.get('u_fname')} ${B?.get('u_lname')}`,
          'en'
        )
      ))
    return (this.UplineUsers = [])
  }

  getInvalidFields = () =>
    Object.keys(this.formValidity).filter(
      (key) => !this.formValidity[key].isValid
    )

  isFormValid = () =>
    this.getInvalidFields().length === 0 && !!this.User?.get('u_email')

  isValidMarketingTitle = () =>
    !invalidMarketingTitles.includes(
      this.User?.get('u_marketing_title').trim().toLowerCase()
    )

  createUser = async () => {
    this.isSaving = true

    try {
      this.firebaseUid = await saveToFirebase(
        this.User.get('u_email'),
        this.User.get('u_password')
      )
      this.User.set('firebase_uid', this.firebaseUid)
    } catch (ex) {
      console.log(`Create new firebase user failed: ${ex}`)
    }

    if ([226, 227, 228].indexOf(parseInt(this.User.get('usertype_id'))) > -1)
      this.User.set('has_onboarded', 1)
    this.User.set('completed_enroll', 1)

    try {
      await this.User.save()
    } catch (ex) {
      console.log(`Store new user failed: ${ex}`)
    }

    UserProfileService.track({
      event_type: 'ui.created-user.success',
      relation_id: this.User.id(),
      relation_model: 'User',
      payload: {
        usertype_id: this.User.get('usertype_id'),
      },
    })

    this.isSaving = false
  }
}

export default new CreateUserStore()
