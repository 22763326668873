import React from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import moment from 'moment-timezone'
import MetricsIntakeStore from './../../store'
import { MDBAlert, MDBContainer, MDBRow, MDBCol, MDBSwitch } from 'mdbreact'
import { toast } from 'react-toastify' // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css' // Import the CSS for toast notifications
import './AttendanceForm.scss'

// Initialize toast notifications
toast.configure()

const AttendanceForm = () => {
  const handleChange = async (event_id, e) => {
    try {
      if (!e.target.checked) {
        await MetricsIntakeStore.unattendEvent(event_id)
        toast.success('You have unattended the event.', {
          position: toast.POSITION.TOP_RIGHT, // Position of the toast
          autoClose: 3000, // Auto-close after 3 seconds
        })
      } else {
        await MetricsIntakeStore.attendEvent(event_id)
        toast.success('You have attended the event.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } catch (error) {
      console.error('Error updating attendance:', error)
      toast.error('Failed to update attendance. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const renderAttendanceToggle = (event) => {
    return (
      <MDBSwitch
        labelLeft={'No'}
        labelRight={'Yes'}
        checked={
          MetricsIntakeStore.attendance.filter(
            (a) => a.event_id === event.event_id
          ).length > 0
        }
        onChange={(e) => handleChange(event.event_id, e)}
      />
    )
  }

  const renderEventInputs = () => {
    const events = toJS(MetricsIntakeStore.events)
    if (!events || !Array.isArray(events) || !events.length)
      return <MDBAlert>There were no scheduled events on this date.</MDBAlert>

    return (
      <>
        <p>Did you attend... ?</p>
        {events.map((event, e) => {
          return (
            <MDBRow className="attendance-intake" key={'i-event-' + e}>
              <MDBCol size="12" sm="8">
                <label className="text-indigo">{event.summary}</label>
                <br />
                <small>
                  {moment(event.starts_at).format('h:mm a')} -{' '}
                  {moment(event.ends_at).format('h:mm a')}
                </small>
              </MDBCol>
              <MDBCol size="12" sm="4">
                {renderAttendanceToggle(event)}
              </MDBCol>
            </MDBRow>
          )
        })}
      </>
    )
  }

  return (
    <MDBContainer id="AttendanceForm" fluid>
      <form>{renderEventInputs()}</form>
    </MDBContainer>
  )
}

export default observer(AttendanceForm)
