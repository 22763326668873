import React from 'react'
import { getWeek, format, sub, getISOWeek, getQuarter } from 'date-fns'
import { UIDropdown } from './../../../../components/forms/form-fields'
import ProductionReportWidgetService from '../../../../shared/services/ProductionReportWidget.service'
import { fetchReportData } from './functions'

import './ProductionWidget.scss'

export default class ProductionWidget extends React.Component {
  _searchMethod = null
  _searchDownline = false
  _searchId = null

  _formatMetric = (value, canFloat) => {
    if (value !== null && isNaN(value)) return value

    switch (this.state.metric) {
      case 'av':
      case 'av_no_aca':
      case 'life_av':
      case 'med_supps_av':
        return this._convertToCurrency(value)
      case 'aca_lives':
      case 'ma_lives':
      case 'points':
      case 'enrolled':
        return canFloat ? parseFloat(value).toFixed(2) : parseInt(value)
      default:
        return value
    }
  }

  _formatExportMetric = (value, metric, canFloat) => {
    if (value !== null && isNaN(value)) return value
    switch (metric) {
      case 'av':
      case 'av_no_aca':
      case 'life_av':
      case 'med_supps_av':
        return this._convertToCurrency(value)
      case 'aca_lives':
      case 'ma_lives':
      case 'points':
      case 'enrolled':
        return canFloat ? parseFloat(value).toFixed(2) : parseInt(value)
      default:
        return value
    }
  }

  _convertToCurrency = (value) => {
    if (isNaN(value) || value === null || value === undefined) {
      return '$0.00' // Default fallback
    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Math.round(parseFloat(value) * 100) / 100)
  }

  _canAdvanceDate = () => {
    if (this.state.reportDate) {
      if (format(this.state.reportDate, 'yyyy') < format(new Date(), 'yyyy'))
        return true
      if (format(this.state.reportDate, 'yyyy') === format(new Date(), 'yyyy'))
        return (
          getWeek(this.state.reportDate, { weekStartsOn: 1 }) <
          getWeek(new Date(), { weekStartsOn: 1 })
        )
      return false
    }

    return true
  }

  _canAdvanceExportDate = () => {
    if (this.state.exportReportDate) {
      if (
        format(this.state.exportReportDate, 'yyyy') < format(new Date(), 'yyyy')
      )
        return true
      if (
        format(this.state.exportReportDate, 'yyyy') ===
        format(new Date(), 'yyyy')
      )
        return (
          getWeek(this.state.exportReportDate, { weekStartsOn: 1 }) <
          getWeek(new Date(), { weekStartsOn: 1 })
        )
      return false
    }

    return true
  }

  testAnnualDates = (currYear, prevYear) => {
    // store current values.
    let cD = this.Calc.getCurrDate(),
      cY = this.Calc.getCurrYear(),
      currSearch,
      prevSearch

    // update values to end of year.
    this.Calc.setCurrReportDate(new Date(currYear, 11, 31))
    this.Calc.setPrevReportYear(prevYear)

    // iterate day-to-day
    let results = []
    while (`${this.Calc.getCurrYear()}` === `${currYear}`) {
      // print findings.
      currSearch = { search: this.Calc.getCurrSearch() }
      prevSearch = { search: this.Calc.getPrevSearch() }
      results.push([
        currSearch.search.report_date,
        prevSearch.search.report_date,
        prevSearch.search.week,
        prevSearch.search.month,
        prevSearch.search.quarter,
        prevSearch.search.year,
      ])
      this.Calc.setCurrReportDate(sub(this.Calc.getCurrDate(), { days: 1 }))
    }

    // revert values.
    this.Calc.setCurrReportDate(cD)
    this.Calc.setPrevReportYear(cY)
    return results
  }

  testDates = () => {
    this.Calc.setCurrReportDate(new Date(2024, 11, 31))

    const results = this.testAnnualDates(2024, 2023)
      .concat(this.testAnnualDates(2024, 2022))
      .concat(this.testAnnualDates(2024, 2021))
      .concat(this.testAnnualDates(2024, 2020))
      .concat(this.testAnnualDates(2023, 2022))
      .concat(this.testAnnualDates(2023, 2021))
      .concat(this.testAnnualDates(2023, 2020))
      .concat(this.testAnnualDates(2022, 2021))
      .concat(this.testAnnualDates(2022, 2020))
      .concat(this.testAnnualDates(2021, 2020))
  }

  _fetch = () => {
    if (this._searchMethod) {
      if (!isNaN(this._searchId) && this._searchId > 0)
        return this._fetchByDownline()

      this.setState({ ...this.Calc.getStateObj(), fetching: true })
      fetchReportData(this.Calc, {
        search: async (search) => {
          return await ProductionReportWidgetService[this._searchMethod](search)
        },
      }).then((data) => {
        this.setState({ data, fetching: false, ...this.Calc.getStateObj() })
      })

      // 1. Enable this for comparative date testing.
      // 2. 'Copy Object' by right-clicking the console.log statement.
      // 3. Format as csv & save it to automation.usabg.com storage/apps folder.
      // 4. Visit automation.usabg.com/tests/date-comparisons, when logged in.
      // this.testDates();
    }
  }

  _fetchExportRecords = async () => {
    let calcObj = this.Calc.getStateObj()
    this.setState({ ...this.Calc.getStateObj(), fetching: true })

    let reportDate = format(calcObj.exportReportDate, 'yyyy-MM-dd')
    let previousDate = calcObj.exportPreviousReportDate

    let [currentData, previousData] = await Promise.all([
      ProductionReportWidgetService.search({
        search: {
          report_date: reportDate,
          export: 1,
        },
      }),
      ProductionReportWidgetService.search({
        search: {
          report_date: previousDate.report_date,
          week: previousDate.week,
          month: previousDate.month,
          quarter: previousDate.quarter,
          year: previousDate.year,
          export: 1,
        },
      }),
    ])

    // Transaform Data
    currentData = this.transformData(currentData)
    // console.log(currentData)
    // console.log(previousData)

    previousData = previousData.reduce((acc, item) => {
      if (item.source_id) {
        if (!acc[item.source_id]) {
          acc[item.source_id] = { timespan_data: {} }
        }

        // Store data by timespan_ind inside timespan_data
        acc[item.source_id].timespan_data[item.timespan_ind] = item
      } else if (item.userId) {
        // If userId exists, reduce based on userId
        acc[item.userId] = item
      }
      return acc
    }, {})

    this.setState({
      previousData: previousData,
      currentData: currentData,
    })
    this._buildTableRecords(this.state.exportmetric)
    this.setState({ ...this.Calc.getStateObj(), fetching: false })
  }

  // Only invoked in: self._fetchExportRecords(), ExportGrowthReportModal
  _buildTableRecords = (metric) => {
    let previousData = this.state.previousData
    let currentData = this.state.currentData
    let reportDate = this.state.exportReportDate
    let avTableRecord = []
    let isoDateDetails = this._getDateInfoISO(reportDate)
    for (let key in previousData) {
      // console.log(`Source ID: ${key}`);
      // console.log(previousData[key].mtd.av); // Access the object for each source_id
      let exportData = []
      let shouldPush = false
      const timeSpanArray = ['wtd', 'mtd', 'qtd', 'ytd']

      if (previousData[key].timespan_data) {
        // The we have non legacy data
        for (let timeSpan of timeSpanArray) {
          if (!previousData[key]?.timespan_data?.[timeSpan]) continue // Skip if data doesn't exist

          let timespanLabel = ''

          // Dynamically set timespan label and details for Week, Month, Quarter, and Year
          switch (timeSpan) {
            case 'wtd':
              timespanLabel = `Week (${isoDateDetails.isoWeekNumber})`
              break
            case 'mtd':
              timespanLabel = `Month (${isoDateDetails.isoMonth})`
              break
            case 'qtd':
              timespanLabel = `Quarter (${isoDateDetails.quarter})`
              break
            case 'ytd':
              timespanLabel = 'Year'
              break
            default:
              timespanLabel = timeSpan.toUpperCase() // Fallback
          }

          const currentMetric =
            currentData[key]?.timespan_data?.[timeSpan]?.[metric] ?? 0
          const previousMetric =
            previousData[key]?.timespan_data?.[timeSpan]?.[metric] ?? 0

          const data = {
            current: this._formatExportMetric(currentMetric, metric),
            previous: this._formatExportMetric(previousMetric, metric),
            id:
              (previousData[key]?.timespan_data?.[timeSpan]?.u_fname ?? '') +
              ' ' +
              (previousData[key]?.timespan_data?.[timeSpan]?.u_lname ?? ''),
            usertype:
              previousData[key]?.timespan_data?.[timeSpan]?.source_type ?? '',
            timespan: timespanLabel,
            growth: this._growth(currentMetric, previousMetric),
          }

          exportData.push(data)
          shouldPush = true
        }
      } else {
        timeSpanArray.forEach((timeSpan) => {
          const currentMetric =
            currentData[key]?.timespan_data[timeSpan]?.[metric] ?? 0
          const previousMetric = previousData[key]?.[timeSpan]?.[metric] ?? 0

          let timespanLabel = ''

          // Dynamically set timespan label and details for Week, Month, Quarter, and Year
          switch (timeSpan) {
            case 'wtd':
              timespanLabel = `Week (${isoDateDetails.isoWeekNumber})`
              break
            case 'mtd':
              timespanLabel = `Month (${isoDateDetails.isoMonth})`
              break
            case 'qtd':
              timespanLabel = `Quarter (${isoDateDetails.quarter})`
              break
            case 'ytd':
              timespanLabel = 'Year'
              break
            default:
              timespanLabel = timeSpan.toUpperCase() // Fallback
          }

          const data = {
            current: this._formatExportMetric(currentMetric, metric),
            previous: this._formatExportMetric(previousMetric, metric),
            id: previousData[key]?.[timeSpan]?.agentname ?? '',
            usertype: previousData[key]?.[timeSpan]?.userType ?? '',
            timespan: timespanLabel,
            growth: this._growth(currentMetric, previousMetric),
          }

          // Check if either current or previous data is non-zero before pushing to exportData
          if (data.current !== 0 || data.previous !== 0) {
            shouldPush = true
          }

          exportData.push(data)
        })
      }

      // Now, check if any of the entries should be pushed (if at least one is non-zero)
      if (shouldPush) {
        avTableRecord.push(...exportData)
      }
    }

    // previousData.map
    this.setState({
      exportData: {
        ...this.state.exportData,
        rows: avTableRecord,
      },
    })
  }

  transformData = (inputData) => {
    let groupedData = {}

    inputData.forEach((entry) => {
      const sourceId = entry.source_id

      if (!groupedData[sourceId]) {
        groupedData[sourceId] = {
          source_type: entry.source_type,
          report_date: entry.report_date,
          u_fname: entry.u_fname,
          u_lname: entry.u_lname,
          u_upline_id: entry.u_upline_id,
          timespan_data: {},
        }
      }

      groupedData[sourceId].timespan_data[entry.timespan_ind] = {
        points: entry.points,
        enrolled: entry.enrolled,
        aca_lives: entry.aca_lives,
        ma_lives: entry.ma_lives,
        av: entry.av,
        life_av: entry.life_av,
        med_supps_av: entry.med_supps_av,
      }
    })

    return groupedData
  }

  _growth = (current, previous) => {
    let p = 0,
      c = 0

    try {
      p = previous
      p = p !== null && !isNaN(p) ? parseFloat(p) : 0
      c = current
      c = c !== null && !isNaN(c) ? parseFloat(c) : 0
    } catch (ex) {}

    // Calculate growth
    const growthValue =
      (c === 0 && p === 0 ? 0 : p === 0 ? 1 : (c - p) / p) * 100

    // Round to two decimal places and return as a string with percentage sign
    return growthValue.toFixed(2) + '%'
  }

  _getDateInfoISO = (date) => {
    const parsedDate = new Date(date)
    if (isNaN(parsedDate)) {
      throw new Error('Invalid date input')
    }

    const isoWeekNumber = getISOWeek(parsedDate)
    const quarter = getQuarter(parsedDate)
    const isoMonth = parsedDate.getMonth() + 1

    return {
      isoWeekNumber,
      quarter,
      isoMonth,
    }
  }

  toggleExportGrowthReportModal = async () => {
    const { isModalOpen, isLoading } = this.state

    // Toggle modal state
    this.setState({ isModalOpen: !isModalOpen })

    // Fetch data only if the modal is being opened and not already loading
    if (!isLoading && !isModalOpen) {
      this.setState({ isLoading: true, fetching: true })
      await this._fetchExportRecords()
      this.setState({ isLoading: false, fetching: false })
    }
  }

  _fetchByDownline = () => {
    if (this._searchMethod) {
      this.setState({ ...this.Calc.getStateObj(), fetching: true })
      fetchReportData(this.Calc, {
        search: async (search) => {
          if (this._searchMethod === 'searchCarrier')
            search.search.carrier_id = this._searchId
          else search.search.user_id = this._searchId
          return await ProductionReportWidgetService[this._searchMethod](search)
        },
      }).then((data) => {
        this.setState({ data, fetching: false, ...this.Calc.getStateObj() })
      })
    }
  }

  _onDateChange = (reportDate) => {
    if (this.Calc.setCurrReportDate(reportDate)) {
      this._searchDownline ? this._fetchByDownline() : this._fetch()
    }
  }

  _onExportDateChange = (exportReportDate) => {
    if (this.Calc.setExportCurrReportDate(exportReportDate)) {
      this._fetchExportRecords()
    }
  }

  _onPreviousYearChange = (e) => {
    if (this.Calc.setPrevReportYear(parseInt(e.target.value))) {
      this._searchDownline ? this._fetchByDownline() : this._fetch()
    }
  }

  _onExportPreviousYearChange = (e) => {
    if (this.Calc.setPrevReportYear(parseInt(e.target.value), true)) {
      this._fetchExportRecords()
    }
  }

  _renderPrevYearDropDown = () => {
    let year = format(this.state.reportDate, 'yyyy') - 1
    const years = Array.from(new Array(5), (v, i) => {
      return {
        text: year - i,
        value: year - i,
        checked: year - i === parseInt(this.state.prevYear),
      }
    })
    return (
      <UIDropdown
        options={years}
        selected={parseInt(this.state.prevYear)}
        onChange={this._onPreviousYearChange}
        value={parseInt(this.state.prevYear)}
      />
    )
  }

  _renderExportPrevYearDropDown = () => {
    let year = format(this.state.exportReportDate, 'yyyy') - 1
    const years = Array.from(new Array(5), (v, i) => {
      return {
        text: year - i,
        value: year - i,
        checked: year - i === parseInt(this.state.exportPrevYear),
      }
    })
    return (
      <UIDropdown
        options={years}
        selected={parseInt(this.state.exportPrevYear)}
        onChange={this._onExportPreviousYearChange}
        value={parseInt(this.state.exportPrevYear)}
      />
    )
  }
}
