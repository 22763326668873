import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdbreact'
import { toast } from 'react-toastify' // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css' // Import the CSS for toast notifications
import WeeklyMarketingCommitment from '../../../../components/modals/MarketingCommitmentModal'

import './WeeklyMarketingCommitmentForm.scss'

// Initialize toast notifications
toast.configure()

const WeeklyMarketingCommitmentForm = () => {
  const [entry, setEntry] = useState(null)
  const [investedValue, setInvestedValue] = useState(0) // State to store the input value

  // Fetch the entry data on component mount
  useEffect(() => {
    const check = async () => {
      const e = await WeeklyMarketingCommitment.getEntry()
      if (e) {
        setEntry({
          ...e,
          entry_date: moment(e.entry_date).format('YYYY-MM-DD'),
        })
        setInvestedValue(e.invested || 0) // Initialize the input value
      } else {
        setEntry({
          entry_date: WeeklyMarketingCommitment.getThisMonday(),
        })
        setInvestedValue(0) // Default to 0 if no entry exists
      }
    }
    check()
  }, [])

  // Handle form submission
  const onSubmit = (event) => {
    event.preventDefault()
    return false
  }

  // Restrict input to numbers only
  const onKeyPress = (evt) => {
    if (!/\d/.test(`${evt.key}`)) {
      evt.preventDefault()
      return false
    }
    return true
  }

  // Save the commitment value
  const handleSave = async () => {
    if (parseInt(investedValue) >= 0 && !isNaN(investedValue)) {
      try {
        await WeeklyMarketingCommitment.onSaveCommitment(
          parseInt(investedValue)
        )
        toast.success('Commitment saved successfully!', {
          position: toast.POSITION.TOP_RIGHT, // Position of the toast
          autoClose: 3000, // Auto-close after 3 seconds
        })
      } catch (err) {
        console.error('Error saving commitment:', err)
        toast.error('Failed to save commitment. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      toast.error('Invalid input value. Please enter a valid number.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  return entry ? (
    <MDBContainer id="WeeklyMarketingCommitmentForm" fluid>
      <p>Marketing</p>
      <form noValidate onSubmit={onSubmit}>
        <MDBRow className="metric-intake">
          <MDBCol size="10">
            <label className="text-indigo" htmlFor="investedInput">
              Week{' '}
              {entry.entry_date
                ? moment(entry.entry_date, 'YYYY-MM-DD').format('w')
                : ''}{' '}
              Commitment
            </label>
            <br />
            <span>How much are you spending on leads each week?</span>
          </MDBCol>
          <MDBCol size="2">
            <MDBInput
              type="text"
              className="form-control"
              id="investedInput"
              name="invested"
              value={investedValue}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const value = e.target.value
                if (!isNaN(value) && value !== null) {
                  setInvestedValue(parseInt(value) || 0) // Update the state with the new value
                }
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-end align-items-end mt-4">
          <MDBCol size="auto">
            <MDBBtn color="primary" onClick={handleSave}>
              Save
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    </MDBContainer>
  ) : (
    <></>
  )
}

export default observer(WeeklyMarketingCommitmentForm)
