import React, { useEffect, useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { Table } from '@tiptap/extension-table'
import { TableRow } from '@tiptap/extension-table-row'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableCell } from '@tiptap/extension-table-cell'
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaListOl,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaUndo,
  FaRedo,
  FaImage,
  FaTable,
  FaVideo,
  FaPlusSquare,
  FaMinusSquare,
  FaTrash,
  FaColumns,
  FaGripLines,
  FaQuoteRight,
  FaStrikethrough,
  FaLink,
  FaIndent,
  FaOutdent,
  FaHeading,
} from 'react-icons/fa'
import { debounce } from 'lodash'
import IndentExtension from './CustomExtension/IndentExtension'
import FontSizeExtension from './CustomExtension/FontSizeExtension'
import VideoExtension from './CustomExtension/VideoExtension'
import CustomTable from './CustomExtension/CustomTable'
import './TextEditor.scss'

const fontSizes = [12, 14, 16, 18, 20, 24, 30, 36, 48]

const TextEditor = ({ id = 1, value, onChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4, 5, 6], // Allow all headings
        },
      }),
      Underline,
      Link.configure({
        openOnClick: true,
      }),
      Image.configure({
        HTMLAttributes: {
          style: 'max-width: 100%; height: auto;',
        },
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CustomTable.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      IndentExtension,
      FontSizeExtension,
      VideoExtension,
    ],
    content: value || '',
    onUpdate: debounce(({ editor }) => {
      onChange(editor.getHTML()) // Save only when the user pauses typing
    }, 300),
  })

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value || '')
    }
  }, [value, editor])

  if (!editor) {
    return null
  }

  const setLink = () => {
    const url = prompt('Enter the URL')
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  }

  const preserveStyles = (html) => {
    return html
      .replace(/style="(.*?)"/g, (match) => match) // Keep inline styles
      .replace(/<h1>/g, '<h2>') // Ensure H2 stays H2
  }

  const unsetLink = () => {
    editor.chain().focus().unsetLink().run()
  }

  const uploadImage = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const base64Image = reader.result
        editor.chain().focus().setImage({ src: base64Image }).run()
      }
      reader.readAsDataURL(file)
    }
  }

  const addVideo = () => {
    const url = prompt('Enter the video URL (YouTube, Vimeo, etc.)')
    if (url) {
      editor.chain().focus().setVideo(url).run() // ✅ Uses custom video extension
    }
  }

  return (
    <div id={`TextEditorComponent-${id}`} className="TextEditorComponent">
      {/* Toolbar */}
      <div className="editor-toolbar">
        {/* Formatting Actions */}
        <select
          onChange={(e) => {
            const size = parseInt(e.target.value, 10)
            editor.chain().focus().setFontSize(size).run()
          }}
          defaultValue={editor.getAttributes('paragraph').fontSize ?? ''}
          title="Font Size"
        >
          <option value="" disabled>
            Font Size
          </option>
          {fontSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={editor.isActive('heading', { level: 1 }) ? 'active' : ''}
          title="Heading 1"
        >
          <FaHeading /> H1
        </button>

        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={editor.isActive('heading', { level: 2 }) ? 'active' : ''}
          title="Heading 2"
        >
          <FaHeading /> H2
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'active' : ''}
          title="Bold"
        >
          <FaBold />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'active' : ''}
          title="Italic"
        >
          <FaItalic />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? 'active' : ''}
          title="Underline"
        >
          <FaUnderline />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'active' : ''}
          title="Strikethrough"
        >
          <FaStrikethrough />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'active' : ''}
          title="Blockquote"
        >
          <FaQuoteRight />
        </button>
        <button type="button" onClick={() => setLink()} title="Insert Link">
          <FaLink />
        </button>
        <button
          type="button"
          onClick={() => unsetLink()}
          title="Remove Link"
          disabled={!editor.isActive('link')}
        >
          <FaLink style={{ textDecoration: 'line-through' }} />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'active' : ''}
          title="Bullet List"
        >
          <FaListUl />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'active' : ''}
          title="Ordered List"
        >
          <FaListOl />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'active' : ''}
          title="Align Left"
        >
          <FaAlignLeft />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={editor.isActive({ textAlign: 'center' }) ? 'active' : ''}
          title="Align Center"
        >
          <FaAlignCenter />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={editor.isActive({ textAlign: 'right' }) ? 'active' : ''}
          title="Align Right"
        >
          <FaAlignRight />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          className={editor.isActive({ textAlign: 'justify' }) ? 'active' : ''}
          title="Justify Text"
        >
          <FaAlignJustify />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().increaseIndent().run()}
          title="Increase Indent"
        >
          <FaIndent />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().decreaseIndent().run()}
          title="Decrease Indent"
        >
          <FaOutdent />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().undo().run()}
          title="Undo"
        >
          <FaUndo />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          title="Redo"
        >
          <FaRedo />
        </button>

        {/* Image and Video */}
        <label
          htmlFor={`imageUpload-${id}`}
          className="upload-label"
          title="Upload Image"
        >
          <FaImage />
          <input
            id={`imageUpload-${id}`}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={uploadImage}
          />
        </label>
        <button type="button" onClick={addVideo} title="Add Video">
          <FaVideo />
        </button>

        {/* Table Actions */}
        <button
          type="button"
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
          title="Insert Table"
        >
          <FaTable />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          disabled={!editor.can().addColumnBefore()}
          title="Add Column Before"
        >
          <FaGripLines />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          disabled={!editor.can().addColumnAfter()}
          title="Add Column After"
        >
          <FaColumns />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addRowBefore().run()}
          disabled={!editor.can().addRowBefore()}
          title="Add Row Before"
        >
          <FaPlusSquare />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addRowAfter().run()}
          disabled={!editor.can().addRowAfter()}
          title="Add Row After"
        >
          <FaPlusSquare />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteRow().run()}
          disabled={!editor.can().deleteRow()}
          title="Delete Row"
        >
          <FaMinusSquare />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteColumn().run()}
          disabled={!editor.can().deleteColumn()}
          title="Delete Column"
        >
          <FaMinusSquare />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteTable().run()}
          disabled={!editor.can().deleteTable()}
          title="Delete Table"
        >
          <FaTrash />
        </button>
      </div>

      {/* Editor Content */}
      <EditorContent editor={editor} />
    </div>
  )
}

export default TextEditor
