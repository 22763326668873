import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact'
import EventViewer from './components/EventViewer/EventViewer.component'
import CalendarService from './shared/Calendar.service'
import { toast } from 'react-toastify'
import moment from 'moment'
import { BehaviorSubject, Subscription } from 'rxjs'
import { filter, distinctUntilChanged } from 'rxjs/operators'
import UserProfileService from './../../shared/services/UserProfile.service'
import CalendarEventService from './../../shared/services/CalendarEvent.service'
// FullCalendar Dependencies
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { createPlugin } from '@fullcalendar/core'

// CalendarPage Styles
import './CalendarPage.scss'
import { cleanHTML } from '../../shared/formatters/cleanHTML'

const INITIAL_CAL_VIEW = 'dayGridMonth',
  nsmWeekViewPlugin = createPlugin({
    views: {
      nsmWeekView: { type: 'dayGridWeek', classNames: ['nsm-week-view'] },
    },
  })

class CalendarPage extends React.Component {
  state = {
    isLoading: true,
    // isLoading: 						false,
    isFetching: true,
    canResync: false,
    canDelete: false,
    events: [],
    eventSources: [],
    eventSourceHandlers: [],
  }

  __CalendarRef = React.createRef()
  __events = []
  __calDate = new BehaviorSubject('')
  __subscriptions$ = new Subscription()

  /*
   * Component Accessor Methods ----------------------
   *
   */
  get CalendarApi() {
    return this.__CalendarRef?.current?.getApi()
  }

  get ViewApi() {
    let CalApi = this.CalendarApi
    return CalApi ? CalApi.view : undefined
  }

  set isLoading(l) {
    if (!!this.state.isLoading !== !!l) this.setState({ isLoading: !!l })
  }

  set isFetching(f) {
    if (!!this.state.isFetching !== !!f) this.setState({ isFetching: !!f })
  }

  /*
   * Component Public Methods ------------------------
   *
   */
  toggleEventSource = (eventSourceId) => {
    if (!CalendarService.toggleEventSource(eventSourceId))
      toast.error(
        'You can not shut off all calendars.  At least one must be active.',
        { position: toast.POSITION.TOP_RIGHT }
      )
  }

  /*
   * Component Private Methods -----------------------
   *
   */
  _getEventSourceId = (eventInfo) => {
    try {
      return eventInfo._def.extendedProps.event_id
    } catch (ex) {
      return undefined
    }
  }

  _getCalendarId = (eventInfo) => {
    try {
      return eventInfo._def.extendedProps.calendar_id
    } catch (ex) {
      return undefined
    }
  }

  _onEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault()
    CalendarService.viewEvent(
      Object.assign(clickInfo.event, {
        source_id: this._getEventSourceId(clickInfo.event),
        calendar_id: this._getCalendarId(clickInfo.event),
      })
    )
  }

  _renderEventContent = (eventInfo) => {
    return (
      <>
        <div className="fc-daygrid-event-dot"></div>
        <div className="fc-event-time">{eventInfo.timeText}</div>
        <div className="fc-event-title">{cleanHTML(eventInfo.event.title)}</div>
      </>
    )
  }

  _resync = () => {
    this.setState({ isFetching: true })

    CalendarEventService.syncEvents().finally(() => {
      let date = this.__calDate.getValue()
      CalendarService.fetchEvents(date.split('|')[0], date.split('|')[1])
    })
  }

  _publishDateChange = () => {
    let date = [
      moment(this.__CalendarRef.current.getApi().view.activeStart).format(
        'YYYY-MM-DD'
      ),
      moment(this.__CalendarRef.current.getApi().view.activeEnd).format(
        'YYYY-MM-DD'
      ),
    ].join('|')

    this.__calDate.next(date)
  }

  _rewind = () => {
    this.__CalendarRef.current.getApi().prev()
    this._publishDateChange()
  }
  _advance = () => {
    this.__CalendarRef.current.getApi().next()
    this._publishDateChange()
  }
  _today = () => {
    this.__CalendarRef.current.getApi().today()
    this._publishDateChange()
  }
  _changeView = (view) => {
    this.__CalendarRef.current.getApi().changeView(view)
    this._publishDateChange()
  }

  _onEventSourcesChange = (eventSources) => {
    let events = this._filterActiveCalendars(this.__events, eventSources)
    this.setState((prevState) => ({ ...prevState, eventSources, events }))
  }

  _filterActiveCalendars = (events, eventSources) => {
    eventSources = eventSources ? eventSources : this.state.eventSources
    eventSources =
      eventSources && Array.isArray(eventSources) ? eventSources : []
    let calendarIds = eventSources.map((e) => parseInt(e.id))

    return events.filter(
      (event) =>
        calendarIds.indexOf(parseInt(event.extendedProps.calendar_id)) > -1
    )
  }

  _renderDateSelector = () => {
    return (
      <ul className="cal-date-selector">
        <li className="btn" onClick={() => this._rewind()}>
          <MDBIcon icon="angle-left" />
        </li>
        <li className="btn" onClick={() => this._today()}>
          Today
        </li>
        <li className="btn" onClick={() => this._advance()}>
          <MDBIcon icon="angle-right" />
        </li>
      </ul>
    )
  }

  _renderDateHeader = () =>
    this.ViewApi?.type ? this.ViewApi.getCurrentData().viewTitle : <></>

  _renderViewSelector = () => {
    return (
      <ul className="cal-view-selector">
        <li className="btn" onClick={() => this._changeView('dayGridMonth')}>
          Month
        </li>
        <li className="btn" onClick={() => this._changeView('timeGridWeek')}>
          Week
        </li>
        <li className="btn" onClick={() => this._changeView('timeGridDay')}>
          Day
        </li>
        {UserProfileService.isA(
          ['system-admin', 'agency-owner', 'internal-admin'],
          true
        ) ? (
          <li className="btn" onClick={() => this._changeView('nsmWeekView')}>
            NSM
          </li>
        ) : (
          <></>
        )}
      </ul>
    )
  }

  _determineAccess = async () => {
    let canResync = UserProfileService.isA([
        'system-admin',
        'agency-owner',
        'internal-admin',
        'internal-staff',
      ]),
      canDelete = UserProfileService.isA([
        'system-admin',
        'agency-owner',
        'internal-admin',
      ])

    if (!canResync) {
      if (UserProfileService.isA('non-agent')) {
        Promise.all([
          UserProfileService.canUser('calendars.events.resync'),
          UserProfileService.canUser('calendars.events.delete'),
        ]).then((Res) => {
          canResync = !!Res.shift()
          canDelete = !!Res.shift()
          this.setState({ canResync, canDelete })
        })
      }
    } else {
      this.setState({ canResync, canDelete })
    }
  }

  _setFormatforCalendar = (events) => {
    let finalevents = []
    events.forEach((event) => {
      event.start = moment(event.start).format('YYYY-MM-DD HH:mm:ss')
      event.end = moment(event.end).format('YYYY-MM-DD HH:mm:ss')
      finalevents.push(event)
    })
    return finalevents
  }

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    // Determine what access level the user has.
    this._determineAccess()

    // Subscribe to isFetching / isLoading events.
    this.__subscriptions$.add(
      CalendarService.onIsFetchingSubject().subscribe((isFetching) =>
        this.setState({
          isFetching,
          isLoading: !isFetching ? false : this.state.isLoading,
        })
      )
    )

    // Subscribe to Calendar Event Sources.
    this.__subscriptions$.add(
      CalendarService.onEventSourcesChange().subscribe((eventSources) =>
        this._onEventSourcesChange(eventSources)
      )
    )
    this.__subscriptions$.add(
      CalendarService.fetchEventSources().subscribe((isFetching) => {
        this.isFetching = isFetching
        if (!isFetching) this._publishDateChange()
      })
    )

    // Subscribe to Calendar Event Changes.
    this.__subscriptions$.add(
      CalendarService.getEvents().subscribe((events) => {
        events = this._setFormatforCalendar(events)
        this.__events = events
        this.setState({ events: this._filterActiveCalendars(this.__events) })
      })
    )

    // Subscribe to Calendar Date Changes.
    this.__subscriptions$.add(
      this.__calDate
        .pipe(
          filter((n) => !!n),
          distinctUntilChanged()
        )
        .subscribe((date) =>
          CalendarService.fetchEvents(date.split('|')[0], date.split('|')[1])
        )
    )

    // Populate calendar's initial events.
    // this._publishDateChange()
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    toast.configure()

    const { isLoading, isFetching, eventSources, canResync, canDelete } =
      this.state
    const allEventSources = CalendarService.getAllEventSources()
    const isEventSourceActive = (eventSourceId) =>
      eventSources
        .map((evt) => parseInt(evt.id))
        .indexOf(parseInt(eventSourceId)) > -1

    return (
      <React.Fragment>
        <DashboardLayout>
          <main
            id="CalendarPage"
            className={
              (isLoading || isFetching ? 'is-loading' : '') +
              ' mainSection pb-5'
            }
          >
            <div className="loading-panel">
              <div>
                Fetching Calendar Events&nbsp;
                <span className="ellipsis-exterior">
                  <span className="ellipsis-interior">...</span>
                </span>
              </div>
            </div>
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol size="12">
                  <h2>Calendar</h2>
                  {canResync ? (
                    <button className="resync-btn" onClick={this._resync}>
                      Resync Calendar Events
                    </button>
                  ) : (
                    <></>
                  )}
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow className="calendar-mgmt-btn-row">
                <MDBCol size="12" md="4">
                  {this._renderDateSelector()}
                </MDBCol>
                <MDBCol size="12" md="4" className="date-header">
                  {this._renderDateHeader()}
                </MDBCol>
                <MDBCol size="12" md="4">
                  {this._renderViewSelector()}
                </MDBCol>
              </MDBRow>
              <MDBRow className="event-source-btn-row">
                {allEventSources && allEventSources.length > 1 ? (
                  allEventSources.map((eventSource, es) => (
                    <MDBCol
                      key={'es-' + es}
                      size="12"
                      md="4"
                      className="event-source-btn"
                    >
                      <MDBBtn
                        size="sm"
                        className="btn-block p-0"
                        onClick={() => this.toggleEventSource(eventSource.id)}
                      >
                        <div style={eventSource.style} className="py-2 px-4">
                          {isEventSourceActive(eventSource.id) ? (
                            <MDBIcon fas="true" icon="check" />
                          ) : (
                            <MDBIcon far icon="square" />
                          )}
                          {eventSource.name}
                        </div>
                      </MDBBtn>
                    </MDBCol>
                  ))
                ) : (
                  <></>
                )}
              </MDBRow>
              <div className="content-wrapper">
                {
                  <FullCalendar
                    ref={this.__CalendarRef}
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      nsmWeekViewPlugin,
                    ]}
                    initialView={INITIAL_CAL_VIEW}
                    events={this.state.events}
                    eventClick={this._onEventClick}
                    weekends={false}
                    contentHeight={'auto'}
                    slotMinTime={'07:00:00'}
                    slotMaxTime={'22:00:00'}
                    eventContent={this._renderEventContent}
                  />
                }
              </div>
              <EventViewer
                canDelete={canDelete}
                onDelete={() => {
                  let date = this.__calDate.getValue()
                  CalendarService.fetchEvents(
                    date.split('|')[0],
                    date.split('|')[1]
                  )
                }}
              />
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default CalendarPage
