import React from 'react'
import BaseModelInstance from './base.model'
import { Carrier as ModelDefinition } from './model-definitions'
import CarrierPriorityFactory from './../factories/carrier-priority.factory'
class Carrier extends BaseModelInstance {
  afterSet = (key, value) => {}

  // priority definition objects (deprecated).
  priorities = []
  coverage_categories = []

  // children (priority) orm objects (preferred method).
  #children = { priorities: [] }

  priority = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await CarrierPriorityFactory.search({
            search: { user_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        key: async (metaKey) =>
          (await CarrierPriorityFactory.search({
            search: { meta_key: metaKey, user_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        create: async (data) =>
          await CarrierPriorityFactory.create({
            ...(data ? data : {}),
            user_id: this.id(),
          }),
      }
    }

    return Array.isArray(this.#children?.meta) ? this.#children.meta : []
  }

  getPriority = (spec, usertype) => {
    try {
      if (this.isPriority(spec, usertype, 'core')) return 'core'
      else if (this.isPriority(spec, usertype, 'preferred')) return 'preferred'
    } catch (ex) {}
    return 'additional'
  }

  addPriority = (priority) => {
    if (
      priority &&
      ['HEALTH', 'SENIOR', 'LIFE', 'ACA'].indexOf(priority?.spec) > -1
    ) {
      if (
        this.priorities.filter((p) => p.spec === priority?.spec).length === 0
      ) {
        this.priorities.push({
          spec: priority.spec,
          is_affiliate_core:
            priority?.is_affiliate_core === true ||
            parseInt(priority?.is_affiliate_core) === 1,
          is_affiliate_preferred:
            priority?.is_affiliate_preferred === true ||
            parseInt(priority?.is_affiliate_preferred) === 1,
          is_associate_core:
            priority?.is_associate_core === true ||
            parseInt(priority?.is_associate_core) === 1,
          is_associate_preferred:
            priority?.is_associate_preferred === true ||
            parseInt(priority?.is_associate_preferred) === 1,
          is_signature_core:
            priority?.is_signature_core === true ||
            parseInt(priority?.is_signature_core) === 1,
          is_signature_preferred:
            priority?.is_signature_preferred === true ||
            parseInt(priority?.is_signature_preferred) === 1,
        })
      }
    }
  }

  // check the priorities[] if speciality exist for a given carrier.
  isSpeciality = (speciality) => {
    return this.priorities.some((priority) =>
      speciality.includes(priority.spec)
    )
  }

  // check the priorities[] if speciality & priority exist for a given carrier.
  isPriority = (spec, usertype, priorityLevel) => {
    spec = spec
      ? (!!~spec.indexOf('-') ? spec.split('-') : [spec]).filter(
          (s) => s && ['HEALTH', 'SENIOR', 'LIFE', 'ACA'].includes(s)
        )
      : null

    if (!spec) return

    if (!isNaN(usertype)) {
      if ([111, 133].indexOf(parseInt(usertype)) > -1) usertype = 'affiliate'
      else if ([223, 224].indexOf(parseInt(usertype)) > -1)
        usertype = 'associate'
      else usertype = 'signature'
    } else {
      throw new Error(
        'Invalid usertype argument passed in to Carrier.isPriority()'
      )
    }

    priorityLevel = priorityLevel
      ? `${priorityLevel}`.trim().toLowerCase()
      : priorityLevel
    if (['core', 'preferred', 'additional'].indexOf(priorityLevel) < 0)
      throw new Error(
        `Invalid carrier priority argument passed in to Carrier.isPriority().  passed: ${priorityLevel}`
      )

    return (
      this.priorities.filter(
        (p) =>
          spec.includes(p.spec) &&
          (p[`is_${usertype}_${priorityLevel}`] === true ||
            parseInt(p[`is_${usertype}_${priorityLevel}`]) === 1)
      ).length > 0
    )

    // // Priority Carriers (All Core/Preferred)
    // if (['core','preferred'].indexOf(priorityLevel) < 0) {
    // 	if (this.raw().hasOwnProperty(`is_${usertype}_${priorityLevel}`))
    // 		return !!this.raw()[`is_${usertype}_${priorityLevel}`];
    // 	return false;
    // }

    // // Additional Carriers (All Non-Core/Non-Preferred)
    // if (this.raw().hasOwnProperty(`is_${usertype}_core`) && !!this.raw()[`is_${usertype}_core`])
    // 	return false;
    // if (this.raw().hasOwnProperty(`is_${usertype}_preferred`) && !!this.raw()[`is_${usertype}_preferred`])
    // 	return false;
    // return true;
  }

  renderPriorityIndicator = (usertype) => {
    if (!isNaN(usertype)) {
      if ([111, 133].indexOf(parseInt(usertype)) > -1) usertype = 'affiliate'
      else if ([223, 224].indexOf(parseInt(usertype)) > -1)
        usertype = 'associate'
      else usertype = 'signature'
    } else {
      throw new Error(
        'Invalid usertype argument passed in to Carrier.isPriority()'
      )
    }

    const priority =
      this.priorities.find((p) => p[`is_${usertype}_core`] === true) ||
      this.priorities.find((p) => p[`is_${usertype}_preferred`] === true)

    //console.log('priority---', priority);

    const priorityIndicator = priority
      ? priority[`is_${usertype}_core`]
        ? '$$$'
        : '$$'
      : '$'

    return (
      <span className="carrier-priority-indicator">{priorityIndicator}</span>
    )
    // switch (this.getPriority(spec, usertype)) {
    //   case 'core':
    //     return <span className="carrier-priority-indicator">$$$</span>
    //   case 'preferred':
    //     return <span className="carrier-priority-indicator">$$</span>
    //   case 'additional':
    //     return <span className="carrier-priority-indicator">$</span>
    //   default:
    //     return ''
    // }
  }

  addCoverageCategory = (c) => {
    this.coverage_categories = [...this.coverage_categories, ...c]
  }

  getCoverageCategory = () => this.coverage_categories

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default Carrier
